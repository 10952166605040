<template>
    <v-dialog
      v-model="show"
      
      max-width="500"
    >
    <v-form
    ref="groupForm"
    v-model="valid"
    
  >
  <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
      <v-card-text>
      <v-text-field
      v-model="text"
      :counter="45"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
      </v-card-text>
    <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
            {{ $store.getters.getTextMap().submit }}
          </v-btn>
          <v-btn
         :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
            {{ $store.getters.getTextMap().cancel }}
          </v-btn>
        </v-card-actions>
  </v-card>
    </v-form>
    </v-dialog>
</template>
<script>
export default {
    name:'GetTextModal',
    props:['show','name'],
    data(){
        return {
            text:null,
            valid:false,
            nameRules: [
        v => !!v || 'required',
        v => /\S+/.test(v) || 'Text only',
        v => (v && v.length <= 50 && v.length > 0) || 'Name must be less than 51 characters',
      ],
        }
    },
    mounted(){
      if(this.name){
        this.text=this.name
      }
    },
    methods:{
        onSubmit(){
            this.$refs.groupForm.validate()
            if(this.valid){
                this.$emit('value',this.text)
                this.$emit('close')
            }

        },
        onReset(){
            this.$emit('close')
        }
    },
    watch:{
      name:{
        handler(){
            this.text=this.name
        },
        deep:true
    }
    }
}
</script>