<template>
    <v-container fluid :dark="$store.getters.getColorPalette().isDark">
        <InfoAlert :showDismissableAlert="showDismissableAlert" v-on:close="showDismissableAlert" :info="info" />
        <v-card :dark="$store.getters.getColorPalette().isDark"
            :style="{ 'border': `1px solid ${$store.getters.getColorPalette().panelBorderColorCode}`, 'background-color': $store.getters.getColorPalette().backgroundColorCode }"
            class="px-2 py-10">
            <v-card-title class="sub-heading">{{ $store.getters.getTextMap().add_image_widget
                }}</v-card-title>

            <!-- Form Input Fields -->
            <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="widgetValid">
                <v-row class="px-5">
                    <!-- Widget Label -->
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-text-field dense outlined v-if="!loading" v-model="widget_label" :counter="30"
                            :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label"
                            required></v-text-field>
                    </v-col>
                    <!-- Widget width -->
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-select v-if="!loading" v-model="widget_width" :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :label="$store.getters.getTextMap().widget_width" :items="widthOptions" item-text="label"
                            item-value="value" :menu-props="{ dark: $store.getters.getColorPalette().isDark }" dense
                            :rules="[rules.required]" outlined>
                        </v-select>
                    </v-col>
                    <!-- Widget Height -->
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-select v-if="!loading" v-model="widget_height"
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :label="$store.getters.getTextMap().widget_height" :items="heightOptions"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" item-text="label"
                            :rules="[rules.required]" item-value="value" dense outlined></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-file-input v-if="!loading" v-model="widget_image"
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :label="$store.getters.getTextMap().upload_image" :rules="[rules.required]" accept="image/*"
                            @change="validateImage" show-size dense outlined></v-file-input>

                        <v-alert border="right" color="red" type="warning" v-if="error">{{ error }}</v-alert>
                        <v-alert border="right" color="green" type="success" v-if="success">{{ success }}</v-alert>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="text-center">
                        <v-btn :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{
                                $store.getters.getTextMap().submit }}</v-btn>
                        <v-btn class="mx-1" :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{
                                $store.getters.getTextMap().cancel }}</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>

import InfoAlert from '@/components/InfoAlert';
import FormRules from '@/utillities/FormRules';
import axios from 'axios';

export default {
    name: "AddImageWidget",
    props: ['widget', 'seq'],
    components: {
        InfoAlert
    },
    data() {
        return {
            title: "Image Widget",
            showDismissableAlert: false,
            info: '',
            widgetValid: false,
            rules: FormRules.rules,
            widget_label: null,
            widget_width: null,
            widget_height: null,
            widget_image: null,
            base64Img: null,
            loading: false,
            edit: false,
            api: "",
            error: null,
            success: null,
            widgetForm: {},
            widthOptions: [
                { label: 'Full screen', value: 12 },
                { label: 'Half screen', value: 6 },
                { label: '1/3 screen', value: 4 },
                { label: 'Quarter screen', value: 3 },
                { label: '3/4 screen', value: 9 },
                // { label: 'Auto', value: 12 }
            ],
            heightOptions: [
                { label: 'Fixed', value: 'fixed' },
                { label: 'Flex', value: 'flex' },
            ],
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.widget && this.widget.widget_id && this.widget.view_id) {
                this.edit = true;
                this.widgetForm = Object.assign({}, this.widget);
                this.api = 'updateWidget';
                this.widget_label = this.widget.label;
                this.widget_height = this.widget.height;
                this.widget_width = this.widget.width;

                // Update widget_image as a base64 string or a file object
                if (this.widget.meta.image) {
                    this.widget_image = this.widget.meta.image; // Assuming it's base64 string
                    this.base64Img = this.widget.meta.image; // Ensure the base64 string is available for submission
                }
            } else {
                console.log("else called.");
                if (this.widget.view_id) {
                    this.api = 'createWidgetForView';
                    this.widgetForm = Object.assign({}, this.widget);
                    this.widgetForm.meta = { data: [] };
                    this.widgetForm.seq = this.seq ? this.seq : 0;
                }
            }
        },

        onSubmit() {
            this.$refs.widgetForm.validate()
            if (this.widgetValid) {

                this.widgetForm['meta'] = {}
                this.widgetForm['label'] = this.widget_label
                this.widgetForm.meta['image'] = this.base64Img
                this.widgetForm['height'] = this.widget_height
                this.widgetForm['width'] = this.widget_width

                console.log(this.widgetForm, "widget form");

                axios.post(this.$store.state.api + this.api, this.widgetForm, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                    .then(response => {
                        if (response.data.status === 'success') {
                            this.loading = false
                            this.$emit('success')
                            this.info = response.data.msg;
                            this.$emit('close')
                        } else {
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.info = err;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    });


            }

        },
        onClose() {
            // this.widget_height = null
            // this.widget_width = null
            // this.widget_image = null
            // this.widget_label = null
            this.$refs.widgetForm.reset()
            this.success = null
            this.error = null
        },

        toggleImageStatus() {
            this.error = null
            this.success = null
        },

        base64ToFile(base64String, fileName) {
            // Extract MIME type and base64 data
            const arr = base64String.split(',');
            const mimeMatch = arr[0].match(/:(.*?);/);
            const mime = mimeMatch ? mimeMatch[1] : '';
            const base64Data = arr[1];

            // Convert base64 string to binary data
            const byteString = atob(base64Data);
            const byteArray = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
                byteArray[i] = byteString.charCodeAt(i);
            }

            // Create a Blob from the binary data
            const blob = new Blob([byteArray], { type: mime });

            // Create a file from the Blob
            const file = new File([blob], fileName, { type: mime });
            console.log(file);

            return file;
        },
        validateImage(file) {
            const maxFileSize = 50 * 1024;
            const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'];

            if (!allowedFormats.includes(file.type)) {
                this.error = 'Only JPG,PNG,JPEG,WEBP formats are allowed'
                this.widget_image = null;
            } else if (file.size > maxFileSize) {
                this.$store.dispatch('showSnackbar', {
                    text: '',
                    color: 'error',
                });
                this.error = "File size must not exceed 50KB "
                this.widget_image = null;
            } else {
                console.log('Valid image selected:', file.name);
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.base64Img = e.target.result;
                    this.error = null;
                    this.success = "Valid image added";
                    console.log('Base64 String:', this.base64Img);
                };
                reader.readAsDataURL(file);
            }
        }

    }

}
</script>

<style></style>