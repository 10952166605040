<template>
  <v-container
    fluid
    :style="{
      background: $store.getters.getColorPalette().backgroundColorCode,
    }"
  >
    <h1 class="text-subtitle-2 text-capitalize grey--text upper"></h1>
    <h2
      class="text-capitalize teal--text upper"
      v-if="!$store.state.clientSafety"
    ></h2>
    <v-container
      class="py-1"
      fluid
      v-if="
        $store.state.user &&
        $store.state.settingMode &&
        $store.getters.getAccessByLevels(['engineeraccess'])
      "
    >
      <v-row no-gutters>
        <v-col cols="11" align="left">
          <v-chip
            v-if="
              $store.state.user &&
              $store.state.settingMode &&
              $store.getters.getAccessByLevels(['alphaaccess'])
            "
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode"
            ><v-avatar
              :dark="$store.getters.getColorPalette().isDark"
              :style="{
                color: $store.getters.getColorPalette().background2ColorCode,
              }"
              :class="
                $store.getters.getColorPalette().foregroundColorName +
                ' mx-1 px-0'
              "
              ><v-icon
                :color="$store.getters.getColorPalette().accentCode"
                small
                >mdi-city-variant-outline</v-icon
              ></v-avatar
            >{{ $store.getters.getOrganisationID() }}</v-chip
          >
          <!-- <h3 ></h3> -->
        </v-col>
        <v-col cols="1" align="right">
          <AddPanelToDashboard />
        </v-col>
      </v-row>
    </v-container>

    <v-row
      v-if="
        $store.state.user &&
        $store.state.user.organisation_id ===
          '26c7ea9e-8d15-4022-bd9e-e4166a6078e5'
      "
    >
      <v-col>
        <PowerModuleInsights />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col><DashboardMachineList /></v-col>
    </v-row>
    <v-row no-gutters v-if="
        $store.state.user &&
        $store.getters.getAccessByLevels(['device'])
      "
      ><v-col cols="12">
        <DashboardView /> </v-col
    ></v-row>
    <v-row
      v-if="
        $store.state.user &&
        $store.state.user.organisation_id ===
          '26c7ea9e-8d15-4022-bd9e-e4166a6078e5'
      "
    >
      <v-col>
        <WaterModuleInsights />
      </v-col>
    </v-row>
    <v-row no-gutters v-if="
        $store.state.user &&
        $store.getters.getAccessByLevels(['parameter'])
      ">
      <v-col><DashboardParamList /></v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        xl="6"
        align-self="center"
        v-bind:key="dashboardGauge.gauge_id"
        v-for="dashboardGauge in $store.state.dashboardGauges"
      >
        <DashboardGauge :dashboardGauge="dashboardGauge" />
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        xl="6"
        align-self="center"
        v-bind:key="dashboardPanel.panel_id"
        v-for="dashboardPanel in $store.state.dashboardPanels"
      >
        <DashboardPanel :dashboardPanel="dashboardPanel" />
      </v-col>
      <!-- <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        xl="6"
        align-self="center"
        v-if="$store.state.nodes.length > 0"
      >
        <WeeklyConsumptionCard class="mx-1 my-1" />
      </v-col> -->
      <!-- <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        xl="6"
        align="center"
        align-self="center"
        v-if="$store.state.nodes.length > 0"
      >
        <DeviceTypePercentagePanel class="mx-1 my-1" />
      </v-col> -->

</v-row>

  </v-container>
</template>
<script>
//import DashboardPanelList from '@/components/lists/DashboardPanelList'
import DashboardPanel from "@/components/items/DashboardPanel";
import DashboardGauge from "@/components/items/DashboardGauge";
import DashboardParamList from "@/components/lists/DashboardParamList";
import DashboardMachineList from "@/components/lists/DashboardMachineList";
import AddPanelToDashboard from "@/components/modals/AddPanelToDashboardBottomSheet";
// import WeeklyConsumptionCard from "@/components/panels/WeeklyConsumptionCard";
// import DeviceTypePercentagePanel from "@/components/panels/DeviceTypePercentagePanel";
import DashboardView from "@/components/panels/DashboardView";

import WaterModuleInsights from "@/components/panels/demo/WaterModuleInsights";
import PowerModuleInsights from "@/components/panels/demo/PowerModuleInsights";

//import EbillPanelList from '@/components/lists/EbillPanelList'
//import EbillSummaryPage from '@/pages/EbillSummaryPage'
//import Gauge from '@/components/graphs/Gauge'

export default {
  name: "Dashboard",
  components: {
    //DashboardPanelList,
    //EbillPanelList,
    //EbillSummaryPage,
    DashboardPanel,
    DashboardGauge,
    AddPanelToDashboard,
    // WeeklyConsumptionCard,
    // DeviceTypePercentagePanel,

    DashboardView,
    DashboardParamList,
    DashboardMachineList,
    WaterModuleInsights,
    PowerModuleInsights,
    //Gauge
  },
  mounted() {
    document.title = "Dashboard";
    if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
  },
  computed: {},
};
</script>
