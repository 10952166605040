<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <v-row>
            <v-col>
                <ChecklistStatusStats />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <ChecklistSubmissionStats />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert.vue';
// import axios from 'axios';
// import moment from 'moment';
import ChecklistSubmissionStats from '@/components/panels/manufacturing/checklists/ChecklistSubmissionStats.vue';
import ChecklistStatusStats from '@/components/panels/manufacturing/checklists/ChecklistStatusStats.vue';
export default {
    name: 'ChecklistSummary',
    components: {
        InfoAlert,
        ChecklistSubmissionStats,
        ChecklistStatusStats
    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:''
        }
    }



}
</script>