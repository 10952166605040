<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-row>
          <v-col>
            <v-form ref="orgForm" v-model="valid">
              <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                <v-card-title  class="headline">{{ title }}</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field 
                        :dark="$store.getters.getColorPalette().isDark" 
                        :loading="loading"
                        v-model="form.name"
                        :counter="40"
                        :rules="[rules.required]"
                        :label="$store.getters.getTextMap().name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field 
                        :dark="$store.getters.getColorPalette().isDark" 
                        :loading="loading"
                        v-model="form.email"
                        :counter="40"
                        :rules="[rules.required,rules.email]"
                        :label="$store.getters.getTextMap().email"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col>
                    <v-text-field 
                        :dark="$store.getters.getColorPalette().isDark" 
                        :loading="loading"
                        v-model="form.phone"
                        :counter="10"
                        :rules="[rules.required,rules.noSpace,rules.integ,rules.nonZeroLengthDynamic(10)]"
                        :label="$store.getters.getTextMap().phone"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete
                          v-if="!loading"
                          v-model="form.default_dashboard_view"
                          :items="entureViewsOptions"
                          :dark="$store.getters.getColorPalette().isDark" 
                          :color="$store.getters.getColorPalette().inputtextColor"
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                          outlined
                          dense
                          small-chips
                          :label="$store.getters.getTextMap().default_enture_view"
                          item-text="label"
                          item-value="value"
                          ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete
                          v-if="!loading"
                          v-model="form.role"
                          :items="rolesOptions"
                          :dark="$store.getters.getColorPalette().isDark" 
                          :color="$store.getters.getColorPalette().inputtextColor"
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                          outlined
                          dense
                          small-chips
                          :label="$store.getters.getTextMap().role"
                          item-text="label"
                          item-value="value"
                        ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                      <v-select
                        v-if="!loading"
                        v-model="form.accessList"
                        :items="accessOptions"
                        item-text="label"
                        item-value="value"          
                        :label="$store.getters.getTextMap().permissions"
                        :rules="[rules.nonZeroLength]"
                        :dark="$store.getters.getColorPalette().isDark" 
                        multiple
                        small-chips
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field 
                        :dark="$store.getters.getColorPalette().isDark" 
                        :loading="loading"
                        v-model="form.designation"
                        :counter="40"
                        
                        :label="$store.getters.getTextMap().designation"
                        
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete
                          v-if="!loading"
                          v-model="form.default_landing"
                          :items="landingPageOptions"
                          :dark="$store.getters.getColorPalette().isDark" 
                          :color="$store.getters.getColorPalette().inputtextColor"
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                          outlined
                          dense
                          small-chips
                          :label="$store.getters.getTextMap().default_landing_page"
                          item-text="label"
                          item-value="value"
                          ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                  <v-btn :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="onReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>            
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
import FormRules from  '@/utillities/FormRules'
import SecurityUtillity from '@/utillities/SecurityUtillity'
import EntureStaticLists from '@/utillities/EntureStaticLists'
export default {
    name:'UpdateUAMUser',
    props:['user'],
    components:{
        InfoAlert
    },
    data(){
        return {
            form:{
                name:null,
                default_dashboard_view:null,
                role:null
            },
            entureViewsOptions:[],
            rolesOptions:[],
            api:'updateUser',
            title:'Update UAM User Access',
            loading:false,
            info:null,
            infoMessage:"",
            showDismissibleAlert:false,
            valid:false,
            rules:FormRules.rules,
            nameRules: [
              v => !!v || 'Name is required',
              v => /\S+/.test(v) || 'Name is required',
              v => (v && v.length <= 10 && v.length > 0) || 'Name must be less than 11 characters',
            ],
            msgRules: [
              v => !!v || 'Description is required',
              v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
            ],
            landingPageOptions:EntureStaticLists.landingPageOptions
        }
    },
    mounted(){
      this.init()
    },
    computed:{
      accessOptions(){
            let op=[]
            for (let i of SecurityUtillity.accessOptions){
                op.push(i)

            }
            return op
        },
        userAccess(){
          if(this.user && this.user.user_level)
            return SecurityUtillity.getLabels(this.user.user_level) //SecurityUtillity.getAccessListByUserLevel(this.user.user_level).map(x=>{return this.accessOp.find(y=>y.value==x).label})
            return []
        },
    },
    methods:{
      init(){
        if(this.user){
        this.getAllDashboardViews()
        this.getRoles()
        this.api='updateUser'
        this.title='Update UAM User'
        this.infoMessage="User Updated"
        this.form = Object.assign({},this.user)
        this.form.accessList=SecurityUtillity.getAccessListByUserLevel(this.user.user_level)
      }
    },
      getRoles(){
          let op=[]
          this.loading = true;
          axios.get(this.$store.state.api + 'roles',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
              if (response.data.status == "success") {
                  let roles=response.data.roles
                  if(this.user.userid===this.$store.state.user.userid){
                    roles=roles.filter(d=>{return d.role != this.user.roles})
                  }
                  for(let i of roles){
                    if(i && i.role && i.role_name){
                      op.push({value:i.role, label: i.role_name })
                    }else{
                      op.push({value:i.role, label: i.role })
                    }
                  }
              } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
              }
              this.loading = false;
            })
            .catch(error => {
              this.info = error;
              this.showDismissibleAlert = true;
              this.loading = false;
          });
          this.rolesOptions=op
      },
      getAllDashboardViews(){
        let op=[]
        this.loading = true;
        axios.get(this.$store.state.api + "entureViews",{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response => {
            if (response.data.status == "success") {
              let enture_views = response.data.views
              if(this.user.userid===this.$store.state.user.userid){
                enture_views=enture_views.filter(d=>{return d.view_id != this.user.default_dashboard_view})
              }
                  for(let i of enture_views){
                    if(i && i.name){
                      op.push({value:i.view_id, label: i.name })
                    }else{
                      op.push({value:i.view_id, label: i.view_id })
                    }
                  }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
              this.info = error;
              this.showDismissibleAlert = true;
              this.loading = false;
            });
            this.entureViewsOptions=op
        },
        onSubmit(){
            this.$refs.orgForm.validate()
            if(this.valid){
            this.loading = true;
            axios.post(this.$store.state.api + this.api, this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
              .then(response => {
                if (response.data.status == "success") {
                  this.$store.dispatch("refreshUAMUsers")
                  this.info = this.infoMessage;
                  this.showDismissibleAlert = true;
                  this.loading = false;
                  this.onReset()
                  this.$emit('close')
                } else {
                  this.info = response.data.msg;
                  this.showDismissibleAlert = true;
                }
                this.loading = false;
              })
              .catch(error => {
                this.onReset()
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
              });
            }
        },
        onReset(){
            this.$emit('close')
        }
    },
    watch: {
    'user': {
      handler() {
        // Do something when $store.state.organisation changes
        this.init();
      },
      deep: true, // Set to true if organisation is an object/array
      // immediate: true // Trigger the handler immediately after the component is created
    }
  }
}
</script>