var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{style:({
      background: _vm.$store.getters.getColorPalette().backgroundColorCode,
    }),attrs:{"fluid":""}},[_c('h1',{staticClass:"text-subtitle-2 text-capitalize grey--text upper"}),(!_vm.$store.state.clientSafety)?_c('h2',{staticClass:"text-capitalize teal--text upper"}):_vm._e(),(
        _vm.$store.state.user &&
        _vm.$store.state.settingMode &&
        _vm.$store.getters.getAccessByLevels(['engineeraccess'])
      )?_c('v-container',{staticClass:"py-1",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11","align":"left"}},[(
              _vm.$store.state.user &&
              _vm.$store.state.settingMode &&
              _vm.$store.getters.getAccessByLevels(['alphaaccess'])
            )?_c('v-chip',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().background2ColorCode}},[_c('v-avatar',{class:_vm.$store.getters.getColorPalette().foregroundColorName +
                ' mx-1 px-0',style:({
                color: _vm.$store.getters.getColorPalette().background2ColorCode,
              }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-icon',{attrs:{"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-city-variant-outline")])],1),_vm._v(_vm._s(_vm.$store.getters.getOrganisationID()))],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('AddPanelToDashboard')],1)],1)],1):_vm._e(),(
        _vm.$store.state.user &&
        _vm.$store.state.user.organisation_id ===
          '26c7ea9e-8d15-4022-bd9e-e4166a6078e5'
      )?_c('v-row',[_c('v-col',[_c('PowerModuleInsights')],1)],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('DashboardMachineList')],1)],1),(
        _vm.$store.state.user &&
        _vm.$store.getters.getAccessByLevels(['device'])
      )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('DashboardView')],1)],1):_vm._e(),(
        _vm.$store.state.user &&
        _vm.$store.state.user.organisation_id ===
          '26c7ea9e-8d15-4022-bd9e-e4166a6078e5'
      )?_c('v-row',[_c('v-col',[_c('WaterModuleInsights')],1)],1):_vm._e(),(
        _vm.$store.state.user &&
        _vm.$store.getters.getAccessByLevels(['parameter'])
      )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('DashboardParamList')],1)],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.$store.state.dashboardGauges),function(dashboardGauge){return _c('v-col',{key:dashboardGauge.gauge_id,attrs:{"cols":"12","xs":"12","sm":"12","md":"6","xl":"6","align-self":"center"}},[_c('DashboardGauge',{attrs:{"dashboardGauge":dashboardGauge}})],1)}),_vm._l((_vm.$store.state.dashboardPanels),function(dashboardPanel){return _c('v-col',{key:dashboardPanel.panel_id,attrs:{"cols":"12","xs":"12","sm":"12","md":"6","xl":"6","align-self":"center"}},[_c('DashboardPanel',{attrs:{"dashboardPanel":dashboardPanel}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }