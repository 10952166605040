<template>
<v-container fluid class="px-0 py-0" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
    <LoadingComponent :loading="loading"/>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-container fluid v-if="settingOptions">
        <v-row v-if="showEdit && $store.state.user && $store.state.settingMode  && $store.getters.getAccessByLevels(['engineeraccess'])">
            <v-col cols="auto" class="d-flex">
                    <v-chip
                        v-if="
                        $store.state.user &&
                        $store.state.settingMode &&
                        $store.getters.getAccessByLevels(['engineeraccess'])
                        "
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().background2ColorCode"
                        ><v-avatar
                        :dark="$store.getters.getColorPalette().isDark"
                        :style="{
                            color: $store.getters.getColorPalette().background2ColorCode,
                        }"
                        :class="
                            $store.getters.getColorPalette().foregroundColorName +
                            ' mx-0 px-0'
                        "
                        ><v-icon :color="$store.getters.getColorPalette().accentCode" small>mdi-identifier</v-icon></v-avatar>
                        {{ view.view_id }}
                        </v-chip>
            </v-col>
            <v-col cols="auto" class="d-flex">
                <v-btn class="mx-1"  @click="setAsDefault" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().add_to_dashboard }}
                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-eye-arrow-left</v-icon>
                </v-btn>

                <EditViewBottomSheet :view="view"/>
            </v-col>
            <v-col cols="auto" class="d-flex ml-auto" align="right">
                <v-btn class="mx-1" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="getWidgets">
                    {{ $store.getters.getTextMap().refresh }}
                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>
                        mdi-refresh
                    </v-icon>
                </v-btn>
                <DeleteConfirmation v-on:confirm="deleteView()"  title="Delete Confirmation"  description="Are you sure you want to delete this Widget View?"> 
                    <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().delete_view }}
                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                    </v-btn>
                </DeleteConfirmation>
            </v-col>
        </v-row>
    </v-container>
    <v-row no-gutters>
        <v-col :cols="widget.width" v-for="(widget,index) in widgetData" :key="index">
            <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="mx-1 my-1">
                <component v-bind:is="widgets[widget.widget_type]" :widget="widget"  :edit="edit" :disableTimeframe="false" v-on:update="getWidgets"  ></component>
            </v-card>
        </v-col>
    </v-row>
    <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" align="center" >
        <v-col align-self="center" v-if="editOptions"><v-divider></v-divider></v-col>
        <v-col cols="1" align-self="center" align="center" v-if="editOptions" ><AddWidgetModal :view_id="view.view_id" :seq="seq" v-on:success="addWidgetSuccess" v-on:update="getWidgets"/>
        </v-col>
        <v-col align-self="center" v-if="editOptions" ><v-divider></v-divider></v-col>
    </v-row>
    
</v-container>
</template>
<script>
import TODBillingWidget from '@/components/widgets/TODBillingWidget'
import PieTDWidget from '@/components/widgets/PieTDWidget'
import BarTDWidget from '@/components/widgets/BarTDWidget'
import HeatMapWidget from '@/components/widgets/HeatMapWidget'
import UsageScoreCardWidget from '@/components/widgets/UsageScoreCardWidget'
import GaugeWidget from '@/components/widgets/GaugeWidget'
import BusBarLiveMonitoring from '@/components/widgets/BusBarLiveMonitoring'
import ConsumptionGaugeWidget from '@/components/widgets/ConsumptionGaugeWidget'
import ConsumptionPieWidget from '@/components/widgets/ConsumptionPieWidget'
import BulletAngularGaugeWidget from '@/components/widgets/BulletAngularGaugeWidget'
import BarTrendTDWidget from '@/components/widgets/BarTrendTDWidget'
import BarTrendWidget from '@/components/widgets/BarTrendWidget'
import TableWidget from '@/components/widgets/TableWidget'
import InfoAlert from '@/components/InfoAlert'
import LoadingComponent from '@/components/LoadingComponent'
import AddWidgetModal from '@/components/modals/AddWidgetModal'
import EditViewBottomSheet from '@/components/modals/EditViewBottomSheet'
import axios from 'axios'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import BannerWidget from '@/components/widgets/BannerWidget'
import AngularGaugeWidget from '@/components/widgets/AngularGaugeWidget'
import ParamEquatedBannerWidget from '@/components/widgets/ParamEquatedBannerWidget'
import BitwiseWordWidget from '@/components/widgets/BitwiseWordWidget'
import DynamicLogWidget from '@/components/widgets/DynamicLogWidget'
import ProcessStatusBasedWidget from '@/components/widgets/ProcessStatusBasedWidget'
import ParameterStatusBasedWidget from '@/components/widgets/ParameterStatusBasedWidget'
import TargetBasedBarTrendWidget from '@/components/widgets/TargetBasedBarTrendWidget'
import ParameterInsightsWidget from '@/components/widgets/ParameterInsightsWidget'
import MachineStatusTimelineWidget from '@/components/widgets/MachineStatusTimelineWidget'
import ShiftBasedConsumptionTableWidget from '@/components/widgets/ShiftBasedConsumptionTableWidget'
import MachineWidget from '@/components/widgets/MachineWidget'
import DualAxisBarTrendWidget from '@/components/widgets/DualAxisBarTrendWidget'
import TriggerCountTableWidget from '@/components/widgets/TriggerCountTableWidget'
import HorizantalBarTrendWidget from '@/components/widgets/HorizantalBarTrendWidget'
import DaywiseMachineStatusWidget from '@/components/widgets/DaywiseMachineStatusWidget.vue'
import ConsolidatedDaywiseMachineStatusWidget from '@/components/widgets/ConsolidatedDaywiseMachineStatusWidget.vue'
import DaywiseMachineStatusTableWidget from '@/components/widgets/DaywiseMachineStatusTableWidget.vue'
import ShiftwiseMachineStatusWidget from '@/components/widgets/ShiftwiseMachineStatusWidget.vue'
import ImageWidget from '@/components/widgets/ImageWidget.vue'
export default {
    name:'WidgetPage',
    props:{
    'view':{
        type:Object
    },
    showEdit:{
        type:Boolean,
        default:true
    },
    // edit:{
    //     type:Boolean,
    //     default:true
    // },
    settingOptions:{
        type:Boolean,
        default:true
    },
    editOptions:{
        type:Boolean,
        default:true
    },
},
    components:{
        InfoAlert,
        TODBillingWidget,
        AddWidgetModal,
        EditViewBottomSheet,
        LoadingComponent,
        DeleteConfirmation,
        BitwiseWordWidget,
        DynamicLogWidget,
        ProcessStatusBasedWidget,
        ParameterStatusBasedWidget,
        ParameterInsightsWidget,
        MachineStatusTimelineWidget,
        DualAxisBarTrendWidget,
        TriggerCountTableWidget,
        HorizantalBarTrendWidget,
        DaywiseMachineStatusWidget,
        ConsolidatedDaywiseMachineStatusWidget,
        DaywiseMachineStatusTableWidget,
        ShiftwiseMachineStatusWidget,
        ImageWidget
    },
    mounted(){
        // console.log(this.edit,"edit")
        this.edit=this.showEdit?true:false
        // console.log(this.view.view_id,'view_id')
        this.getWidgets()
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            info:'',
            seq:0,
            showDismissibleAlert:false,
            widgetData:[],
            edit:true,
            widgets:{
                'todBillingWidget':TODBillingWidget,
                'pieTDWidget':PieTDWidget,
                'barTDWidget':BarTDWidget,
                'heatMapWidget':HeatMapWidget,
                'usageScoreCardWidget':UsageScoreCardWidget,
                'gaugeWidget':GaugeWidget,
                'consumptionGaugeWidget':ConsumptionGaugeWidget,
                'consumptionPieWidget':ConsumptionPieWidget,
                'busBarLiveMonitoring':BusBarLiveMonitoring,
                'bulletAngularGaugeWidget':BulletAngularGaugeWidget,
                'barTrendTDWidget':BarTrendTDWidget,
                'barTrendWidget':BarTrendWidget,
                'tableWidget':TableWidget,
                'bannerWidget':BannerWidget,
                'angularGaugeWidget':AngularGaugeWidget,
                'paramEquatedbannerWidget':ParamEquatedBannerWidget,
                'bitwiseWordWidget':BitwiseWordWidget,
                'dynamicLogWidget':DynamicLogWidget,
                'machineStatusTimelineWidget':MachineStatusTimelineWidget,
                'processStatusBasedWidget':ProcessStatusBasedWidget,
                'parameterStatusBasedWidget':ParameterStatusBasedWidget,
                'targetBasedBarTrendWidget':TargetBasedBarTrendWidget,
                'parameterInsightsWidget':ParameterInsightsWidget,
                'shiftBasedConsumptionTableWidget':ShiftBasedConsumptionTableWidget,
                'machineWidget':MachineWidget,
                'dualAxisBarTrendWidget': DualAxisBarTrendWidget,
                'triggerCountTableWidget': TriggerCountTableWidget,
                "horizantalBarTrendWidget":HorizantalBarTrendWidget,
                'daywiseMachineStatusWidget':DaywiseMachineStatusWidget,
                'consolidatedDaywiseMachineStatusWidget':ConsolidatedDaywiseMachineStatusWidget,
                'daywiseMachineStatusTableWidget':DaywiseMachineStatusTableWidget,
                'shiftwiseMachineStatusWidget':ShiftwiseMachineStatusWidget,
                "imageWidget":ImageWidget
            }
        }
    },
    computed:{
        
    },
    methods:{
         setAsDefault(){
            this.loading=true
            let payload={view_id:this.view.view_id}
            axios.post(this.$store.state.api+'updateDashboardView',payload,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                        
                            // this.edit=false
                            //this.$store.dispatch('refreshViews')
                            
                        }else{
                            
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                        this.loading=false
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })

        },
        getWidgets(){
            if(this.view && this.view.view_id){
                axios.post(this.$store.state.api+'getWidgetsByView',{view_id:this.view.view_id},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response && response.data.status=='success'){
                            this.loading=false
                            response.data.widgets.sort((a,b)=>{
                                if(isNaN(a.seq) || isNaN(b.seq)){
                                    return -1
                                }
                                return a.seq-b.seq
                            })
                            if(response.data.widgets && response.data.widgets.length){
                                
                                this.seq=response.data.widgets.length
                                
                            }else{
                                this.seq=0
                            }
                            /*for(let i of response.data.widgets){
                                i['meta']=JSON.parse(i['meta'])
                            }*/
                            this.widgetData=response.data.widgets

                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        console.error(err)
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        deleteView(){
            let payload={view_id:this.view.view_id}
            this.loading=true
          axios.post(this.$store.state.api+'deleteView',payload,{headers: {
              Authorization: 'Bearer '+ this.$store.state.jwt
              }}).then(response=>{
              if(response.data.status==='success'){
                    //   this.edit=false
                      this.$store.dispatch('refreshViews')
                    //   this.getWidgets()
              }
              })
              .catch(err=>{
                  console.error(err)
              this.loading=false});
        },
        addWidgetSuccess(){
            
            this.getWidgets()
        },
        cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
        }
    }
}
</script>
