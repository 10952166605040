<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false"
            :info="info" />
        <div v-if="startScanning&& !disableForm">
            <v-dialog v-model="startScanning" fullscreen  transition="dialog-bottom-transition">
                <v-card flat outlined  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
                    <QRCodeScanner :fieldData="fieldData" v-on:qrData="addQRData" v-on:goBack="toggleScan" />
                </v-card>
            </v-dialog>
        </div>
        <v-row class="mb-5" v-if="checklist && !checklist.fill_started">
            <v-col>
                <v-btn v-if="!loading" class="mr-1" :color="$store.getters.getColorPalette().submitbtnColor" small
                    @click="startChecklistFilling">{{ $store.getters.getTextMap().start +' '+$store.getters.getTextMap().filling}}</v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
            <v-col>
                <v-simple-table fixed-header dense
                    :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode }">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th v-for="(header, index) in tableJsonForm.headers" :key="index" class="text-left"
                                    :class="$store.getters.getColorPalette().foregroundColorName"
                                    :color="$store.getters.getColorPalette().accentCode">
                                    <strong>{{ header }}</strong>
                                </th>
                            </tr>
                        </thead>
                        <!-- <v-divider></v-divider> -->
                        <tbody :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode }"
                            v-if="!loading && tableJsonForm">
                            <tr v-for="(row, rowIndex) in tableJsonForm.rows" :key="rowIndex"
                                :style="{ 'background-color': $store.getters.getColorPalette().background1ColorCode }">
                                <td class="text-left" v-for="(cell, cellIndex) in row" :key="cellIndex">
                                    <!-- Handle Static Type -->
                                    <span v-if="cell.type === 'static'">
                                        {{ cell.value }}
                                    </span>
                                    <!-- Handle Input Types -->
                                    <div v-else-if="cell.type === 'input'">
                                        <!-- Checkbox -->
                                        <v-checkbox v-if="cell.value.type === 'check'"
                                            v-model="tableJsonForm.rows[rowIndex][cellIndex].value.data" hide-details
                                            :disabled="disableForm"
                                            :color="$store.getters.getColorPalette().accentCode"></v-checkbox>
                                        <!-- Text Box -->
                                        <v-text-field v-else-if="cell.value.type === 'text'"
                                            v-model="tableJsonForm.rows[rowIndex][cellIndex].value.data" dense
                                            :disabled="disableForm" hide-details class="pa-0">
                                        </v-text-field>
                                        <div v-if="cell.value.type === 'qrtext' && !loading " >
                                            <!-- <div v-if="!disableForm" class="pa-4">
                                                {{ item.key_text }}
                                            </div> -->
                                            <span  class="pa-5">
                                                {{tableJsonForm.rows[rowIndex][cellIndex].value.data}}
                                            </span>
                                            <span v-if="!startScanning" class="ma-2">
                                                <v-btn :color="$store.getters.getColorPalette().downloadbtnColor" outlined small :disabled="disableForm"  @click="toggleScan({rowIndex,cellIndex,type:'table'})">Start Scanning</v-btn>
                                            </span>
                                            <!-- <div v-if="startScanning&& !disableForm">
                                                <QRCodeScanner v-on:qrData="addQRDataToTable(rowIndex,cellIndex,$event)" v-on:goBack="startScanning=false" />
                                            </div> -->
                                            <!-- <span v-else-if="!tableJsonForm.rows[rowIndex][cellIndex].value.data&&!startScanning && disableForm" class="pl-2 my-3">
                                                * Please click on "Start Filling" to scan the QR Code
                                            </span> -->
                                            
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-form ref="dynamicForm" v-model="isFormValid">
            <v-row no-gutters v-for="(item, index) in formFields" :key="index" >
                <v-col cols="12">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field dense outlined :disabled="disableForm" v-if="item.entry_type === 'text' && !loading"
                        v-model="formValues[item.key_text]" :label="item.key_text" :required="item.required"
                        :rules="item.required ? [rules.required] : []"></v-text-field>
                    <div v-if="item.entry_type === 'qrtext' && !loading " >
                        <div v-if="!disableForm" class="pa-4">
                            {{ item.key_text }}
                        </div>
                        <div v-if="!startScanning" class="mb-1 ">
                            <v-btn :color="$store.getters.getColorPalette().downloadbtnColor" outlined small :disabled="disableForm"  @click="toggleScan({...item,type:'key_value'})">Start Scanning</v-btn>
                        </div>
                        <!-- <div v-if="startScanning&& !disableForm">
                            <QRCodeScanner v-on:qrData="addQRData(item,$event)" v-on:goBack="startScanning=false" />
                        </div> -->
                        <!-- <div v-else-if="!formValues[item.key_text]&&!startScanning && disableForm" class="pl-2 my-3">
                            * Please click on "Start Filling" to scan the QR Code
                        </div> -->
                        <div v-else class="pa-5">
                            {{ formValues[item.key_text] }}
                        </div>
                    </div>
                    <!-- 
                    <v-file-input
                        dense
                        outlined
                        v-else-if="item.entry_type === 'image'  && !loading"
                        v-model="formValues[item.key_text]"
                        :label="item.key_text"
                        :required="item.required"
                        :rules="item.required ? [rules.required] : []"
                    ></v-file-input> -->
                    <div v-if="item.entry_type === 'image' && !loading">
                        <v-file-input dense outlined :disabled="disableForm" :label="`Upload ${item.key_text}`"
                            v-model="fieldFiles[item.key_text]" accept="image/*"
                            @change="validateAndCompressImage(item.key_text)"></v-file-input>

                        <div v-if="base64Images[item.key_text]">
                            <h4>{{ item.key_text }} Preview:</h4>
                            <img :src="base64Images[item.key_text]" alt="Preview"
                                style="max-width: 100%; height: auto;" />
                        </div>
                    </div>

                    <v-checkbox dense outlined :disabled="disableForm"
                        v-else-if="item.entry_type === 'check' && !loading" v-model="formValues[item.key_text]"
                        :label="item.key_text" :required="item.required"></v-checkbox>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col align="right">
                    <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" class="mr-1" :color="$store.getters.getColorPalette().submitbtnColor" small
                        @click="submitForm(true)">{{ $store.getters.getTextMap().submit_move_next_stage }}</v-btn>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().downloadbtnColor" small
                        @click="submitForm(false)">{{ $store.getters.getTextMap().submit_draft }}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert.vue'
import axios from 'axios';
import QRCodeScanner from '@/components/reusable_components/QRCodeScanner.vue';

export default {
    name: 'ChecklistMakerForm',
    props: ['checklist'],
    components: {
        InfoAlert,
        QRCodeScanner
        // QRCodeReader
    },
    data() {
        return {
            info: '',
            showDismissibleAlert: false,
            loading: false,
            formFields: [],
            disableForm: false,
            isFormValid: false,
            formValues: {},
            tableJsonForm: {},
            fieldFiles: {}, // Stores selected files
            base64Images: {}, // Stores base64 values
            maxFileSize: 100 * 1024, // Limit file size to 2MB
            maxImageWidth: 800, // Compress to max width
            maxImageHeight: 800, // Compress to max height,
            fieldData:null,
            rules: {
                required: (value) => !!value || "This field is required",
            },
            startScanning: false
        }
    },
    mounted() {
        if (this.checklist && this.checklist.checklist_id && this.checklist.template_id) {
            if (!this.checklist.fill_started) {
                this.disableForm = true
            }
            this.getChecklistTemplate()
            if (this.checklist.table_json) {
                this.tableJsonForm = this.checklist.table_json
            } else {
                this.getChecklistTableJsonForm()
            }
        }
    },
    methods: {
        toggleScan(item) {
            this.fieldData=null
            if (item) {
                this.fieldData=item
            }
            this.startScanning=!this.startScanning
        },
        addQRData(value) {
            if(value){
                if (value.type === 'table') {
                    this.tableJsonForm.rows[value.rowIndex][value.cellIndex].value.data=value.qrData
                } else {
                    this.formValues[value.key_text] = value.qrData
                }
            }
            this.fieldData=null
            this.startScanning = false
        },
        formInit() {
            this.formFields.forEach((field) => {
                if (field.entry_type === "check") {
                    this.formValues[field.key_text] = false;
                } else if (field.entry_type === "image") {
                    this.formValues[field.key_text] = null;
                } else {
                    this.formValues[field.key_text] = "";
                }
            });
            if (this.checklist.submitted_timestamp) {
                this.getChecklistData()
            }
        },
        getChecklistData() {
            this.loading = true
            this.checklistFormDetails = []
            axios.post(this.$store.state.api + "getChecklistDataById", { checklist_id: this.checklist.checklist_id, timestamp: this.checklist.epoch_timestamp }, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.checklistFormDetails = [...response.data.data]
                        for (let i of response.data.data) {
                            for (let j of this.formFields) {
                                if (i.key_text === j.key_text) {
                                    if (j.entry_type === "check") {
                                        i.data === 'true' ? this.formValues[j.key_text] = true : this.formValues[j.key_text] = false
                                    } else {
                                        this.formValues[j.key_text] = i.data
                                    }
                                }
                            }
                        }
                        this.loading = false
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }
                    this.loading = false
                })
                .catch(err => {
                    console.log(err);
                    this.showDismissibleAlert = true
                    this.info = err
                    this.loading = false
                })
        },
        getChecklistTableJsonForm() {
            this.loading = true
            this.formFields = []
            axios.post(this.$store.state.api + "checklistTemplateDetails", { template_id: this.checklist.template_id }, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status === 'success') {
                        if (response.data.data[0].table_json) {
                            this.tableJsonForm = response.data.data[0].table_json
                        }
                        this.loading = false
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }
                    this.loading = false
                })
                .catch(err => {
                    console.log(err);
                    this.showDismissibleAlert = true
                    this.info = err
                    this.loading = false
                })
        },
        getChecklistTemplate() {
            this.loading = true
            this.formFields = []
            axios.post(this.$store.state.api + "checklistTemplateMetaById", { template_id: this.checklist.template_id }, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.formFields = [...response.data.data]
                        this.loading = false
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }
                    this.loading = false
                })
                .catch(err => {
                    console.log(err);
                    this.showDismissibleAlert = true
                    this.info = err
                    this.loading = false
                })
        },
        // convertToBase64(keyText) {
        //     const file = this.fieldFiles[keyText];
        //     if (file) {
        //         const reader = new FileReader();

        //         reader.onload = (e) => {
        //         this.$set(this.base64Images, keyText, e.target.result); // Set Base64 value dynamically
        //         };

        //         reader.onerror = (error) => {
        //         console.error(`Error converting file for ${keyText} to Base64:`, error);
        //         };

        //         reader.readAsDataURL(file);
        //     } else {
        //         this.$delete(this.base64Images, keyText); // Remove Base64 value if no file
        //     }
        // },
        validateAndCompressImage(keyText) {
            const file = this.fieldFiles[keyText];
            if (file) {
                // Check file size
                if (file.size > this.maxFileSize) {
                    alert(`File size exceeds ${this.maxFileSize / (1024)} KB limit`);
                    this.$delete(this.fieldFiles, keyText);
                    this.$delete(this.base64Images, keyText);
                    return;
                }

                const reader = new FileReader();
                reader.onload = (e) => {
                    this.compressImage(e.target.result, keyText);
                };

                reader.onerror = (error) => {
                    console.error(`Error reading file for ${keyText}:`, error);
                };

                reader.readAsDataURL(file);
            } else {
                this.$delete(this.base64Images, keyText);
            }
        },
        compressImage(imageSrc, keyText) {
            const img = new Image();
            img.src = imageSrc;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Set the target dimensions
                let width = img.width;
                let height = img.height;

                // Scale down if necessary
                if (width > this.maxImageWidth || height > this.maxImageHeight) {
                    const aspectRatio = width / height;
                    if (width > height) {
                        width = this.maxImageWidth;
                        height = width / aspectRatio;
                    } else {
                        height = this.maxImageHeight;
                        width = height * aspectRatio;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                // Compress the image and save the Base64
                const compressedBase64 = canvas.toDataURL("image/jpeg", 0.8); // 80% quality
                this.$set(this.base64Images, keyText, compressedBase64);
            };

            img.onerror = (error) => {
                console.error(`Error loading image for ${keyText}:`, error);
            };
        },
        startChecklistFilling() {
            let payload = {
                checklist_id: this.checklist.checklist_id,
                timestamp: Number(this.checklist.epoch_timestamp),
            }
            axios.post(this.$store.state.api + "startChecklistFill", payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.$emit('submitted')
                        this.info = "You can start filling the checklist";
                        this.showDismissibleAlert = true;
                        this.loading = false
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }
                    this.loading = false
                })
                .catch(err => {
                    console.log(err);
                    this.showDismissibleAlert = true
                    this.info = err
                    this.loading = false
                })
        },
        submitForm(submit) {
            if (this.$refs.dynamicForm.validate()) {
                this.loading = true
                let payload = {
                    checklist_id: this.checklist.checklist_id,
                    timestamp: Number(this.checklist.epoch_timestamp),
                    checklist_meta: {},
                    submit
                }
                let temp = {}
                this.formFields.map((field) => {
                    if (field.entry_type === "image" && this.base64Images) {
                        temp[field.key_text] = this.base64Images[field.key_text] || null
                    } else {
                        temp[field.key_text] = this.formValues[field.key_text]
                    }
                });
                payload["checklist_meta"] = temp
                let endpoint = "writeChecklistData"
                if (this.tableJsonForm) {
                    payload["table_json"] = this.tableJsonForm
                }
                if (this.checklist && this.checklist.status === "refill") {
                    endpoint = "refillChecklistData"
                }
                axios.post(this.$store.state.api + endpoint, payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                    .then(response => {
                        if (response.data.status === 'success') {
                            this.$emit('submitted')
                            this.info = "Checklist submitted successfully!";
                            this.showDismissibleAlert = true;
                            this.loading = false
                        } else {
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                            this.loading = false
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err);
                        this.showDismissibleAlert = true
                        this.info = err
                        this.loading = false
                    })
            }
        }
    },
    watch: {
        // checklist: {
        //     handler() {
        //         if (this.checklist && this.checklist.checklist_id && this.checklist.template_id) {
        //             this.getChecklistTemplate()
        //         }
        //     },
        //     deep:true
        // },
        formFields: {
            handler() {
                if (this.formFields && this.formFields.length > 0) {
                    this.formInit()
                }
            },
            deep: true
        }
    }
}
</script>