<template>
  <div>
    <CameraCodeScanner
      @scan="onScan"
      @load="onLoad"
      :torch="false"
      :facingMode="'environment'"
      :formats="['QR_CODE']"
    />
    <v-row class="ma-2">
      <v-col>
        <v-btn
          small
          :color="$store.getters.getColorPalette().submitbtnColor"
          @click="$emit('goBack')"
          >Go Back</v-btn
        >
      </v-col>
      <v-col align="right">
        Make sure the camera is open & has all neccessary permissions
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { CameraCodeScanner } from "vue-barcode-qrcode-scanner";
export default {
  name: "QRCodeScanner",
  props:["fieldData"],
  components: {
    CameraCodeScanner,
  },
  mounted() {
    console.log("in qr");
  },
  methods: {
    onLoad({ controls, scannerElement , browserMultiFormatReader }) {
      this.controls = controls; // Ensure this is stored properly
      console.log(controls);
      console.log(scannerElement);
      console.log(browserMultiFormatReader);
    },
    onScan({ result }) {
      let returnData = { ...this.fieldData }
      returnData["qrData"]=result
      this.$emit("qrData", returnData);
      console.log(result);
      // console.log(raw);
    },
  },
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
