<template>
    <v-container :dark="$store.getters.getColorPalette().isDark" fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <v-card :dark="$store.getters.getColorPalette().isDark" :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode, 'background-color': $store.getters.getColorPalette().background2ColorCode }" flat outlined class="px-3 mb-2">
            <v-card-title v-if="edit" class="sub-heading">{{$store.getters.getTextMap().edit_bar_trend_widget}}</v-card-title>
            <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().add_bar_trend_widget}}</v-card-title>
            <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="widgetValid">
                <v-row>
                    <!-- Widget label input field -->
                    <v-col align-self="center">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field dense outlined v-if="!loading" v-model="widgetForm.label" :counter="30"
                            :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label" required></v-text-field>
                    </v-col>

                    <!-- Widget width -->
                    <v-col align-self="center">
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="widgetForm.width" :items="widthOptions" :label="$store.getters.getTextMap().widget_width" dense outlined
                            item-text="label" item-value="value"></v-select>
                    </v-col>
                    <!-- Widget height -->
                    <v-col>
                        <v-select v-if="!loading" v-model="widgetForm.height" :items="heightOptions"
                        :label="$store.getters.getTextMap().widget_height" dense outlined item-text="label" item-value="value"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
                    </v-col>
                    <v-col >
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                    <!-- Widget timeframe like rtd, ytd etc.. -->
                    <v-col v-if="!disableTimeframe" align-self="center">
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="timeFrame" :items="tdOptions" :label="$store.getters.getTextMap().timeFrame" dense outlined item-text="label"
                            item-value="value"></v-select>
                    </v-col>
                    <!-- widget timerange like daily .. -->
                    <v-col  align-self="center">
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="timeRange" :items="trOptions" :label="$store.getters.getTextMap().aggregation" dense outlined item-text="label"
                            item-value="value"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <!--default Graph mode  -->
                    <v-col align-self="center">
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="graphType" :items="graphTypeOptions" :label="$store.getters.getTextMap().default_graph_type" dense outlined
                            item-text="label" item-value="value"></v-select>
                    </v-col>

                    <!--Default Bar mode  -->
                    <v-col v-if="!loading && graphType == 2" align-self="center">
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                            v-if="!loading && graphType == 2" v-model="barmode" :items="barmodeOptions"
                            :rules="[rules.required]" :label="$store.getters.getTextMap().bar_mode" dense outlined item-text="label"
                            item-value="value"></v-select>
                    </v-col>

                    <!-- widget parm seq -->
                    <v-col v-if="edit" align-self="center">
                        <v-text-field v-if="!loading" v-model="widgetForm.seq" :counter="3" :label="$store.getters.getTextMap().sequence" dense
                            outlined></v-text-field>
                    </v-col>

                    <!-- y-axis label -->
                    <v-col align-self="center">
                        <v-text-field :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="yaxisLabel" :label="$store.getters.getTextMap().yaxis_label" dense outlined
                            item-text="label" item-value="value"></v-text-field>
                    </v-col>
                    <!-- Ideal feature -->
                    <v-col>
                        <v-checkbox v-model="showIdeal" :label="$store.getters.getTextMap().show_ideal_line"></v-checkbox>
                        <v-checkbox
      v-model="showTotal"
      :label="$store.getters.getTextMap().show_total"
    ></v-checkbox>
                        <v-checkbox
      v-model="showAvg"
      :label="$store.getters.getTextMap().show_avg"
    ></v-checkbox>
                    </v-col>

                    <!-- IF ideal is enabled then ideal value -->
                    <v-col align-self="center" v-if="!loading && showIdeal">
                        <v-text-field v-model="idealValue" :label="$store.getters.getTextMap().idealValue" :rules="[rules.required, rules.num]"
                            type="number" dense outlined>
                        </v-text-field>
                    </v-col>

                    <!-- If ideal is enabled then ideal label -->
                    <v-col align-self="center" v-if="!loading && showIdeal">
                        <v-text-field v-model="idealLabel" type="text" :label="$store.getters.getTextMap().idealLabel"
                            :rules="[rules.required, rules.validString]" dense outlined>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>

        <!-- Add parameters  -->
        <v-card :dark="$store.getters.getColorPalette().isDark" :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode, 'background-color': $store.getters.getColorPalette().background2ColorCode }" flat outlined>
            

            <!-- If equation based then this form will shown -->
            <v-row>
                <v-col >
                    <v-card-title v-if="!equationBased" class="sub-heading">{{ $store.getters.getTextMap().add_parameters }}</v-card-title>
                    <v-card-title v-else class="sub-heading">{{ $store.getters.getTextMap().add_equation}}</v-card-title>
                </v-col>
                <v-spacer/>
                <v-col cols="auto" class="d-flex" align="right" align-self="center">
                    <v-switch
                        small
                        inset
                        style="transform: scale(0.8);"
                        color="success"
                        v-model="equationBased"
                        :label="`Equation`"
                    ></v-switch>
                </v-col>
            </v-row>



            <!--else Add params form -->
            <v-form ref="usageForm" v-model="valid">
                <v-container fluid>
                    <v-row v-if="!equationBased">
                        <!-- Label of the param view -->
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-text-field v-if="!loading" v-model="form.label" :counter="30"
                                :rules="[rules.required]" :label="$store.getters.getTextMap().param_label" dense outlined
                                required></v-text-field>
                        </v-col>

                        <!-- Workspace Options -->
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-select :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                                v-model="workspace" :items="workspaceOptions" :label="$store.getters.getTextMap().workspace" outlined dense
                                item-text="label" item-value="value" required :rules="objectRules"></v-select>
                        </v-col>

                        <!-- Machine Options based on the workspaces -->
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-select :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                                v-model="machine" :items="machineOptions" :label="$store.getters.getTextMap().machine" outlined dense
                                item-text="label" item-value="value" required :rules="objectRules"></v-select>
                        </v-col>

                        <!-- Param Options based on the machines-->
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-select :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                                v-model="form.param" :items="paramOptions" :label="$store.getters.getTextMap().param" outlined dense multiple
                                item-text="label" item-value="value" required
                                :rules="[rules.required, rules.nonZeroLength]"></v-select>
                        </v-col>
                    </v-row>

                    <!-- if equation based is enabled then this form will show -->
                    <v-row v-else>
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-text-field v-if="!loading" v-model="form.label" :counter="30"
                            :label="$store.getters.getTextMap().equation_label" dense outlined
                                :rules="[rules.required]"
                                required></v-text-field>
                        </v-col>
                        <v-col>
                            <v-select
                                :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                v-if="!loading"
                                v-model="form.eq"
                                :items="paramEquationOptions"
                                :label="$store.getters.getTextMap().equations"
                                dense
                                outlined
                                item-text="label"
                                item-value="value"
                                required
                                :rules="objectRules"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <!-- Adding params in this space -->
                    <v-row no-gutters>
                        <v-col v-if="!equationBased">
                            <v-chip class="ma-2" close color="indigo" text-color="white" v-for="param in form.param"
                                @click:close="removeParam(param)" :key="param">
                                {{ $store.getters.getNameByParamId(param) }}
                            </v-chip>
                        </v-col>
                        <v-spacer/>

                        <!-- Adding parameter to the lists buttons -->
                        <v-col cols="auto" class="d-flex mt-2" align="right">
                            <v-btn v-if="!equationBased" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="addParamToList">{{$store.getters.getTextMap().add}}
                                <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                            <v-btn v-else :color="$store.getters.getColorPalette().accentCode" small text outlined @click="addParamToList">{{$store.getters.getTextMap().add}}
                                <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                            <v-btn class="mx-1" small text outlined :color="$store.getters.getColorPalette().cancelbtnColor" @click="onReset">{{$store.getters.getTextMap().cancel}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>


            <!-- Added parameters will appear in this table -->
            <v-row class="pa-2" v-if="!loading && tableData">
                <v-col>
                    <v-simple-table height="200px" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                        <template v-slot:default>
                            <thead >
                                <tr>
                                    <th class="text-left"><strong>{{$store.getters.getTextMap().label}}</strong></th>
                                    <th class="text-left"> <strong>{{$store.getters.getTextMap().id}}</strong></th>
                                    <th class="text-left"> <strong>{{$store.getters.getTextMap().type}}</strong></th>
                                    <th class="text-left">{{$store.getters.getTextMap().delete}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Deleting parameter from the list -->
                                <tr :key="item.label" v-for="item in tableData">
                                    <td>{{ item.label }}</td>
                                    <td>{{ item.idType && item.idType==='equation'?item.eq:$store.getters.getNameByParamId(item.param) }}</td>
                                    <td>{{ item && item.idType?item.idType:"parameter" }}</td>
                                    <td>
                                        <DeleteConfirmation v-on:confirm="deleteFromTable(item)"
                                            title="Delete Confirmation"
                                            description="Are you sure you want to delete this?">
                                            <v-icon small color="red">mdi-delete</v-icon>
                                        </DeleteConfirmation>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <!-- <v-row class="pa-2" v-else>
                <v-col v-if="!loading && tableData">
                    <v-simple-table height="200px" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                        <template v-slot:default>
                            <thead >
                                <tr>
                                    <th class="text-left"><strong>Label</strong></th>
                                    <th class="text-left"> <strong>ID</strong></th>
                                    <th class="text-left">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="item.eq" v-for="item in tableData">
                                    <td>{{ item.label }}</td>
                                    <td>{{ item.eq}}</td>
                                    <td>
                                        <DeleteConfirmation v-on:confirm="deleteEquationFromTable(item)"
                                            title="Delete Confirmation"
                                            description="Are you sure you want to delete this equation?">
                                            <v-icon small color="red">mdi-delete</v-icon>
                                        </DeleteConfirmation>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row> -->
        </v-card>

        <!-- Submiting and reseting widget creation form -->
        <v-row no-gutters>
            <v-spacer/>
            <v-col cols="auto" class="d-flex mt-2" align="right">
                <v-btn v-if="tableDataContainsEquation && (widgetForm.meta.time_frame==='rtd' ||  timeFrame==='rtd')" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" disabled small filled>Submit</v-btn>
                <v-btn v-else :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small filled  @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                <v-btn class="mx-1" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name: 'AddBarTDWidget',
    props: ['widget', 'seq', 'disableTimeframe'],
    components: {
        InfoAlert,
        DeleteConfirmation
    },
    mounted() {
        this.getParamEquations()
        this.init()
    },
    data() {
        return {
            showDismissibleAlert: false,
            info: '',
            loading: false,
            showDialog: false,
            valid: false,
            ValidMulti: false,
            widgetValid: false,
            counter: 0,
            show: false,
            dialog: false,
            api: 'createWidgetForView',
            edit: false,
            workspace: null,
            machine: null,
            legend: false,
            textInfo: null,
            graphType: 2,
            equationBased:false,
            barmode: 'group',
            idealValue: 0,
            showIdeal: false,
            showTotal: false,
            showAvg: false,
            idealLabel: '',
            tableData: [],
            form: {},
            widgetForm: {},
            machineOptions: [],
            paramOptions: [],
            paramEquations:[],
            tableDataContainsEquation:false,
            barmodeOptions: [
                { label: 'Stacked', value: 'stack' },
                { label: 'Grouped', value: 'group' }
            ],
            widthOptions: [
                { label: 'Full screen', value: 12 },
                { label: 'Half screen', value: 6 },
                { label: '1/3 screen', value: 4 },
                { label: 'Quarter screen', value: 3 },
                {label:'3/4 screen', value:9},
                { label: 'Auto', value: null }
            ],
            heightOptions: [
                { label: 'Fixed', value: 'fixed' },
                { label: 'Flex', value: 'flex' },
            ],
            graphTypeOptions: [
                { label: 'Bar', value: 2 },
                { label: 'Line', value: 1 },
            ],
            tdOptions: [
                { label: 'Real Time', value: 'rtd' },
                { label: 'MTD (Month Till Date)', value: 'mtd' },
                { label: 'YTD (Year Till Date)', value: 'ytd' },
                { label: 'last 7 days', value: '7' },
                { label: 'last 30 days', value: '30' },
                { label: 'last 60 days', value: '60' },
                { label: 'last 90 days', value: '90' },
                { label: 'last 180 days', value: '180' },
                { label: 'last 365 days', value: '365' },
            ],
            trOptions: [
                { label: 'Daily', value: 'day' },
                { label: 'Weekly', value: 'week' },
                { label: 'Monthly', value: 'month' },
                { label: 'Yearly', value: 'year' },
            ],
            textInfoOptions: [
                { label: 'Label & percentage', value: 'label+percent' },
                { label: 'Value Only', value: 'value' },
                { label: 'Value & Percentage', value: 'value+percent' },
                { label: 'Percentage only', value: 'percent' },
                { label: 'None', value: 'none' }
            ],
            rules: FormRules.rules,
            objectRules: [
                v => !!v || 'Required',
            ],
            countRules: [
                v => !!v || 'Required',
                v => (v && v > 1) || 'Number of Machine should be greater than 1'
            ],
            yaxisLabel:null
        }
    },
    computed: {

        // Workspaces input fiels data
        workspaceOptions() {
            let op = []
            let g = this.$store.state.workspaces
            for (let i of g) {
                op.push({ value: i.workspace_id, label: i.name })
            }
            return op
        },

        // Param Equation input fields data
        paramEquationOptions(){
            let op=[]
            for(let i of this.paramEquations){
                op.push( {value:i.eq_id, label: i.label })
            }
            return op
         },
        //  timefram getters and setters 
        timeFrame: {
            get: function () {
                if (this.widgetForm && this.widgetForm.meta) {
                    return this.widgetForm.meta.time_frame
                } else {
                    return null
                }
            },
            set: function (newValue) {
                if (this.widgetForm && this.widgetForm.meta) {
                    this.widgetForm.meta.time_frame = newValue
                } else {
                    this.widgetForm.meta = { time_frame: newValue }
                }
            }
        },
        // timerange getters and setters
        timeRange: {
            get: function () {
                if (this.widgetForm && this.widgetForm.meta) {
                    return this.widgetForm.meta.time_range
                } else {
                    return null
                }
            },
            set: function (newValue) {
                if (this.widgetForm && this.widgetForm.meta) {
                    this.widgetForm.meta.time_range = newValue
                } else {
                    this.widgetForm.meta = { time_range: newValue }
                }
            }
        },
    },


    methods: {
        // Initiate widget data update while mounting.
        init() {
            // If widget created already and widget data is there.
            if (this.widget && this.widget.widget_id && this.widget.view_id) {
                this.edit = true
                this.widgetForm = Object.assign({}, this.widget)
                this.api = 'updateWidget'
                if (!(this.widgetForm.meta)) {
                    this.widgetForm.meta = { data: [] }
                    this.tableData = []
                } else {
                    this.tableData = this.widgetForm.meta.data
                    this.graphType = this.widgetForm.meta.graphType
                    this.equationBased = this.widgetForm.meta.equationBased
                    this.barmode = this.widgetForm.meta.barmode
                    this.idealValue = this.widgetForm.meta.idealValue,
                    this.idealLabel = this.widgetForm.meta.idealLabel,
                    this.legend = this.widgetForm.meta.legend
                    this.textInfo = this.widgetForm.meta.textinfo
                    this.showIdeal = this.widgetForm.meta.showIdeal
                    this.showTotal = this.widgetForm.meta.showTotal
                    this.showAvg = this.widgetForm.meta.showAvg
                    this.yaxisLabel = this.widgetForm.meta.yaxis_label
                }
            } else {
                // There is no widget for view then this will initiate
                if (this.widget.view_id) {
                    this.api = 'createWidgetForView'
                    this.widgetForm = Object.assign({}, this.widget)
                    this.widgetForm.meta = { data: [] }
                    this.widgetForm.seq = this.seq ? this.seq : 0
                    this.tableData = []
                }
            }
        },

        // Param equations list
        getParamEquations(){
            this.loading=true
            axios.get(this.$store.state.api+'paramEquations',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.paramEquations=response.data.data
                }else{
                    console.log(response.data.msg)
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                console.log(err)
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },

        // Machine field options data
        getMachineList() {
            let op = []
            if (this.workspace) {
                let payload = {
                    workspace_id: this.workspace
                }
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByWorkspace", payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status == "success") {
                        let machines = response.data.machines
                        for (let i of machines) {
                            if (i && i.name) {
                                op.push({ value: i.machine_id, label: i.name })
                            } else {
                                op.push({ value: i.machine_id, label: i.machine_id })
                            }
                        }
                    } else {
                        console.log(response.data.msg)
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    console.log(error)
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
            this.machineOptions = op
        },

        // params field options data
        getParams() {
            if (this.machine) {
                this.loading = true
                axios.post(this.$store.state.api + 'getParamsByMachine', { machine_id: this.machine }, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status == 'success') {
                        this.loading = false
                        this.paramOptions = []
                        for (let i of response.data.params) {
                            this.paramOptions.push({ label: i.name, value: i.param_id })
                        }
                    } else {
                        console.log(response.data.msg)
                        this.loading = false
                        this.info = response.data.msg
                        this.showDismissibleAlert = true
                    }
                }).catch(err => {
                    console.log(err)
                    this.loading = false
                    this.info = err
                    this.showDismissibleAlert = true
                })
            }
        },

        // Getting param Name
        getParamName(param) {
            let temp = []
            if (param && Array.isArray(param)) {
                for (let i of param) {
                    let p = this.$store.getters.getParameterById(i)
                    if (p) {
                        let param_name = this.$store.getters.getDeviceById(p['device_id'])['name'] + "-" + p['name']
                        temp.push(param_name)
                    } else {
                        return "Issue with getting Param Name"
                    }
                }

                return temp
            } else {
                let p = this.$store.getters.getParameterById(param)
                if (p) {
                    return this.$store.getters.getDeviceById(p['device_id'])['name'] + "-" + p['name']
                } else {
                    return "Issue with getting Param Name"
                }
            }
        },

        // Adding param to list
        addParamToList() {
            this.$refs.usageForm.validate()
            if (this.valid) {
                this.loading = true
                if (!this.tableData) {
                    this.tableData = []
                }
                if(this.equationBased){
                    if(this.widgetForm.meta.time_frame==='rtd' || this.timeFrame==='rtd'){
                        this.info = "Realtime data is restricted with Param Equations. Choosing Real time for Param equations will not show any Real time data";
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }else{
                        this.tableData.push({ label: this.form && this.form.label?this.form.label:this.form.eq && this.form.eq.label, eq: this.form.eq,idType:"equation" })
                    }
                }else{
                    this.tableData.push({ label: this.form.label, param: this.form.param,idType:"parameter" })
                }
                this.checkTableDataForEquation()
                this.form = {}
                this.loading = false
            }
        },

        //  Table data for equation table
        checkTableDataForEquation(){
            this.tableDataContainsEquation=false
            let count=0
            for(let i of this.tableData){
                if(i.idType && i.idType==='equation'){
                    count+=1
                }
            }
            if(count>0){
                this.tableDataContainsEquation=true
            }
        },
        // addEquationToList() {
        //     this.$refs.usageForm.validate()
        //     if (this.valid) {
        //         this.loading = true
        //         if (!this.tableData) {
        //             this.tableData = []
        //         }
        //         this.tableData.push({ label: this.form && this.form.label?this.form.label:this.form.eq && this.form.eq.label, eq: this.form.eq })
        //         this.form = {}
        //         this.loading = false
        //     }
        // },

        // Submit for create and edit.
        onSubmit() {
            this.$refs.widgetForm.validate()
            if (this.widgetValid) {
                this.loading = true
                this.widgetForm.meta.data = this.tableData
                this.widgetForm.meta.graphType = this.graphType
                this.widgetForm.meta.legend = this.legend
                this.widgetForm.meta.textinfo = this.textInfo
                this.widgetForm.meta.barmode = this.barmode
                this.widgetForm.meta.idealValue = this.idealValue
                this.widgetForm.meta.idealLabel = this.idealLabel
                this.widgetForm.meta.showIdeal = this.showIdeal
                this.widgetForm.meta.showTotal = this.showTotal
                this.widgetForm.meta.showAvg = this.showAvg
                this.widgetForm.meta.yaxis_label = this.yaxisLabel
                this.widgetForm.meta.equationBased=this.equationBased

                console.log(this.widgetForm,"hello");

                axios.post(this.$store.state.api + this.api, this.widgetForm, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.loading = false
                        this.$emit('success')
                        this.$emit('close')
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading = false
                });
            }
        },
        onReset() {
            this.showSingleParam = false
            this.showMultiParam = false
            this.show = false
            this.dialog = false
            this.counter = 0
            this.workspace = null
            this.machine = null
            this.form.label = null
            this.paramEquations=[]
            this.equationBased=false
            this.$refs.usageForm.reset()
            this.loading = false
        },
        onClose() {
            this.show = false,
            this.dialog = false,
            this.counter = 0
            this.workspace = null
            this.machine = null
            this.form.label = null
            this.form = {}
            this.$refs.widgetForm.reset()
            this.loading = false
            this.$emit('close')
        },
        removeParam(param) {
            for(let i of this.form.param){
                if(i == param){
                    this.form.param.pop(i)
                }
            }
        },
        deleteFromTable(item) {
            if(item.idType && item.idType=="equation"){
                this.tableData = this.tableData.filter(x => x.eq != item.eq)
            }else{
                this.tableData = this.tableData.filter(x => x.label != item.label)
            }
            this.checkTableDataForEquation()
        },

    },
    // Watchers for all input fields in the
    watch: {
        workspace() {
            this.getMachineList()
        },
        machine() {
            this.getParams()
        },
        widget() {
            this.init()
        },
        timeFrame(){
            if(this.tableDataContainsEquation && this.widgetForm.meta.time_frame==='rtd'){
                this.info = "Realtime data is restricted with Param Equations. Choosing Real time for Param equations will not show any Real time data";
                this.showDismissibleAlert = true;
                this.loading = false
            }
        },
        tableData(){
            this.checkTableDataForEquation()
        }
        // equationBased(){
        //     this.tableData=[]
        // }
    }
}
</script>