<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <v-card :style="{
      'border-radius': '2px',
      border:
        '1px solid' + $store.getters.getColorPalette().panelBorderColorCode,
    }"
    flat
    :dark="$store.getters.getColorPalette().isDark"
    :color="$store.getters.getColorPalette().background2ColorCode">
    <v-card-title>
        <span :style="{'color':  $store.getters.getColorPalette().accentCode}" class="text-h5">{{ $store.getters.getTextMap().submission_summary }} - Last 30 Days</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle>
        <v-row>
            <v-col>
                <v-card flat :dark="$store.getters.getColorPalette().isDark" >

                    <strong class="text-h5">{{ $store.getters.getTextMap().submission_adherence }}:</strong> <span class="text-h5">{{ submissionCompliance }}%</span>
                    <!-- <apexchart
                                                ref="semiGauge"
                                                type="radialBar"
                                                :options="gaugeOptions"
                                                :series="[submissionCompliance]"
                                            ></apexchart> -->
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card flat :dark="$store.getters.getColorPalette().isDark" >

                    <strong>{{ $store.getters.getTextMap().total_checklists_submitted }}:</strong> <span class="text-h6">{{ totalChecklistSubmitted }}</span>
                </v-card>
            </v-col>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-col>
                <v-card flat :dark="$store.getters.getColorPalette().isDark" >

                    <strong>{{ $store.getters.getTextMap().Total_auto_submitted_checklists }}: </strong><span class="text-h6">{{ autoSubmittedTotal }}</span>
                </v-card>
            </v-col>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-col>
                <v-card flat :dark="$store.getters.getColorPalette().isDark" >

                    <strong>{{ $store.getters.getTextMap().auto_submitted_empty_checklists }}:</strong> <span class="text-h6">{{ autoSubmittedEmpty }}</span>
                </v-card>
            </v-col>
        </v-row>
        
        
    </v-card-subtitle>

        </v-card>

        
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert.vue';
import axios from 'axios';
// import VueApexCharts from "vue-apexcharts";
// import moment from 'moment';
export default {
    name: 'ChecklistSubmissionStats',
    components: {
        InfoAlert,
        // apexchart: VueApexCharts,
    },
    mounted(){

        this.getStats()

    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:'',
            tableData:[],
            gaugeOptions:{
                    //   series: [75],
                    chart: {
                    height: 10,
                    type: 'radialBar',
                    toolbar: {
                        show: true
                    }
                    },
                    plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 225,
                        hollow: {
                        margin: 0,
                        size: '70%',
                        background: this.$store.getters.getColorPalette().background2ColorCode,
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                        dropShadow: {
                            enabled: true,
                            top: 3,
                            left: 0,
                            blur: 4,
                            opacity: 0.5
                        }
                        },
                        track: {
                        background: this.$store.getters.getColorPalette().background2ColorCode,
                        strokeWidth: '67%',
                        margin: 0, // margin is in pixels
                        dropShadow: {
                            color: this.$store.getters.getColorPalette().accentCode,
                            enabled: true,
                            top: -3,
                            left: 0,
                            blur: 4,
                            opacity: 0.7
                        }
                        },
                    
                        dataLabels: {
                        show: true,
                        name: {
                            offsetY: -2,
                            show: true,
                            color: this.$store.getters.getColorPalette().accentCode,
                            fontSize: '17px'
                        },
                        value: {
                            formatter: function(val) {
                            return val.toString()+' %';
                            },
                            color: this.$store.getters.getColorPalette().accentCode,
                            fontSize: '27px',
                            show: true,
                        }
                        }
                    }
                    },
                    fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#EF5350'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 0.8,
                        stops: [0, 100]
                    }
                    },
                    stroke: {
                    lineCap: 'round'
                    },
                    labels: ['Submission Adherence'],
                    }
        }
    },
    computed:{
        totalChecklistSubmitted(){
            return this.tableData.reduce((acc,curr)=>{
                return acc+Number(curr.count)

            },0)
        },
        autoSubmittedTotal(){
            return this.tableData.reduce((acc,curr)=>{
                if(curr.auto_submitted){

                    return acc+Number(curr.count)
                }
                return acc
            },0)
        },
        autoSubmittedEmpty(){
            return this.tableData.reduce((acc,curr)=>{
                if(curr.auto_submitted && !curr.fill_started){

                    return acc+Number(curr.count)
                }
                return acc
            },0)
        },
        submissionCompliance(){
            if(this.totalChecklistSubmitted){
                return Math.round((1-(this.autoSubmittedTotal/this.totalChecklistSubmitted))*100)
            }
            return '-'
        }



    },
    methods:{
        getStats(){
            let payload={}
            axios.post(this.$store.state.api+'getChecklistSubmissionStats',payload,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                  }}).then(response=>{
                    // console.log(response.data)
                  if(response.data.status==='success'){

                    this.tableData=response.data.data



                  }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                  }
                  this.loading=false
                }).catch(err=>{
                    this.info=err
                    this.loading=false
                    this.showDismissibleAlert=true
                })

        },

    }



}
</script>