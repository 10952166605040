<template>
  <v-container fluid @contextmenu="show" :style="{ 'background-color': widget.background_color }">
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
    <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog = true"
      v-if="$store.state.settingMode && edit">
      <v-list :dark="$store.getters.getColorPalette().isDark"
        :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-list-item @click="update = true">
          <v-list-item-title><strong>{{ $store.getters.getTextMap().edit }}</strong></v-list-item-title>
          <v-list-item-icon>
            <v-icon small>mdi-pencil</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item @click="deleteWidget">
          <v-list-item-title><strong>{{ $store.getters.getTextMap().delete }}</strong></v-list-item-title>
          <v-list-item-icon>

            <v-icon color="red" small>mdi-trash-can</v-icon>

          </v-list-item-icon>
        </v-list-item>
        <v-list-item @click="downloadImage">
          <v-list-item-title><strong>{{ $store.getters.getTextMap().download }}</strong></v-list-item-title>
          <v-list-item-icon>
            <v-icon color="green" small>mdi-download</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card :dark="$store.getters.getColorPalette().isDark"
        :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-toolbar :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().foregroundColorCode">
          <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ widget.label }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false">{{
              $store.getters.getTextMap().close }}</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <AddImageWidget :widget="widget" v-on:close="update = false"
          v-on:success="$emit('update')" />
      </v-card>
    </v-dialog>


    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
    <div v-if="widget.meta.image"
      :style="[widget.height === 'fixed' ? { 'background-color': $store.getters.getColorPalette().background2ColorCode, 'height': '175px', } : {}]">
      <div class="ma-0 pa-1 text-center">
        <h3 class="text-bold">{{ widget && widget.label ? widget.label : '' }}</h3>
      </div>
      <v-img :src="widget.meta.image" :style="[widget.height === 'fixed'?{'height':'130px', 'object-fit':'contain'}:{}]" class="rounded-lg" ></v-img>
    </div>

    <div v-else
      :style="[widget.height === 'fixed' ? { 'height': '175px', 'text-align': 'center' } : { 'text-align': 'center' }]">
      <v-icon small>mdi-hail</v-icon>
      <h5>{{ $store.getters.getTextMap().not_configured }}</h5>
    </div>
  </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import AddImageWidget from '@/components/crud_components/widgets/AddImageWidget'
import axios from "axios"
export default {
  name: 'ImageWidget',
  props: ['widget', 'edit'],
  components: {
    InfoAlert,
    AddImageWidget
  },
  mounted() {
    if (this.widget && this.widget.meta && this.widget.meta.image) {
      this.image = this.widget.meta.image
      console.log("imagebase64", this.image);
    }

  },
  data() {
    return {
      update: false,
      showMenu: false,
      image: "",
      x: 0,
      y: 0,
      showDialog: false,
      loading: false,
      showDismissibleAlert: false,
      info: '',
    }
  },

  methods: {



    show(e) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      if (this.$store.state.settingMode) {
        this.$nextTick(() => {
          this.showMenu = true
        })
      }
    },

    deleteWidget() {
      this.loading = true
      axios.post(this.$store.state.api + 'deleteWidget', this.widget, {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.jwt
        }
      }).then(response => {
        if (response.data.status === 'success') {
          this.dialog = false
          this.loading = false
          this.$emit('update')
        }
      })
        .catch(err => {
          console.error(err)
          this.loading = false
        });
    },
    cancel() {
      this.showDialog = false
    },
    confirm() {

      this.showDialog = false
    },
    downloadImage(){
      const  imageURL = this.widget.meta.image
      const link = document.createElement("a")
      link.href = imageURL
      link.download = "Widget-Image"; 
      link.click(); 
    }

  }
}
</script>