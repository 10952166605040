import axios from 'axios'
// import moment from 'moment'
// import DataProcessingUtillities from '@/utillities/DataProcessingUtillities'

export default {
  addWorkspace: function (context, workspace) {
    axios.post(context.state.api + 'createWorkspace', workspace, {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        if (response.data.status === 'success') {
          context.commit('addWorkspace', response.data.workspace)
          // console.log("Workspace added " + workspace)
        } else {
          console.log(response.data.msg)
        }
      })
      .catch(err => { console.log(err) });

  },
  addNode: function (context, node) {
    axios.post(context.state.api + 'addNode', node, {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        if (response.data.status === 'success') {
          context.commit('addNode', node)
          console.log("Node added " + node)
        } else {
          console.log(response.data.msg)
        }
      })
      .catch(err => { console.log(err) });

  },
  // addSchedule: function (context, schedule) {
  //   axios.post(context.state.api + 'addSchedule', schedule, {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       if (response.data.status === 'success') {
  //         context.commit('addSchedule', schedule)
  //         console.log("Schedule added " + schedule)
  //       } else {
  //         console.log(response.data.msg)
  //       }
  //     })
  //     .catch(err => { console.log(err) });

  // },
  addDevice: function (context, device) {
    device.device_id = Math.floor(Date.now() / 1000).toString()
    axios.post(context.state.api + 'addDevice', device, {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        if (response.data.status === 'success') {
          context.commit('addDevice', device)
          console.log("Device added " + device)
        } else {
          console.log(response.data.msg)
        }
      })
      .catch(err => { console.log(err) });

  },
  // reportState: function (context, node) {
  //   axios.post(context.state.api + 'reportState', node, {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       if (response.data.status === 'success') {
  //         // context.commit('updateNode', node)
  //         // setTimeout(() => { context.dispatch('refreshNodeStates') }, 5000)

  //       } else {
  //         console.log(response.data.msg)
  //       }
  //     })
  //     .catch(err => { console.log(err) });
  // },
  updateDevice: function (context, device) {
    axios.post(context.state.api + 'updateDevice', device, {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        if (response.data.status === 'success') {
          context.commit('updateDevice', device)
        } else {
          console.log(response.data.msg)
        }
      })
      .catch(err => { console.log(err) });
  },
  // toggleDevice: function (context, device) {
  //   axios.post(context.state.api + 'thingUpdate', device, {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       if (response.data.status === 'success') {
  //         context.commit('updateDevice', device)
  //       } else {
  //         console.log(response.data.msg)
  //       }
  //     })
  //     .catch(err => { console.log(err) });
  // },


  // updateGroup: function (context, group) {
  //   axios.post(context.state.api + 'updateGroup', group, {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       if (response.data.status === 'success') {
  //         context.commit('updateGroup', group)
  //       } else {
  //         console.log(response.data.msg)
  //       }
  //     })
  //     .catch(err => { console.log(err) });
  // },
  // addGroup: function (context, group) {
  //   group.id = Math.floor(Date.now() / 1000).toString()
  //   //item.group.total_consumed=0
  //   //item.group.total_wasted=0
  //   group.items = []
  //   axios.post(context.state.api + 'createGroup', group, {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       if (response.data.status === 'success') {
  //         context.commit('addGroup', group)
  //       } else {
  //         console.log(response.data.msg)
  //       }
  //     })
  //     .catch(err => { console.log(err) });
  // },
  // deleteGroup: function (context, group) {
  //   axios.post(context.state.api + 'deleteGroup', group, {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       if (response.data.status === 'success') {
  //         context.dispatch('refreshGroups')
  //       } else {
  //         console.log(response.data.msg)
  //       }
  //     })
  //     .catch(err => { console.log(err) })
  // },
  // deleteNode: function (context, node) {

  //   axios.post(context.state.api + 'deleteNode', node, {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       if (response.data.status === 'success') {
  //         context.dispatch('refreshNodes')
  //         // context.dispatch('refreshNodeStates')

  //       } else {
  //         console.log(response.data.msg)
  //       }
  //     })
  //     .catch(err => { console.log(err) })
  // },
  // deleteSNode: function (context, node) {

  //   axios.post(context.state.api + 'deleteSNode', node, {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       if (response.data.status === 'success') {
  //         context.dispatch('refreshSNodes')
  //         //context.dispatch('refreshNodeStates')

  //       } else {
  //         console.log(response.data.msg)
  //       }
  //     })
  //     .catch(err => { console.log(err) })
  // },
  // deleteSchedule: function (context, schedule) {
  //   axios.post(context.state.api + 'deleteSchedule', schedule, {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       if (response.data.status === 'success') {
  //         context.dispatch('refreshSchedules')
  //       } else {
  //         console.log(response.data.msg)
  //       }
  //     })
  //     .catch(err => { console.log(err) })
  // },
  deleteParameter: function (context, parameter) {

    axios.post(context.state.api + 'deleteParameter', parameter, {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        if (response.data.status === 'success') {
          context.dispatch('refreshParameters')
          //context.dispatch('refreshNodeStates')

        } else {
          console.log(response.data.msg)
        }
      })
      .catch(err => { console.log(err) })
  },
  // calculateSnodeUsage: async function (context) {
  //   let df = null
  //   for (let i of context.state.snodes) {
  //     let data = context.getters.getLast30DaySNodeDataByID(i.snode_id)
  //     //console.log(data)
  //     df = DataProcessingUtillities.mergeDataFrameAndDataByKey(df, 'date', data, "consumed")
  //   }
  //   console.log(df)
  //   context.commit('setCurrentSnodeUsage', df)
  // },
  refresh: function (context) {
    //console.log("refresh initiated")
    if (localStorage.getItem('enture_token')) {
      context.commit("setJWT", localStorage.getItem('enture_token'));
    }
    axios.get(context.state.api + 'login', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.loggedIn=response.data.loggedIn
        context.commit("setLoggedIn", response.data.loggedIn)
        if (response.data.loggedIn) {
          //context.state.user=response.data.user
          context.commit("setUser", response.data.user)
          //context.state.jwt=response.data.jwt



          context.dispatch('refreshOrganisation')
          context.dispatch('refreshUserData')
          
          context.dispatch('refreshMachines')
          context.dispatch('refreshProcesses')
          context.dispatch('refreshDeviceTypes')

          context.dispatch('refreshDevices')
          context.dispatch('refreshS7Devices')
          context.dispatch('refreshVDevices')
          context.dispatch('refreshMQTTDevices')
          // context.dispatch('refreshNodes')

          context.dispatch('refreshParameters')
          // context.dispatch('refreshParams') // called inside refreshParameters
          context.dispatch('refreshS7Params')
          context.dispatch('refreshOPCParams')
          context.dispatch('refreshMQTTParameters')
          context.dispatch('refreshComputedParameters')
          context.dispatch('refreshWriteParams')
          context.dispatch('refreshManualParameters')
          context.dispatch('refreshComboParams')
          context.dispatch('refreshCalculatedParams')
          context.dispatch('refreshDerivedParams')
          
          
          context.dispatch('refreshDepartments')
          context.dispatch('refreshIssuerList')

          context.dispatch('refreshMDeviceTypes')
          context.dispatch('refreshDataTypes')
          context.dispatch('refreshTagTypes')
          context.dispatch('refreshOrgTypes')
          context.dispatch('refreshCountryCodes')
          context.dispatch('refreshMGates')
          // context.dispatch('refreshSNodes')
          context.dispatch('refreshSchedules')
          
          context.dispatch('refreshHubs')
          // context.dispatch('refreshGroups')
          if (context.getters.getAccessByLevels(['logs'])) { 
            context.dispatch('refreshChecklistsMakersList')
            context.dispatch('refreshChecklistsApproversList')
            context.dispatch('refreshChecklistsCheckersList')
            context.dispatch('refreshProductionLogs')
          context.dispatch('refreshDataLog')
          context.dispatch('refreshCapacityLogs')
          context.dispatch('refreshDynamicLogs')
          context.dispatch('refreshBatchProductionLogs')
          context.dispatch('refreshShiftMachineUsageLogs')
          }
          if (context.getters.getAccessByLevels(['device'])) {
            context.dispatch('refreshWorkspaces')
            context.dispatch('refreshActions')
            context.dispatch('refreshSheets')
            context.dispatch('refreshTargets')
            context.dispatch('refreshTargetData')
            context.dispatch('refreshTariffs')
            context.dispatch('refreshTariffData')
            context.dispatch('refreshSKUs')
            context.dispatch('refreshProducts')
            context.dispatch('refreshLocations')
            context.dispatch('refreshLocationEventTypes')
            context.dispatch('refreshTriggers')
            context.dispatch('refreshParamTriggerMappings')
            context.dispatch('refreshActionMappings')

            context.dispatch('refreshLists')
            context.dispatch('refreshListData')
            context.dispatch('refreshShifts')
            context.dispatch('refreshShiftData')
            // context.dispatch('refreshNotifications')
            context.dispatch('refreshViews')
            context.dispatch('refreshModules')
            context.dispatch('refreshAreas')
            context.dispatch('refreshActivities')
            context.dispatch('refreshTasks')
            context.dispatch('refreshDashboardPanels')
            context.dispatch('refreshDashboardGauges')
            context.dispatch('refreshDashboardParams')
            context.dispatch('refreshDashboardMachines')
            context.dispatch('refreshDashboardView')
          }
          if (context.getters.getAccessByLevels(['report'])) {
            //context.dispatch('refreshlast7Days')
            //context.dispatch('refreshLast7DayComputedParamData')
            //context.dispatch('refreshMonthlyData')
            context.dispatch('refreshReports')
            context.dispatch('refreshUsageReports')
            context.dispatch('refreshEnergyUsageReports')
            context.dispatch('refreshEnergyReports')
            context.dispatch('refreshEnergyDistributionReports')
            context.dispatch('refreshEnergyBillDistReports')
            context.dispatch('refreshSECReports')
            // context.dispatch('refreshgranularSECReports')
            context.dispatch('refreshConsolidatedReports')
            context.dispatch('refreshDynamicExcelReports')
            context.dispatch('refreshExcelTemplateBasedReports')
          }
          if (context.getters.getAccessByLevels(['engineeraccess'])) {
            context.dispatch('refreshMaintainedOrgs')
            context.dispatch('refreshWords')
          }
          if (context.getters.getAccessByLevels(['uam'])) {
            context.dispatch('refreshRoles')
            context.dispatch('refreshUserGroups')
          }
          context.dispatch('refreshUAMUsers')
          // context.dispatch('refreshData')

          context.dispatch('refreshNotificationGroups')
          context.dispatch('refreshMachineGroups')
          context.dispatch('refreshParamGroups')



          //console.log("refresh complete")
        }
      }).catch(err => { console.log(err) })
  },
  refreshData: function (context) {
    //console.log("refresh initiated")
    if (context.state.loggedIn) {
      //context.dispatch('refreshCurrentSNodeData')
      //context.dispatch('refreshCurrentParameterData')
      //context.dispatch('refreshNodeStates')
      //context.dispatch('refreshCurrentDerivedParameterData')
    }
  },
  refreshLogin: function (context) {
    axios.get(context.state.api + 'login', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        context.commit("setLoggedIn", response.data.loggedIn)
        context.commit("setUser", response.data.user)
        //context.state.loggedIn=response.data.loggedIn
        //context.state.user=response.data.user


      }).catch(err => { console.log(err) })
  },
  refreshUserData: function (context) {
    axios.get(context.state.api + 'getUserDetails', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        context.commit("setUserData", response.data.data)
        if (response.data.data && response.data.data.theme) {
          context.commit("setTheme", response.data.data.theme)
        } else {
          context.commit("setTheme", response.data.data.default_theme)
        }
        if(response.data.data && response.data.data.lang){
          context.commit("setLang",response.data.data.lang)
        }
        //context.state.loggedIn=response.data.loggedIn
        //context.state.user=response.data.user


      }).catch(err => { console.log(err) })
  },
  refreshReports: function (context) {
    axios.get(context.state.api + 'reports', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        if (response.data.status === 'success') {
          let rep = []
          let secRep = []
          for (let i of response.data.reports) {
            if (i['report_type'] == 'sec') {
              secRep.push(i)
            } else {
              rep.push(i)
            }
          }
          context.commit("setReports", rep)
          context.commit("setSECReports", secRep)
        } else {
          console.log(response.data.msg)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshSECReports: function (context) {
    axios.get(context.state.api + 'secReports', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        if (response.data.status === 'success') {

          let secRep = []
          for (let i of response.data.reports) {

            secRep.push(i)

          }

          context.commit("setSECTimeFrameReports", secRep)
        } else {
          console.log(response.data.msg)
        }

      })
      .catch(err => { console.log(err) })
  },
  // refreshgranularSECReports: function (context) {
  //   axios.get(context.state.api + 'granularSECReports', {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       //context.state.groups=response.data.groups
  //       if (response.data.status === 'success') {

  //         let secRep = []
  //         for (let i of response.data.reports) {

  //           secRep.push(i)

  //         }

  //         context.commit("setGranularSECReports", secRep)
  //       } else {
  //         console.log(response.data.msg)
  //       }

  //     })
  //     .catch(err => { console.log(err) })
  // },
  refreshConsolidatedReports: function (context) {
    axios.get(context.state.api + 'getConsolidatedReports', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        if (response.data.status === 'success') {
          let reps = []
          for (let i of response.data.reports) {
            i['meta'] = JSON.parse(i.meta)
            console.log(i['meta'])
            reps.push(i)
          }
          context.commit("setConsolidatedReports", reps)
        } else {
          console.error(response.data.msg)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshDynamicExcelReports: function (context) {
    axios.get(context.state.api + 'getDynamicExcelReports', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        if (response.data.status === 'success') {
          let reps = []
          for (let i of response.data.reports) {
            i['meta'] = JSON.parse(i.meta)
            // console.log(i['meta'])
            reps.push(i)
          }
          context.commit("setDynamicReports", reps)
        } else {
          console.error(response.data.msg)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshExcelTemplateBasedReports: function (context) {
    axios.get(context.state.api + 'getExcelTemplateBasedReports', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        if (response.data.status === 'success') {
          let reps = []
          for (let i of response.data.reports) {
            i['meta'] = JSON.parse(i.meta)
            // console.log(i['meta'])
            reps.push(i)
          }
          context.commit("setExcelTemplateBasedReports", reps)
        } else {
          console.error(response.data.msg)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshUsageReports: function (context) {
    axios.get(context.state.api + 'usageReports', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        if (response.data.status === 'success') {

          let secRep = []
          for (let i of response.data.reports) {

            secRep.push(i)

          }
          context.commit("setUsageReports", secRep)
        } else {
          console.error(response.data.msg)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshEnergyUsageReports: function (context) {

    axios.get(context.state.api + 'energyUsageReports', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        if (response.data.status === 'success') {

          let secRep = []
          for (let i of response.data.reports) {

            secRep.push(i)

          }
          context.commit("setEnergyUsageReports", secRep)
        } else {
          console.error(response.data.msg)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshEnergyReports: function (context) {

    axios.get(context.state.api + 'energyReports', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        if (response.data.status === 'success') {

          let secRep = []
          for (let i of response.data.reports) {

            secRep.push(i)

          }
          context.commit("setEnergyReports", secRep)
        } else {
          console.error(response.data.msg)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshEnergyDistributionReports: function (context) {

    axios.get(context.state.api + 'energyDistributionReports', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        if (response.data.status === 'success') {

          let secRep = []
          for (let i of response.data.reports) {

            secRep.push(i)

          }
          context.commit("setEnergyDistributionReports", secRep)
        } else {
          console.error(response.data.msg)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshEnergyBillDistReports: function (context) {

    axios.get(context.state.api + 'energyBillDistReports', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        if (response.data.status === 'success') {

          let secRep = []
          for (let i of response.data.reports) {

            secRep.push(i)

          }
          context.commit("setEnergyBillDistReports", secRep)
        } else {
          console.error(response.data.msg)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshChecklistsMakersList: function (context) {
    axios.get(context.state.api + 'checklistsMakersList', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setChecklistsMakersList", response.data.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshChecklistsApproversList: function (context) {
    axios.get(context.state.api + 'checklistsApproversList', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setChecklistsApproversList", response.data.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshChecklistsCheckersList: function (context) {
    axios.get(context.state.api + 'checklistsCheckersList', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setChecklistsCheckersList", response.data.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshDepartments: function (context) {
    axios.get(context.state.api + 'departments', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setDepartments", response.data.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshIssuerList: function (context) {
    axios.get(context.state.api + 'itemIssuers', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setItemIssuerList", response.data.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshActions: function (context) {
    axios.get(context.state.api + 'actions', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setActions", response.data.actions)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshSheets: function (context) {
    axios.get(context.state.api + 'sheets', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setSheets", response.data.sheets)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshTargets: function (context) {
    axios.get(context.state.api + 'targets', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setTargets", response.data.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshTariffs: function (context) {
    axios.get(context.state.api + 'tariffs', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setTariffs", response.data.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshSKUs: function (context) {
    axios.get(context.state.api + 'sku', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setSKUs", response.data.data)
        }

      })
      .catch(err => { console.error(err) })
  },
  refreshProducts: function (context) {
    axios.get(context.state.api + 'products', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setProducts", response.data.data)
        }

      })
      .catch(err => { console.error(err) })
  },
  refreshLocations: function (context) {
    axios.get(context.state.api + 'location', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setLocations", response.data.data)
        }

      })
      .catch(err => { console.error(err) })
  },
  refreshLocationEventTypes: function (context) {
    axios.get(context.state.api + 'locationEventType', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setLocationEventTypes", response.data.data)
        }

      })
      .catch(err => { console.error(err) })
  },
  refreshActionMappings: function (context) {
    axios.get(context.state.api + 'actionMappings', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          //console.log(response.data)
          context.commit("setActionMapping", response.data.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshTriggers: function (context) {
    axios.get(context.state.api + 'triggers', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setTriggers", response.data.triggers)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshParamTriggerMappings: function (context) {
    axios.get(context.state.api + 'triggerParamMappings', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        //TODO - check response status
        if (response.data.status == 'success') {
          context.commit("setParamTriggerMapping", response.data.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  // refreshParamTargetMappings: function (context) {
  //   axios.get(context.state.api + 'targetParamMappings', {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       //context.state.groups=response.data.groups
  //       //TODO - check response status
  //       if (response.data.status == 'success') {
  //         context.commit("setParamTargetMapping", response.data.data)
  //       }

  //     })
  //     .catch(err => { console.log(err) })
  // },
  refreshDashboardPanels: function (context) {
    axios.get(context.state.api + 'dashboardPanels', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          //console.log("got data")
          //console.log(response.data)
          context.commit("setDashboardPanels", response.data.dashboardPanels)
        } else {
          console.log(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshDashboardGauges: function (context) {
    axios.get(context.state.api + 'dashboardGauges', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          //console.log("got data")
          //console.log(response.data)
          context.commit("setDashboardGauges", response.data.dashboardGauges)
        } else {
          console.log(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshDashboardParams: function (context) {
    axios.get(context.state.api + 'dashboardParams', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          //console.log("got data")
          //console.log(response.data)
          context.commit("setDashboardParams", response.data.data)
        } else {
          console.log(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshDashboardMachines: function (context) {
    axios.get(context.state.api + 'dashboardMachines', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          context.commit("setDashboardmachines", response.data.data)
        } else {
          console.log(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshModules: function (context) {
    axios.get(context.state.api + 'entureModules', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {

          context.commit("setModules", response.data.modules)
        } else {
          console.error(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshAreas: function (context) {
    axios.get(context.state.api + 'areas', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {

          context.commit("setAreas", response.data.areas)
        } else {
          console.error(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshActivities: function (context) {
    axios.get(context.state.api + 'activities', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {

          context.commit("setActivities", response.data.activities)
        } else {
          console.error(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshTasks: function (context) {
    axios.get(context.state.api + 'tasks', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {

          context.commit("setTasks", response.data.tasks)
        } else {
          console.error(response.data)
        }
        
      })
      .catch(err => { console.log(err) })
    },
    refreshViews: function (context) {
      context.commit('setViewsLoaded',false)
      axios.get(context.state.api + 'entureViews', {
        headers: {
          Authorization: 'Bearer ' + context.state.jwt
        }
      })
      .then(response => {
        
        if (response.data.status == 'success') {
          //console.log("got data")
          //console.log(response.data)
          let views = []
          for (let i of response.data.views) {
            i['data'] = JSON.parse(i['data'])
            views.push(i)
          }
          context.commit("setViews", views)
        } else {
          console.error(response.data)
        }
        context.commit('setViewsLoaded',true)
      })
      .catch(err => { console.log(err) })
  },
  refreshDashboardView: function (context) {
    axios.get(context.state.api + 'dashboardView', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {

        if (response.data.status == 'success') {
          //console.log("got data")
          //console.log(response.data)
          let view = null
          if (response.data.data) {
            view = response.data.data.view_id
          }
          context.commit("setDashboardView", view)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshLists: function (context) {
    axios.get(context.state.api + 'lists', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        //TODO - check response status
        if (response.data.status == 'success') {
          context.commit("setLists", response.data.lists)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshCalculatedParams: function (context) {
    axios.get(context.state.api + 'calculatedParams', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        
        if (response.data.status == 'success') {
          // console.log('setCalculatedParams')
          context.commit("setCalculatedParams", response.data.calculated_params)
        }else{
          console.error(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshDerivedParams: function (context) {
    axios.get(context.state.api + 'derivedParams', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        //TODO - check response status
        if (response.data.status == 'success') {
          context.commit("setDerivedParameters", response.data.derived_params)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshComboParams: function (context) {
    axios.get(context.state.api + 'comboParams', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        //TODO - check response status
        if (response.data.status == 'success') {
          context.commit("setComboParams", response.data.comboParams)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshProductionLogs: function (context) {
    axios.get(context.state.api + 'productionLogs', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        //TODO - check response status
        if (response.data.status == 'success') {
          context.commit("setProductionLogs", response.data.logs)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshDataLog: function (context) {
    axios.get(context.state.api + 'dataLogs', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        //TODO - check response status
        if (response.data.status == 'success') {
          context.commit("setDataLogs", response.data.logs)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshCapacityLogs: function (context) {
    axios.get(context.state.api + 'capacityLogs', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        //TODO - check response status
        if (response.data.status == 'success') {
          context.commit("setCapacityLogs", response.data.logs)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshDynamicLogs: function (context) {
    axios.get(context.state.api + 'dynamicLogs', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        //TODO - check response status
        if (response.data.status == 'success') {
          context.commit("setDynamicLogs", response.data.logs)
        }else{
          console.error(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },


  // Batch logs 
  refreshBatchProductionLogs: function (context) {
    axios.get(context.state.api + 'batchProductionLogs', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        //TODO - check response status
        if (response.data.status == 'success') {
          context.commit("setBatchProductionLogs", response.data.logs)
        }else{
          console.error(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },

  // Shift Machine Usage Logs
  refreshShiftMachineUsageLogs: function (context) {
    axios.get(context.state.api + 'shiftMachineUsageLogs', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        //TODO - check response status
        if (response.data.status == 'success') {
          context.commit("setShiftMachineUsageLogs", response.data.logs)
        }else{
          console.error(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },




  refreshMaintainedOrgs: function (context) {
    if (context.state.user && Number(context.state.user.user_level) > context.state.engineerAccessLevel) {
      //console.log('maintainedOrgs')
      axios.get(context.state.api + 'maintainedOrgs', {
        headers: {
          Authorization: 'Bearer ' + context.state.jwt
        }
      })
        .then(response => {
          //context.state.groups=response.data.groups
          context.commit("setmaintainedOrgs", response.data.maintainedOrgs)
        })
        .catch(err => { console.log(err) })
    }
  },
  // refreshGroups: function (context) {
  //   axios.get(context.state.api + 'groups', {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       //context.state.groups=response.data.groups
  //       context.commit("setGroups", response.data.groups)
  //     })
  //     .catch(err => { console.log(err) })
  // },
  refreshDeviceTypes: function (context) {
    //console.log("refreshing device types")
    axios.get(context.state.api + 'deviceTypes', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.deviceTypes=response.data.device_types
        context.commit("setDeviceTypes", response.data.device_types)

      })
      .catch(err => { console.log(err) })
  },
  refreshDataTypes: function (context) {
    //console.log("refreshing device types")
    axios.get(context.state.api + 'dataTypes', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.deviceTypes=response.data.device_types
        context.commit("setDataTypes", response.data.data_types)

      })
      .catch(err => { console.log(err) })
  },
  refreshTagTypes: function (context) {
    //console.log("refreshing device types")
    axios.get(context.state.api + 'tagTypes', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.deviceTypes=response.data.device_types
        context.commit("setTagTypes", response.data.tag_types)

      })
      .catch(err => { console.log(err) })
  },
  refreshMDeviceTypes: function (context) {
    //console.log("refreshing device types")
    axios.get(context.state.api + 'mDeviceTypes', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.deviceTypes=response.data.device_types
        context.commit("setMDeviceTypes", response.data.mdevice_types)

      })
      .catch(err => { console.log(err) })
  },
  refreshOrganisation: function (context) {
    //console.log("refreshing device types")
    axios.get(context.state.api + 'organisation', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.deviceTypes=response.data.device_types
        //console.log(response.data)
        context.commit("setOrganisation", response.data.details)


      })
      .catch(err => { console.log(err) })
  },

  refreshOrgTypes: function (context) {
    //console.log("refreshing device types")
    axios.get(context.state.api + 'organisationTypes', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.deviceTypes=response.data.device_types
        context.commit("setOrgTypes", response.data.organisation_types)

      })
      .catch(err => { console.log(err) })
  },

  
  refreshCountryCodes: function (context) {
    //console.log("refreshing device types")
    axios.get(context.state.api + 'countryCodes', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.deviceTypes=response.data.device_types
        context.commit("setcountryCodes", response.data.country_codes)

      })
      .catch(err => { console.log(err) })
  },

  refreshWords: function (context) {
    //console.log("refreshing device types")
    axios.get(context.state.api + 'wordRefs', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.deviceTypes=response.data.device_types
        context.commit("setWords", response.data.word_refs)

      })
      .catch(err => { console.log(err) })
  },
  refreshNotificationGroups: function (context) {
    //console.log("refreshing device types")
    axios.get(context.state.api + 'notificationGroups', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.deviceTypes=response.data.device_types
        if (response.data.status == 'success' && response.data.groups) {
          context.commit("setNotificationGroups", response.data.groups)
        } else {
          console.log(response.data)
        }


      })
      .catch(err => { console.log(err) })
  },
  refreshMachineGroups: function (context) {
    //console.log("refreshing device types")
    axios.get(context.state.api + 'machineGroups', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.deviceTypes=response.data.device_types
        if (response.data.status == 'success' && response.data.groups) {
          context.commit("setMachineGroups", response.data.groups)
        } else {
          console.log(response.data)
        }
      })
      .catch(err => { console.log(err) })
  },
  refreshParamGroups: function (context) {
    //console.log("refreshing device types")
    axios.get(context.state.api + 'paramGroups', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.deviceTypes=response.data.device_types
        if (response.data.status == 'success' && response.data.groups) {
          context.commit("setParamGroups", response.data.groups)
        } else {
          console.log(response.data)
        }
      })
      .catch(err => { console.log(err) })
  },
  refreshUserGroups: function (context) {
    //console.log("refreshing device types")
    axios.get(context.state.api + 'userGroups', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.deviceTypes=response.data.device_types
        if (response.data.status == 'success' && response.data.groups) {
          context.commit("setUserGroups", response.data.groups)
        } else {
          console.log(response.data)
        }
      })
      .catch(err => { console.log(err) })
  },

  // refreshNodeStates: function (context) {
  //   //console.log("refreshing node states")
  //   axios.get(context.state.api + 'nodeStates', {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       //context.state.nodeStates=response.data.nodeStates
  //       context.commit("setNodeStates", response.data.nodeStates)
  //     })
  //     .catch(err => { console.log(err) })
  // },
  // refreshNotifications:function(context){
  //   axios.get(context.state.api+'notifications',{headers: {
  //     Authorization: 'Bearer '+ context.state.jwt
  //   }})
  //   .then(response=>{
  //     //context.state.notifications=response.data.notifications
  //     context.commit("setNotifications", response.data.notifications)
  //   })
  //   .catch(err=>{console.log(err)})
  // },
  refreshDevices: function (context) {
    axios.get(context.state.api + 'devices', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.devices=response.data.devices
        context.commit("setDevices", response.data.devices)
      })
      .catch(err => { console.log(err) })
  },
  refreshS7Devices: function (context) {
    axios.get(context.state.api + 's7Devices', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.devices=response.data.devices
        if (response.data.status == 'success') {
          context.commit("setS7Devices", response.data.devices)
        } else {
          console.error(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshVDevices: function (context) {
    axios.get(context.state.api + 'vdevices', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.devices=response.data.devices
        if (response.data.status == 'success') {
          context.commit("setVDevices", response.data.devices)
        } else {
          console.error(response.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshMQTTDevices: function (context) {
    axios.get(context.state.api + 'mqttDevices', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.devices=response.data.devices
        //console.log(response)
        if (response.data.status == 'success') {
          context.commit("setMQTTDevices", response.data.devices)
        } else {
          console.error(response.data.msg)
        }
      })
      .catch(err => { console.log(err) })
  },
  // refreshNodes: function (context) {
  //   axios.get(context.state.api + 'nodes', {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       //context.state.nodes=response.data.nodes
  //       context.commit("setNodes", response.data.nodes)
  //     })
  //     .catch(err => { console.log(err) })
  // },
  // refreshSNodes: function (context) {
  //   axios.get(context.state.api + 'snodes', {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       //context.state.nodes=response.data.nodes
  //       context.commit("setSNodes", response.data.snodes)
  //       context.dispatch('refreshLast30DaySNodeData')
  //     })
  //     .catch(err => { console.log(err) })
  // },
  refreshMGates: function (context) {
    axios.get(context.state.api + 'getMgates', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.nodes=response.data.nodes
        context.commit("setMgates", response.data.mGates)
      })
      .catch(err => { console.log(err) })
  },
  refreshHubs: function (context) {
    axios.get(context.state.api + 'hubs', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.nodes=response.data.nodes
        context.commit("setHubs", response.data.hubs)
        // console.table(response.data.hubs)
      })
      .catch(err => { console.log(err) })
  },
  refreshWriteParams: function (context) {
    axios.get(context.state.api + 'getWriteParams', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.nodes=response.data.nodes
        context.commit("setWriteParams", response.data.write_params)
      })
      .catch(err => { console.log(err) })
  },
  refreshManualParameters: function (context) {
    axios.get(context.state.api + 'getManualParameters', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        if (response.data.status == 'success') {
          context.commit("setManualParameters", response.data.manual_parameters)
        } else {
          console.log(response.data.msg)
        }
      })
      .catch(err => { console.log(err) })
  },
  refreshParams: function (context) {
    axios.get(context.state.api + 'params', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.nodes=response.data.nodes
      const sortedParameters =  response.data.params.sort(function (a, b) { 
        return a.name.localeCompare(b.name) })
        context.commit("setParams", sortedParameters)
      })
      .catch(err => { console.log(err) })
  },
  refreshParameters: function (context) {
    context.dispatch('refreshParams')
    axios.get(context.state.api + 'getParams', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.nodes=response.data.nodes
      const sortedParameters =  response.data.parameters.sort(function (a, b) { 
        return a.name.localeCompare(b.name) })
        context.commit("setParameters", sortedParameters)
      })
      .catch(err => { console.log(err) })
  },
  refreshOPCParams: function (context) {
    axios.get(context.state.api + 'opcParams', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.nodes=response.data.nodes
        // console.log(response.data.opc_params)
        context.commit("setOPCParams", response.data.opc_params)
      })
      .catch(err => { console.log(err) })
  },
  refreshS7Params: function (context) {
    axios.get(context.state.api + 's7Params', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.nodes=response.data.nodes
        // console.log(response.data.opc_params)
        context.commit("setS7Params", response.data.params)
      })
      .catch(err => { console.log(err) })
  },
  refreshMQTTParameters: function (context) {
    axios.get(context.state.api + 'mqttParams', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.nodes=response.data.nodes
        context.commit("setMQTTParameters", response.data.mqtt_parameters)
      })
      .catch(err => { console.log(err) })
  },
  refreshComputedParameters: function (context) {
    axios.get(context.state.api + 'computedParameters', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.nodes=response.data.nodes
        context.commit("setComputedParameter", response.data.computed_parameters)
      })
      .catch(err => { console.log(err) })
  },
  refreshWorkspaces: function (context) {
    axios.get(context.state.api + 'workspaces', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.workspaces=response.data.workspaces
        context.commit("setWorkspaces", response.data.workspaces)
      })
      .catch(err => { console.log(err) })
  },
  refreshProcesses: function (context) {
    axios.get(context.state.api + 'processes', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.workspaces=response.data.workspaces
        context.commit("setProcesses", response.data.data)
      })
      .catch(err => { console.log(err) })
  },
  refreshMachines: function (context) {
    axios.get(context.state.api + 'machines', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.workspaces=response.data.workspaces
        context.commit("setMachines", response.data.machines)
      })
      .catch(err => { console.log(err) })
  },
  refreshSchedules: function (context) {
    axios.get(context.state.api + 'schedules', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.schedules=response.data.schedules
        context.commit("setSchedules", response.data.schedules)
      })
      .catch(err => { console.log(err) })
  },
  // refreshlast7Days: function (context) {
  //   axios.get(context.state.api + 'last7days', {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       //console.log("last 7 days data received")
  //       //console.log(response.data.last7Days)
  //       //context.state.last7Days=response.data.last7Days
  //       context.commit("setLast7Days", response.data.last7Days)
  //       //console.log(context.state.last7Days)
  //     })
  //     .catch(err => { console.log(err) })
  // },
  // refreshLast30DaySNodeData: async function (context) {
  //   let last30Days = {}
  //   for (let i of context.state.snodes) {
  //     let snodeID = i.snode_id
  //     try {

  //       let response = await axios.post(context.state.api + 'getDailyDataBySnodefor30Days', { snode_id: snodeID }, {
  //         headers: {
  //           Authorization: 'Bearer ' + context.state.jwt
  //         }
  //       })
  //       //console.log(response)
  //       if (response.status == 200) {
  //         let res = response.data
  //         if (res.status === 'success') {
  //           last30Days[snodeID] = res.data
  //           //console.log(snodeID)
  //           //console.log(response.data.data)

  //         } else {
  //           console.log(res.msg)
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   context.commit('setLast30DaysSNodeData', last30Days)
  // },

  // refreshMonthlyData: function (context) {
  //   axios.get(context.state.api + 'monthlyData', {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       //console.log("monthly data received")
  //       //console.log(response.data.monthlyData)
  //       //context.state.monthlyData=response.data.monthlyData
  //       context.commit("setMonthlyData", response.data.monthlyData)

  //     })
  //     .catch(err => { console.log(err) })
  // },

  // refreshCurrentSNodeData: function (context) {
  //   let fromTimeStamp = Math.floor(moment().subtract(1.5, 'h').valueOf() / 1000);
  //   let payload = {
  //     from_timestamp: fromTimeStamp
  //   }
  //   //console.log(fromTimeStamp)
  //   axios.post(context.state.api + 'getSNodeDataBetweenTimestamp', payload, {
  //     headers: {
  //       Authorization: 'Bearer ' + context.state.jwt
  //     }
  //   })
  //     .then(response => {
  //       //console.log("snode data received")
  //       //console.log(response.data.monthlyData)
  //       //context.state.monthlyData=response.data.monthlyData
  //       //console.log(response.data.data)
  //       //console.log('something')
  //       context.commit("setCurrentSNodeData", response.data.data)
  //       //context.dispatch('calculateSnodePowerUsage')
  //       //context.dispatch('calculateSnodePowerUsage')

  //     })
  //     .catch(err => { console.log(err) })
  // },
  //   refreshCurrentParameterData:function(context){
  //     let fromTimeStamp=Math.floor(moment().subtract(1.5,'h').valueOf()/1000);
  //     let payload={
  //       from_timestamp:fromTimeStamp
  //     }
  //     //console.log(fromTimeStamp)
  //     axios.post(context.state.api+'getParamDataBetweenTimeStamp',payload,{headers: {
  //       Authorization: 'Bearer '+ context.state.jwt
  //     }})
  //     .then(response=>{
  //       //console.log("snode data received")
  //       //console.log(response.data.monthlyData)
  //       //context.state.monthlyData=response.data.monthlyData
  // //console.log(response.data.data)
  // //console.log('something')
  //       context.commit("setCurrentParameterData", response.data.data)

  //     })
  //     .catch(err=>{console.log(err)})
  //   },
  //   refreshCurrentDerivedParameterData:function(context){
  //     //console.log('refreshCurrentDerivedParameterData')
  //     let fromTimeStamp=Math.floor(moment().subtract(1.5,'h').valueOf()/1000);
  //     let payload={
  //       from_timestamp:fromTimeStamp,
  //       //to_timestamp:Math.floor(moment().valueOf()/1000)
  //     }
  //     //console.log(fromTimeStamp)
  //     axios.post(context.state.api+'getDerivedParamDataBetweenTimeStamp',payload,{headers: {
  //       Authorization: 'Bearer '+ context.state.jwt
  //     }})
  //     .then(response=>{
  //       //console.log("snode data received")
  //       //console.log(response.data.monthlyData)
  //       //context.state.monthlyData=response.data.monthlyData
  // //console.log(response.data)
  // //console.log('something')
  // if(response.data.status=='success'){
  //       context.commit("setCurrentDerivedParameterData", response.data.data)
  // }else{
  //   console.error(response.msg)
  // }

  //     })
  //     .catch(err=>{console.log(err)})
  //   },
  refreshListData: function (context) {

    axios.get(context.state.api + 'listTuples', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        if (response.data.status == "success") {
          context.commit("setListData", response.data.data)
        }
      })
      .catch(err => { console.log(err) })
  },
  refreshShifts: function (context) {
    axios.get(context.state.api + 'shifts', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        //context.state.groups=response.data.groups
        //TODO - check response status
        if (response.data.status == 'success') {
          context.commit("setShifts", response.data.data)
        }

      })
      .catch(err => { console.log(err) })
  },
  refreshShiftData: function (context) {

    axios.get(context.state.api + 'shiftData', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        if (response.data.status == "success") {
          context.commit("setShiftData", response.data.data)
        }
      })
      .catch(err => { console.log(err) })
  },
  refreshTargetData: function (context) {

    axios.get(context.state.api + 'targetData', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        if (response.data.status == "success") {
          context.commit("setTargetData", response.data.data)
        }
      })
      .catch(err => { console.log(err) })
  },
  refreshTariffData: function (context) {

    axios.get(context.state.api + 'tariffData', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        if (response.data.status == "success") {
          context.commit("setTariffData", response.data.data)
        }
      })
      .catch(err => { console.log(err) })
  },

  refreshUAMUsers: function (context) {
    axios.get(context.state.api + 'UAMUsers', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        context.commit("setUAMUsers", response.data.UAMUsers)

      })
      .catch(err => { console.log(err) })
  },

  refreshRoles: function (context) {
    axios.get(context.state.api + 'roles', {
      headers: {
        Authorization: 'Bearer ' + context.state.jwt
      }
    })
      .then(response => {
        context.commit("setRoles", response.data.roles)

      })
      .catch(err => { console.log(err) })
  },
  logOut: function (context) {
    /*axios.get(context.state.api+'logout',{headers: {
      Authorization: 'Bearer '+ context.state.jwt
    }})
    .then(response=>{
      context.state.loggedIn=response.data.loggedIn
      context.state.user=null
      context.state.groups=[]
    })*/

    //context.state.loggedIn=false
    localStorage.removeItem('enture_token')
    context.commit("setLoggedIn", false)
    //context.state.user=null
    context.commit("setUser", null)
    //context.state.groups=[]
    // context.commit("setGroups", [])
    //context.state.jwt=null
    context.commit("setJWT", null)
  }

}