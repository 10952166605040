<template>
<v-container>
    
  <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" :dark="$store.getters.getColorPalette().isDark" >
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$store.getters.getTextMap().search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="users"
      item-key="userid"
      :search="search"
      :single-expand="true"
      show-expand
      :expanded.sync="expanded"
      :loading="loading"
    loading-text="Loading... Please wait"
    >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>User access management - UAM</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <CreateUAMUserModal v-if="$store.state.user && $store.getters.getAccessByLevels(['uam'])" />
        </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <UAMUser :user="item" />
      </td>
    </template>
          
    </v-data-table>
  </v-card>

</v-container>
</template>

<script>
import CreateUAMUserModal from '@/components/modals/CreateUAMUserModal'
import UAMUser from '@/components/items/UAMUser'


import moment from 'moment'
export default {
    name:'UAMTable',
    components:{
        CreateUAMUserModal,
        UAMUser
    },
    data(){
        return {
            search:'',
            loading:false,
            expanded: [],
            headers: [
          {
            text: 'ID',
            align: 'start',
            filterable: true,
            value: 'userid',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
          },
          { text: 'Name', value: 'name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Email', value: 'email', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Phone', value: 'phone', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          //{ text: 'Type', value: 'type' },
          
          
        ],
        }
    },
    computed:{
        users(){
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            return this.$store.state.UAMUsers
        }
    },
    methods: {
        getTime(timestamp){
            return moment(timestamp.toString(),'x').format("YYYY-MM-DD HH:mm")
        }
    },
}
</script>
<style scoped>
/* .v-card__title{
  background-color:#BDBDBD00;
}
.theme--light.v-toolbar.v-sheet{
  background-color: #B2DFDB00;
}
.theme--light.v-data-table{
   background-color: #B2DFDB00;
} */
</style>