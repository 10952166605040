import English from "@/utillities/languages/English";

export default {
    ...English,
    "start": "શરૂઆત",
    "filling": "ભરવાનું",

    "accept": "સ્વીકારી લો",
"reject": "નકારો",
"move_to_next_stage": "આગામી તબક્કે જાઓ",
"save_as_draft": "ડ્રાફ્ટ તરીકે સંગ્રહ કરો",
"save_and_submit": "સાચવો અને રજૂ કરો",
"closure": "બંધ",
"duration": "મુદત",
"refill_request": "ફરી ભરવાની વિનંતી",
"is_be_cancelled": "રદ કરવામાં આવશે",
"delete_this": "આ કાઢી નાખો",
"event": "ઘટના",

    "for": "માટે",
    "of": "ના",
    "downtime": "ડાઉનટાઇમ",

    "metrics": "મેટ્રિક્સ",
    "oee_by_day": "દિન દ્વારા OEE",

    "ideal_cycle_time": "આદર્શ ચક્ર સમય",
    
    "minutes": "મિનિટ",
    "production_time": "ઉત્પાદન સમય",
    "back": "પાછળ",
    "down_times": "ડાઉન ટાઇમ્સ",

    "top_5_reasons": "ટોપ 5 કારણો",
    "top_5_categories": "ટોપ 5 કેટેગરીઝ",
    "enter_apparent_energy_manually": "સ્પષ્ટ ઉર્જા મેન્યુઅલી દાખલ કરો",

    "oee": "OEE",
    "reason": "કારણ",
    "add_downtime_reason": "ડાઉntimeનો કારણ ઉમેરો",

    "default_target": "ડિફોલ્ટ લક્ષ્ય",

    "enter_in_minutes": "મિનિટોમાં દાખલ થવું",
    "custome_fields": "રિવાજ ક્ષેત્ર",
    "enter_data_in_minutes": "મિનિટમાં ડેટા દાખલ કરો",
    "enter_data_in_hours": "કલાકોમાં ડેટા દાખલ કરો",
    "create_tod_billing_widget": "ટોડ બિલિંગ વિજેટ બનાવો",
    "edit_tod_billing_widget": "ટોડ બિલિંગ વિજેટ સંપાદિત કરો",
    "choose_from_machine_groups": "મશીન જૂથોમાંથી પસંદ કરો",
    "tod_billing": "ટોડ બિલિંગ",
    "choose": "પસંદ કરવું",
    "create_shift_based_machine_usage_log": "મશીન વપરાશ લ log ગ બનાવો",
    "shift_based_machine_usage_log": "મશીન વપરાશ લ log ગ",
    "rejected": "અસ્વીકૃત",
    "machine_operator": "યંત્ર પ્રચાલક",
    "production_end_timestamp": "અંતિમ સમય",
    "production_start_timestamp": "ઉત્પાદન પ્રારંભ સમય",
    "running_time": "ચાલુ સમય",
    "idle_time": "નિષ્ક્રિય સમય",
    "down_time": "ડાઉન ટાઇમ",
    "date_row_index": "તારીખ",
    "date_position": "તારીખની સ્થિતિ",
    "save": "બચત કરવી",
    "discard": "કા discી નાખવા",
    "adjust": "સમાયોજન કરવું",
    "safety_incident_sheet": "સલામતી -ઘટના",
    "safety_inspection_sheet": "સલામતી નિરીક્ષણ શીટ",
    "ghg_emission_logs": "જીએચજી ઉત્સર્જન લોગ",
    "add_ghg_emission_data": "જીએચજી ઉત્સર્જન લ s ગ્સ ઉમેરો",
    "ghg_scope_conversion": "જીએચજી અવકાશ રૂપાંતર",
    "create_ghg_scope_conversion": "જીએચજી અવકાશ રૂપાંતર બનાવો",
    "ghg_data_logs": "જીએચજી ઉત્સર્જન ડેટા લ s ગ્સ",
    "choose_inspection_type_optional": "નિરીક્ષણ પ્રકાર પસંદ કરો (વૈકલ્પિક)",
    "supplier_code": "પુરવઠાકાર",
    "contact": "સંપર્ક",
    "point_of_contact": "સંપર્કનો મુદ્દો",
    "quantity_supplied": "જથ્થો પૂરો પાડવો",
    "create_product": "ઉત્પાદન બનાવો",
    "products": "ઉત્પાદન",
    "data": "માહિતી",
    "timezone": "ટાઈમઝોન",
    "tariffs": "પ્રશુલ્ક",
    "days_delta": "દિવસના ડેલ્ટા",
    "machine_groups": "મશીન જૂથો",
    "param_groups": "સાંકડા જૂથો",
    "user_groups": "વપરાશકર્તા જૂથો",
    "topic_prefix": "વિષયનો",
    "message_direction": "સંદેશ દિશા",
    "safety_inspection": "સલામતી તપાસ",
    "write_inspection_log": "નિરીક્ષણ લ log ગ લખો",
    "inspection_id": "તપાસણી I id",
    "inspector_id": "નિરીક્ષક આઈડી",
    "inspection_date": "નિરીક્ષણ તારીખ",
    "inspection_type": "નિરીક્ષણ પ્રકાર",
    "findings": "તારણ",
    "actions_required": "આવશ્યક ક્રિયાઓ",
    "followup_date": "અનુસરો તારીખ",
    "status": "દરજ્જો",
    "safety_incident": "સલામતી ઘટના",
    "write_incident": "ઘટના લખો",
    "incident_description": "ઘટનાનું વર્ણન",
    "severity": "તીવ્ર",
    "accident": "અકસ્માત",
    "accident_with_loss": "નુકસાન સાથે અકસ્માત",
    "closed": "બંધ",
    "corrective_action": "સુધારક કાર્યવાહી",
    "employee_id": "કર્મચારી",
    "apply": "અરજી કરવી",
    "choose_location_optional": "સ્થાન પસંદ કરો (વૈકલ્પિક)",
    "locations": "સ્થાનો",
    "locations_event_types": "સ્થાનોના પ્રકારનાં પ્રકારો",
    "create_tariff": "પ્રશુલ્ક બનાવો",
    "default_tariff_val": "ડિફોલ્ટ -મૂલ્ય",
    "add_tariff_data": "ટેરિફ ડેટા ઉમેરો",
    "edit_tariff": "ટેરિફ સંપાદિત કરો",
    "delete_tariff": "ટેરિફ કા Delette ી નાખો",
    "tariff_value": "પ્રશુલ્ક મૂલ્ય",
    "machine_status_timeline": "મશીન સ્થિતિ સમયરેખા",
    "total_duration": "કુલ સમયગાળો",
    "generate": "પેદા કરવું",
    "remove_machine": "મશીન દૂર કરો",
    "edit_machine_status_timeline_widget": "મશીન સ્થિતિ સમયરેખા વિજેટમાં ફેરફાર કરો",
    "add_machine_Status__timeline_widget": "મશીન સ્થિતિ સમયરેખા વિજેટ ઉમેરો",
    "select_timeframe": "સમયમર્યાદા પસંદ કરો",
    "timeframe_type_shift": "પાળી",
    "timeframe_type_custom": "રિવાજ",
    "timeframe_type_yesterday": "ગઈકાલે",
    "edit_report_details": "અહેવાલ વિગતો સંપાદિત કરો",
    "download_current_template": "વર્તમાન નમૂના ડાઉનલોડ કરો",
    "date_column_index": "તારીખ ક column લમ (અનુક્રમણિકા)",
    "select_area": "ક્ષેત્ર પસંદ કરો",
    "select_shift": "પાળી પસંદ કરો",
    "dashboard_heading": "ધૂબડી",
    "production_params": "ઉત્પાદન",
    "no_modules_configured": "કોઈ મોડ્યુલો ગોઠવેલ નથી",
    "dashboard_panel": "ધૂબડી",
    "choose_type": "પ્રકાર પસંદ કરો",
    "name_is_required": "નામ જરૂરી છે",
    "description_is_required": "વર્ણન જરૂરી છે",
    "required": "આવશ્યક",
    "you_have_to_select_something": "તમારે કંઈક પસંદ કરવું પડશે",
    "name_must_be_less_than_30_characters": "નામ 30 અક્ષરોથી ઓછું હોવું જોઈએ",
    "name_must_be_less_than_25_characters": "નામ 25 અક્ષરોથી ઓછું હોવું જોઈએ",
    "name_must_be_less_than_50_characters": "નામ 50 અક્ષરોથી ઓછું હોવું જોઈએ",
    "description_must_be_less_than_35_characters": "વર્ણન 35 અક્ષરોથી ઓછું હોવું જોઈએ",
    "description_must_be_less_than_55_characters": "વર્ણન 55 અક્ષરોથી ઓછું હોવું જોઈએ",
    "number_must_be_less_than_6_digits": "સંખ્યા 6 અંકોથી ઓછી હોવી જોઈએ",
    "number_must_be_less_than_8_digits": "સંખ્યા 8 અંકોથી ઓછી હોવી જોઈએ",
    "number_must_be_less_than_7_digits": "સંખ્યા 7 અંકોથી ઓછી હોવી જોઈએ",
    "panel": "પેનલ",
    "gauge": "માપ",
    "param": "પરમ",
    "machine": "મશીન",
    "add_panel_to_dashboard": "ડેશબોર્ડ પર પેનલ ઉમેરો",
    "edit_dashboard_panel": "ડેશબોર્ડ પેનલ સંપાદિત કરો",
    "param_type": "પરમ પ્રકાર",
    "workspace": "ખલાસ",
    "device": "સાધન",
    "name": "નામ",
    "language": "ભાષા",
    "time_duration": "સમયગાળો",
    "submit": "રજૂ કરવું",
    "cancel": "રદ કરવું",
    "close": "બંધ",
    "usage": "ઉપયોગ",
    "peak_value": "ટોચની કિંમત",
    "current_data": "વર્તમાન આંકડા",
    "parameter": "પરિમાણ",
    "computed_param": "ગણતરી કરેલ પરમ",
    "derived_param": "પરાકાષ્ઠા",
    "t4_days": "4 દિવસ",
    "last_7_days": "છેલ્લા 7 દિવસ",
    "last_30_days": "છેલ્લા 30 દિવસ",
    "last_60_days": "છેલ્લા 60 દિવસ",
    "last_90_days": "છેલ્લા 90 દિવસ",
    "last_180_days": "છેલ્લા 180 દિવસ",
    "last_365_days": "લેટ 365 દિવસ",
    "last_1_hour": "છેલ્લા 1 કલાક",
    "last_3_hours": "છેલ્લા 3 કલાક",
    "last_6_hours": "છેલ્લા 6 કલાક",
    "reorder_views": "ફરીથી ગોઠવવું",
    "create_view": "બનાવવી",
    "clear_view_name_filter": "સ્પષ્ટ ફિલ્ટર્સ",
    "view_type": "દૃશ્ય પ્રકાર",
    "description": "વર્ણન",
    "table_view": "ઉષ્ણકટિબંધ",
    "widget_view": "વિજળ",
    "dynamic_timeframed_widget_view": "ગતિશીલ સમયમર્યાદા વિજેટ દૃશ્ય",
    "diagram_view": "વિધવા",
    "energy_usage_view": "Energyર્જા -વપરાશ દૃષ્ટિકોણ",
    "machine_scroll_view": "મશીન સ્ક્રોલ દૃશ્ય",
    "param_scroll_view": "પરમ સ્ક્રોલ દૃશ્ય",
    "consolidated_view": "એકત્રિત દૃષ્ટિકોણ",
    "air_quality_view": "હવા ગુણવત્તા દૃશ્ય",
    "view_auto_change": "Auto ટો ફેરફાર જુઓ",
    "no_views_configured": "કોઈ દૃષ્ટિકોણ ગોઠવેલ નથી",
    "add_to_dashboard": "ડેશબોર્ડમાં ઉમેરો",
    "edit_view_name": "નામ સંપાદિત કરો નામ",
    "edit_view": "સંપાદન દૃશ્ય",
    "refresh": "તાજું કરવું",
    "create_machine": "મશીન બનાવો",
    "add_machine": "મશીન ઉમેરો",
    "sequence": "અનુક્રમ",
    "machine_type": "મશીન પ્રકાર",
    "physical_machine": "ભૌતિક યંત્ર",
    "virtual_machine": "વર્ચ્યુઅલ યંત્ર",
    "positive_workspace": "સકારાત્મક કાર્યસ્થળ",
    "positive_machine": "સકારાત્મક યંત્ર",
    "negative_workspace": "નકારાત્મક કાર્યસ્થળ",
    "negative_machine": "નકારાત્મક યંત્ર",
    "label": "લેબલ",
    "positive_machine_Ids": "સકારાત્મક મશીન આઈડી",
    "negative_machine_Ids": "નકારાત્મક મશીન આઈડી",
    "trend_analysis_tools": "વલણ વિશ્લેષણ સાધનો",
    "energy_consumption_by_machines": "મશીનો દ્વારા energy ર્જા વપરાશ",
    "Create_report": "રિપોર્ટ બનાવો",
    "view_report": "જુલમ અહેવાલ",
    "tarrif_on": "પરાક્રમ",
    "active_energy": "સક્રિય energyર્જા",
    "apparent_energy": "સ્પષ્ટ energyર્જા",
    "automate_report": "સ્વચાલિત અહેવાલ",
    "add_root_machine": "રુટ મશીન ઉમેરો",
    "add_common_machine": "સામાન્ય મશીન ઉમેરો",
    "notification_groups": "સૂચના જૂથો",
    "add_machines_to_list": "સૂચિમાં મશીનો ઉમેરો",
    "lists": "યાદી",
    "machine_list": "યંત્ર -યાદી",
    "data_historian": "આંકડાકીય",
    "timeframed_trends": "સમયમર્યાદા વલણો",
    "dual_axis_trend_comparison": "બેવડી ધરી વલણ સરખામણી",
    "granular24_hrs_trend": "દાણાદાર 24 કલાક વલણ",
    "machinewise_trend": "મશીન મુજબની વલણ",
    "basic_statistical_trends": "મૂળભૂત આંકડાકીય વલણો",
    "log_analysis": "લોગ વિશ્લેષણ",
    "aqi_comparison_panel": "એક્યુઆઈ સરખામણી પેનલ",
    "time_of_day_trend": "દિવસનો વલણ",
    "param_historical_comparisons": "પરમ Hist તિહાસિક તુલના",
    "energy_usage_trends": "Energyર્જા -ઉપયોગ વલણો",
    "machine_name": "મશીન નામ",
    "param_name": "પરમ નામ",
    "trend_view": "દૃશ્ય જોવા",
    "raw": "કાચું",
    "trend": "વલણ",
    "normalised": "સામાન્યકૃત",
    "from_date": "તારીખથી",
    "to_date": "આજની તારીખમાં",
    "from": "થી",
    "to": "તરફ",
    "hour": "કલાક",
    "minute": "ક્ષય",
    "generate_report": "અહેવાલ બનાવો",
    "time_range": "સમયપત્રક",
    "no_data_available": "કોઈ ડેટા ઉપલબ્ધ નથી",
    "daily": "રોજનું",
    "monthly": "દરિયો",
    "weekly": "સાપ્તાહિક",
    "week": "સપ્તાહ",
    "yearly": "વાર્ષિક",
    "device_name": "ઉપકરણનું નામ",
    "machine_wise_comparison": "મશીન મુજબની તુલના",
    "stats_detailed_report": "આંકડા વિગતવાર અહેવાલ",
    "log_type": "લોગ પ્રકાર",
    "choose_Log": "લોગ પસંદ કરો",
    "area": "વિસ્તાર",
    "aq_device": "એક્યુ ઉપકરણ",
    "nebeskie_labs_office": "નેબેસ્કી લેબ્સ office ફિસ",
    "date_range": "તારીખ_રેંજ",
    "this_year": "આ વર્ષ",
    "last 6 months": "છેલ્લા 6 મહિના",
    "last quater": "અંતિમ ક્વાર્ટર",
    "mtd": "Mોર",
    "ytd": "Y",
    "yesterday": "ગઈકાલે",
    "custom": "રિવાજ",
    "run_report": "અહેવાલ",
    "create_report": "રિપોર્ટ બનાવો",
    "usage_report": "વપરાશ અહેવાલ",
    "consolidated_report": "એકત્રિત અહેવાલ",
    "dynamic_excel_report": "ગતિશીલ એક્સેલ અહેવાલ",
    "energy_usage_report": "Energyર્જા વપરાશ અહેવાલ",
    "create_action": "ક્રિયા બનાવો",
    "action_type": "ક્રિયા -પ્રકાર",
    "notification_group": "સૂચના જૂથ",
    "address": "સંબોધન",
    "number_of_registers": "રજિસ્ટરની સંખ્યા",
    "value": "મૂલ્ય",
    "send_notification": "સૂચના મોકલો",
    "write_parameter": "પરિમાણ લખો",
    "relays": "રિલેઝ",
    "relay_1": "રિલે 1",
    "relay_2": "રિલે 2",
    "relay_3": "રિલે 3",
    "relay_4": "રિલે 4",
    "email_must_be_valid": "ઇમેઇલ માન્ય હોવું આવશ્યક છે",
    "it_should_be_a_number": "તે સંખ્યા હોવી જોઈએ",
    "it_should_be_a_valid_number": "તે માન્ય સંખ્યા હોવી જોઈએ",
    "operator": "પ્રચાલક",
    "operand": "પ્રચાર",
    "targets": "લક્ષ્યાંક",
    "target_value": "લક્ષ્યાંક મૂલ્ય",
    "wait_time_seconds": "રાહ જુઓ સમય (સેકંડ)",
    "greater_than": "કરતાં વધારે",
    "modulus_greater_than": "કરતા વધારે મોડ્યુલસ",
    "lesser_than": "કરતાં ઓછી",
    "modulus_lesser_than": "કરતા ઓછા મોડ્યુલસ",
    "equal_to": "સાકાર",
    "not_equal_to": "સમાન નથી",
    "modulus_equal_to": "મોડ્યુલસ સમાન",
    "greater_than_equal_to": "સમાન કરતા વધારે",
    "modulus_greater_than_equal_to": "સમાન કરતા વધારે મોડ્યુલસ",
    "lesser_than_equal_to": "બરાબર",
    "modulus_lesser_than_equal_to": "સમાન કરતા ઓછા મોડ્યુલસ",
    "bit_high": "Highંચું",
    "bit_low": "નીચું",
    "it_should_be_a_positive_whole_number": "તે સકારાત્મક સંપૂર્ણ સંખ્યા હોવી જોઈએ",
    "it_should_be_a_positive": "તે સકારાત્મક હોવું જોઈએ",
    "create_process": "પ્રક્રિયા કરવી",
    "edit_process": "સંપાદન પ્રક્રિયા",
    "status_rule": "દરજ્જો નિયમ",
    "create_process_status_rule": "પ્રક્રિયા સ્થિતિ નિયમ બનાવો",
    "tag_type": "ટ tag ગ પ્રકાર",
    "process_status": "પ્રક્રિયા દરજ્જો",
    "machines": "મશીનો",
    "running": "વહેતું",
    "idling": "અતિથિ",
    "offline": "Offનટ",
    "no_machines_configured": "કોઈ મશીનો ગોઠવેલ નથી",
    "delete_process": "પ્રક્રિયા કા Delete ી નાખો",
    "add_machine_to_process": "પ્રક્રિયામાં મશીન ઉમેરો",
    "delete_premise_area": "પૂર્વવર્તી વિસ્તાર કા .ી નાખો",
    "add": "ઉમેરો",
    "add_to_list": "સૂચિમાં ઉમેરો",
    "search": "શોધ",
    "switch_to": "પર ફેરવો",
    "switch_to_base_org": "આધાર org પર સ્વિચ કરો",
    "switch": "બદલવું",
    "create_new_organisation": "નવી સંસ્થા બનાવો",
    "organisation_name": "સંગઠન નામ",
    "organisation_type": "સંગઠન પ્રકાર",
    "country_code": "દેશી આચાર",
    "country": "દેશ",
    "state": "રાજ્ય",
    "clear": "સ્પષ્ટ",
    "change_organisation": "પરિવર્તન સંગઠન",
    "organisation_id": "વ્યવસ્થા",
    "update_your_oem_logo": "તમારા OEM લોગોને અપડેટ કરો",
    "select_a_logo_to_upload": "અપલોડ કરવા માટે લોગો પસંદ કરો",
    "submit_logo": "લોગો સબમિટ કરો",
    "create_workspace": "વર્કસ્પેસ બનાવો",
    "hub_id": "હબ આઈડી",
    "name_must_be_less_than_45_characters": "નામ 45 અક્ષરોથી ઓછું હોવું જોઈએ",
    "id_is_required": "આઈડી આવશ્યક છે",
    "no_space_allowed": "કોઈ જગ્યાની મંજૂરી નથી",
    "id_must_be_less_than_6_characters": "આઈડી 6 અક્ષરોથી ઓછી હોવી જોઈએ",
    "edit_workspace": "વર્કસ્પેસ સંપાદિત કરો",
    "delete_workspace": "વર્કસ્પેસ કા Delete ી નાખો",
    "add_modbus_device": "મોડબસ ડિવાઇસ ઉમેરો",
    "add_device": "ઉપકરણ ઉમેરો",
    "oops": "Ooops !!",
    "ok": "ઠીક",
    "slave_id": "ગુલામ I id",
    "device_type": "ઉપકરણનો પ્રકાર",
    "connection_type": "અનુરોધિત પ્રકાર",
    "mGate": "મોગેટ",
    "make": "બનાવટ",
    "model": "નમૂનો",
    "name_must_be_less_than_33_characters": "નામ 33 અક્ષરોથી ઓછું હોવું જોઈએ",
    "name_must_be_less_than_11_characters": "નામ 11 અક્ષરોથી ઓછું હોવું જોઈએ",
    "it_should_be_a_valid_slave_id": "તે માન્ય ગુલામ ID હોવું જોઈએ",
    "name_must_be_less_than_4_digits": "નામ 4 અંકોથી ઓછું હોવું જોઈએ",
    "modbus_tcp": "બડબડ ટી.સી.પી.",
    "modbus_rtu": "બડબ્લ્યુએસ",
    "add_virtual_device": "વર્ચ્યુઅલ ડિવાઇસ ઉમેરો",
    "add_mqtt_device": "એમક્યુટીટી ડિવાઇસ ઉમેરો",
    "topic": "વિષય",
    "identifier": "ઓળખકર્તા",
    "identifier_value": "ઓળખ -મૂલ્ય",
    "name_must_be_less_than_20_characters": "નામ 20 અક્ષરોથી ઓછું હોવું જોઈએ",
    "no_special_characters_or_space_allowed": "કોઈ ખાસ પાત્રો અથવા જગ્યાની મંજૂરી નથી",
    "must_be_less_than_15_characters": "15 અક્ષરોથી ઓછા હોવા જોઈએ",
    "create_role": "ભૂમિકા બનાવો",
    "role": "ભૂમિકા",
    "role_is_required": "ભૂમિકા જરૂરી છે",
    "role_must_be_less_than_40_characters": "ભૂમિકા 40 અક્ષરોથી ઓછી હોવી જોઈએ",
    "role_should_not_contain_white_characters": "ભૂમિકામાં સફેદ અક્ષરો હોવા જોઈએ નહીં",
    "comparison": "તુલના",
    "historical_time_of_day_comparison_parameter": "દિવસની તુલનાનો ઇતિહાસ સમય - પરિમાણ",
    "device_parameter": "પરિમાણ",
    "date_1": "તારીખ 1",
    "date_2": "તારીખ 2",
    "tod_comparison_report": "ટોડ તુલના અહેવાલ",
    "time_frame": "સમયફળ",
    "download_table": "ડાઉનલોડ કોષ્ટક",
    "table": "કોષ્ટક",
    "showMinMax_values": "મીન અને મહત્તમ મૂલ્યો બતાવો",
    "usage_or_average": "વપરાશ અથવા સરેરાશ",
    "min_value": "લઘુ કિંમત",
    "last_12_weeks": "છેલ્લા 12 અઠવાડિયા",
    "last_6_months": "છેલ્લા 6 મહિના",
    "last_12_months": "છેલ્લા 12 મહિના",
    "year_5": "5 વર્ષ",
    "machinewise_comparison": "મશીન મુજબની તુલના",
    "timeFrame": "સમય -ખંડ",
    "it_should_be_a_date": "તે તારીખ હોવી જોઈએ",
    "time_of_day_parameter": "દિવસનો સમય- પરિમાણ",
    "tod_report": "ટોડ - રિપોર્ટ",
    "in_this_timeframe": "આ સમયમર્યાદામાં",
    "average": "સરેરાશ",
    "maximum": "મહત્તમ",
    "minimum": "લઘુત્તમ",
    "energy_usage_trend": "Energyર્જા ઉપયોગની વલણ",
    "standard_deviation": "માનક વિચલન",
    "statistical_mode": "આંકડાકીય ધોરણ",
    "production_logs": "ઉત્પાદન",
    "capacity_logs": "ક્ષમતાવાળા લ s ગ્સ",
    "dynamic_logs": "ગતિશીલ લોગ",
    "pollutants": "પ્રદૂષકો",
    "group_by": "જૂથ",
    "generate_grouped_trends": "જૂથબદ્ધ વલણો ઉત્પન્ન કરો",
    "tCo2": "ટી.સી.ઓ.",
    "scope_1": "અવકાશ 1",
    "scope_2": "અવકાશ 2",
    "scope_3": "અવકાશ 3",
    "source": "મૂળ કારણ",
    "type": "પ્રકાર",
    "user_access_log": "વપરાશકર્તા પ્રવેશ લ log ગ",
    "user_logs": "વપરાશકર્તા લોગ",
    "fetch_logs": "લોગ લાવો",
    "update_profile": "અપડેટ પ્રોફાઇલ",
    "lang": "ક lંગું",
    "theme": "વિષય",
    "phone": "કણ",
    "update": "અપડેટ કરવું",
    "update_password": "પાસવર્ડ અપડેટ કરો",
    "change_password": "પાસવર્ડ બદલો",
    "old_password": "જૂનો પાસવર્ડ",
    "new_password": "નવો પાસવર્ડ",
    "confirm_password": "પાસવર્ડ પુષ્ટિ કરો",
    "password_must_be_more_than_8_characters": "પાસવર્ડ 8 અક્ષરોથી વધુ હોવો આવશ્યક છે",
    "passwords_do_not_match": "પાસવર્ડ્સ મેળ ખાતા નથી",
    "add_hub": "હબ ઉમેરો",
    "id_must_be_less_than_4_characters": "આઈડી 4 અક્ષરોથી ઓછી હોવી જોઈએ",
    "add_maintainer": "જાળવણી કરનાર ઉમેરો",
    "add_parent_org": "માતાપિતા org ઉમેરો",
    "add_parent_to_organisation": "સંસ્થામાં માતાપિતા ઉમેરો",
    "parent_org_id": "પિતૃ org ID",
    "add_maintainer_to_organization": "સંસ્થામાં જાળવણી કરનારને ઉમેરો",
    "maintainer_id": "જાળવણી કરનાર ID",
    "update_organisation_details": "સંગઠન વિગતો અપડેટ કરો",
    "update_your_organization_details": "તમારી સંસ્થાની વિગતો અપડેટ કરો",
    "default_email": "પરણમાં ઇમેઇલ",
    "picker_in_menu": "મેનુમાં પિકર",
    "param_diagnostics": "પરમ ડાયગ્નોસ્ટિક્સ",
    "show_logo": "લોગો બતાવો",
    "show_oem_logo": "OEM લોગો બતાવો",
    "organisation_alias": "સંગઠન",
    "update_organisation_alias": "સંગઠન ઉર્ફે અપડેટ કરો",
    "update_org_alias": "Org ઉર્ફે અપડેટ કરો",
    "update_your_organization_logo": "તમારા સંગઠન લોગોને અપડેટ કરો",
    "select_a_logo": "લોગો પસંદ કરો",
    "update_logo": "અપડેટ લોગો",
    "create_uam_user": "યુએએમ ​​વપરાશકર્તા બનાવો",
    "overview": "વિહંગાવલોક",
    "batches": "બચ્ચાં",
    "track_batch": "ટ્રેક બેચ",
    "batch_movement": "બેચની આંદોલન",
    "production_planner": "ઉત્પાદન આયોજક",
    "show_unshipped_only": "ફક્ત યુ.એન.",
    "view_logs": "લોગ જુઓ",
    "params": "પરમ",
    "add_filter": "ફિલ્ટર ઉમેરો",
    "filters": "ગાળકો",
    "batch_id": "બેચ_આઈડી",
    "show_details": "વિગતો બતાવો",
    "tracing_logs": "લોગ ટ્રેસિંગ",
    "quantity": "જથ્થો",
    "location_event_type": "સ્થાન ઘટના પ્રકાર",
    "fetch_plans": "યોજનાઓ લાવો",
    "sku": "છીનવી લેવું",
    "skus": "માલ રાખનાર એકમો",
    "code": "સંહિતા",
    "add_production_plans": "ઉત્પાદન યોજનાઓ ઉમેરો",
    "date": "તારીખ",
    "from_time": "સમયસર",
    "to_time": "સમયસર",
    "activities": "પ્રવૃત્તિ",
    "task_name": "વ્યવસાય નામ",
    "task_details": "વ્યવસાય વિગતો",
    "start_date": "પ્રારંભ તારીખ",
    "end_date": "છેવર તારીખ",
    "assignee": "મુખત્ર",
    "manager": "વ્યવસ્થાપક",
    "activity": "પ્રવૃત્તિ",
    "activity_type": "પ્રવૃત્તિ પ્રકાર",
    "escalation_matrix": "વધારાનું મેટ્રિક્સ",
    "maintenance_activity": "જાળવણી પ્રવૃત્તિ",
    "em_level": "ક levelપૂલ",
    "delay": "વિલંબ",
    "user_group": "જૂથ",
    "mins_5": "5 મિનિટ",
    "mins_10": "10 મિનિટ",
    "mins_15": "15 મિનિટ",
    "mins_30": "30 મિનિટ",
    "hour_1": "1 કલાક",
    "hour_6": "6 કલાક",
    "day_1": "1 દિવસ",
    "day": "દિવસ",
    "day_3": "3 દિવસ",
    "insights": "આંતરદૃષ્ટિ",
    "create_emailer_group": "ઇમેઇલર જૂથ બનાવો",
    "notification_type": "સૂચના પ્રકાર",
    "email": "ઇમેઇલ",
    "sms": "એસ.એમ.એસ.",
    "create_machine_group": "મશીન જૂથ બનાવો",
    "create_param_group": "પરમ જૂથ બનાવો",
    "create_user_group": "વપરાશકર્તા જૂથ બનાવો",
    "create_module": "મોડ્યુલ બનાવો",
    "busbar_temp_module": "બસબાર ટેમ્પ મોડ્યુલ",
    "power_quality_module": "વીજળી ગુણવત્તા મોડ્યુલ",
    "esg_module": "ઇએસજી મોડ્યુલ",
    "edge_hub": "ધાર કે કેન્દ્ર",
    "tolerance": "સહનશીલતા",
    "ambient_temp_input_type": "એમ્બિયન્ટ ટેમ્પ ઇનપુટ પ્રકાર",
    "ambient_temp": "આસપાસના કામચલાઉ",
    "workspace_ambient_temp": "વર્કસપેસ એમ્બિયન્ટ ટેમ્પ",
    "device_ambient_temp": "ઉપકરણ",
    "r_temperature": "તાપમાન",
    "y_temperature": "વાય તાપમાન",
    "b_temperature": "તાપમાન",
    "n_temperature": "તાપમાન",
    "rated_current": "દરજ્જો",
    "no_load_current": "કોઈ લોડ વર્તમાન",
    "r_current": "વર્તમાન",
    "y_current": "વાય વર્તમાન",
    "b_current": "બી વર્તમાન",
    "n_current": "N પ્રવાહ",
    "delete_activity": "પ્રવૃત્તિ કા Delete ી નાખો",
    "substation_voltage_kv": "સબસ્ટેશન વોલ્ટેજ (કેવી)",
    "transformer_rating_mva": "ટ્રાન્સફોર્મર રેટિંગ (એમવીએ)",
    "percentage_impedance_of_the_transformer": "ટ્રાન્સફોર્મરની % અવરોધ",
    "voltage_rv_y": "વોલ્ટેજ આરવી (વાય)",
    "voltage_yb_v": "વોલ્ટેજ વાયબી (વી)",
    "voltage_br_v": "વોલ્ટેજ બીઆર (વી)",
    "current_r_a": "વર્તમાન આર (એ)",
    "current_y_a": "વર્તમાન વાય (એ)",
    "current_b_a": "વર્તમાન બી (એ)",
    "thd_i": "Thd i",
    "thd_v": "Thd v",
    "thd_i1": "Thd i1",
    "active_power_kw": "સક્રિય શક્તિ (કેડબલ્યુ)",
    "apparent_power_kva": "દેખીતી શક્તિ (કેવીએ)",
    "reactive_power_kvar": "પ્રતિક્રિયાશીલ શક્તિ (કેવર)",
    "active_energy_kwh": "સક્રિય energy ર્જા (કેડબ્લ્યુએચ)",
    "apparent_energy_kvah": "સ્પષ્ટ energy ર્જા (કેવીએએચ)",
    "pf": "પી.એફ.",
    "text": "લખાણ",
    "energy_source": "Energyર્જા સ્ત્રોત",
    "scope": "ક્ષેત્ર",
    "system_logs": "સિસ્ટમ લ s ગ્સ",
    "system_logbook": "સિસ્ટમ -બુક",
    "esg_data_log": "ઇએસજી ડેટા લ log ગ",
    "add_esg_data": "ઇએસજી ડેટા ઉમેરો",
    "add_esg_data_log": "ESG ડેટા લ log ગ ઉમેરો",
    "source_type": "સ્ત્રોત પ્રકાર",
    "unit_of_measurement": "માપ -એકમ",
    "from_date_optional": "તારીખથી (વૈકલ્પિક)",
    "emission_factor": "ઉત્સર્જન પરિબળ (કેજીકો 2)",
    "create_dynamic_logger": "ગતિશીલ લોગર બનાવો",
    "dynamic_log": "ગતિશીલ લોગ",
    "primary_field_label": "પ્રાથમિક ક્ષેત્ર લેબલ",
    "primary_field_type": "પ્રાથમિક પ્રકાર",
    "primary": "પ્રાથમિક",
    "additional_fields": "વધારાના ક્ષેત્રો",
    "field_label": "ક્ષેત્રનું લેબલ",
    "field_type": "ક્ષેત્ર પ્રકાર",
    "choose_list": "સૂચિ પસંદ કરો",
    "list": "યાદી",
    "create_capacity_logger": "ક્ષમતા લોગર બનાવો",
    "capacity_log": "શક્તિ",
    "create": "બનાવવી",
    "create_production_logger": "પ્રોડક્શન લોગર બનાવો",
    "production_log": "ઉત્પાદન",
    "production_logger": "ઉત્પાદન -લંગર",
    "input_type": "ઇનપુટ પ્રકાર",
    "input": "ઇનપાર",
    "input_name": "ઇનપુટ નામ",
    "output_type": "ઉત્પાદન પ્રકાર",
    "output": "ઉત્પાદન",
    "output_name": "ઉત્પાદનનું નામ",
    "output_params": "પરિમાણ",
    "compute_production": "ગણતરી ઉત્પાદન",
    "compute_energy": "ગણતરી energyર્જા",
    "energy_param_type": "Energy ર્જા પરમ પ્રકાર",
    "energy_device": "Energyર્જા -ઉપકરણ",
    "energy_param": "શક્તિ પરમ",
    "production_operator": "ઉત્પાદન operatorપરેટર",
    "production_correction_factor": "ઉત્પાદન -સુધારણા પરિબળ",
    "output_operator": "ઉત્પાદન પ્રચાર",
    "output_correction_factor": "ઉત્પાદન -સુધારા પરિબળ",
    "input_operator": "ઇનપુટ operatorપરેટર",
    "input_correction_factor": "ઉત્પાદન -સુધારણા પરિબળ",
    "energy_unit": "Energyર્જા એકમ",
    "production_unit": "ઉત્પાદન એકમ",
    "production": "ઉત્પાદન",
    "create_data_logger": "ડેટા લોગર બનાવો",
    "data_log": "આંકડા",
    "add_param": "પરમ ઉમેરો",
    "param_id": "પરમદ",
    "create_batch_production_logger": "બેચ પ્રોડક્શન લોગર બનાવો",
    "batch_tracing_log": "બેચ ટ્રેસિંગ લ log ગ",
    "log_name": "લોગ નામ",
    "location": "સ્થાન",
    "info": "માહિતી",
    "remarks": "ટીકા",
    "code_will_be_available_to_log_by_default": "કોડ ડિફ default લ્ટ રૂપે લ log ગ કરવા માટે ઉપલબ્ધ રહેશે",
    "fields": "ખેતરો",
    "additional_info": "વધારાની માહિતી",
    "action": "ક્રિયા",
    "report_type": "રિપોર્ટ પ્રકાર",
    "create_parameter_report": "પરિમાણ અહેવાલ બનાવો",
    "data_processing": "આંકડા",
    "addition": "વધારા",
    "singular": "એકલ",
    "report": "અહેવાલ",
    "energy_parameters_kwh": "Energy ર્જા પરિમાણો (કેડબ્લ્યુએચ)",
    "apparent_energy_KVAh": "સ્પષ્ટ energy ર્જા કેવીએહ",
    "maximum_demand_MD": "મહત્તમ માંગ (એમડી)",
    "excel_frequency": "ઉત્કૃષ્ટ આવર્તન",
    "multiplier": "ગુણાકાર",
    "single": "એક",
    "incomers": "નિવાસી",
    "sections": "વિભાગ",
    "energy_distribution_report": "E ર્જા વિતરણ અહેવાલ",
    "root_machine": "મઠ -યંત્ર",
    "tariff_on": "પરાક્રમ",
    "add_root_common_machine": "રુટ અને સામાન્ય મશીન ઉમેરો",
    "common_machine": "સામાન્ય યંત્ર",
    "energy_bill_distribution_report": "Energyર્જા બિલ વિતરણ અહેવાલ",
    "create_consolidated": "એકીકૃત બનાવો",
    "choose_report": "રિપોર્ટ પસંદ કરો",
    "id": "ઉપદેશ",
    "timeframed_sec": "ટાઇમ ફ્રેમ્ડ સેક",
    "create_dynamic_excel_report": "ગતિશીલ એક્સેલ રિપોર્ટ બનાવો",
    "update_dynamic_excel_report": "ગતિશીલ એક્સેલ રિપોર્ટ અપડેટ કરો",
    "add_sheet": "Addાંકણ",
    "sheet_name": "ચાદરનું નામ",
    "timestamp": "ટાઇમસ્ટેમ્પ",
    "sheet": "ચાદર",
    "sheets": "ચાદર",
    "sheet_id": "ચાદર",
    "delete_sheet": "છત કા delે છે",
    "report_name": "અહેવાલ નામ",
    "update_details": "વિગતો અપડેટ કરો",
    "unit": "એકમ",
    "parameters": "પરિમાણો",
    "send_excel_file": "એક્સેલ ફાઇલ મોકલો",
    "update_excel_template_based_report": "એક્સેલ નમૂના આધારિત અહેવાલ અપડેટ કરો",
    "create_excel_template_based_report": "એક્સેલ નમૂના આધારિત અહેવાલ બનાવો",
    "upload_excel_template_xlsx": "એક્સેલ ટેમ્પલેટ (XLSX) અપલોડ કરો",
    "param_equation": "પરમ સમીકરણ",
    "param_equations": "પરિમાણ સમીકરણો",
    "edit_sheets": "શીટ્સ સંપાદિત કરો",
    "row_no": "પંક્તિ નંબર",
    "column_no": "ક column લમ નંબર",
    "row_number": "પંક્તિ નંબર",
    "column_number": "સંખ્યા",
    "colummn_number": "સંખ્યા",
    "no_sheets_added": "કોઈ શીટ્સ ઉમેર્યું",
    "process_based_usage_sheet": "પ્રક્રિયા આધારિત ઉપયોગ શીટ",
    "process": "પ્રક્રિયા",
    "process_label": "પ્રક્રિયા લેબલ",
    "add_tag_types": "ટ tag ગ પ્રકારો ઉમેરો",
    "add_process_to_list": "સૂચિમાં પ્રક્રિયા ઉમેરો",
    "machine_tag_based_daily_data_sheet": "મશીન ટેગ આધારિત દૈનિક ડેટા શીટ",
    "machine_label": "યંત્ર -લેબલ",
    "add_machine_to_list": "સૂચિમાં મશીન ઉમેરો",
    "add_tag_type_to_list": "સૂચિમાં ટ tag ગ પ્રકાર ઉમેરો",
    "shift_based_usage_sheet": "પાળી આધારિત વપરાશ શીટ",
    "status_for_process": "પ્રક્રિયા",
    "status_for_machine": "મશીન",
    "esg_summary_item": "સારાંશ",
    "esg_details_item": "વિગતો",
    "esg_scope1_label": "અવકાશ 1",
    "esg_scope2_label": "અવકાશ 2",
    "esg_scope3_label": "અવકાશ 3",
    "esg_scopes_list_label": "અવકાશ",
    "transpose": "સ્થાનાંતરિત કરવું",
    "process_based_timeframed_data_sheet": "પ્રક્રિયા આધારિત સમયમર્યાદા ડેટા શીટ",
    "add_pocess_to_list": "સૂચિમાં પ્રક્રિયા ઉમેરો",
    "tag_types": "ટ tag ગ પ્રકાર",
    " consolidated_shift_based_usage_data_sheet": "કન્સોલિડેટેડ શિફ્ટ આધારિત વપરાશ ડેટા શીટ",
    "date_format": "તારીખ ફોર્મેટ",
    "include_process_total": "પ્રક્રિયા કુલ શામેલ કરો",
    "machine_group_based_usage_sheet": "મશીન ગ્રુપ આધારિત વપરાશ શીટ",
    "machine_group_label": "યંત્ર જૂથ -લેબલ",
    "machine_group": "મશીન જૂથ",
    "add_machine_group_to_list": "સૂચિમાં મશીન જૂથ ઉમેરો",
    "parameter_based_usage_data_sheet": "પરિમાણ આધારિત વપરાશ ડેટા શીટ",
    "show_month_start&end_data": "મહિનો પ્રારંભ અને અંત ડેટા બતાવો",
    "statistics": "આંકડા",
    "add_param_to_list": "સૂચિમાં પરમ ઉમેરો",
    "add_timestamp_to_list": "સૂચિમાં ટાઇમસ્ટેમ્પ ઉમેરો",
    "parameter_group_based_timeframed": "પરિમાણ જૂથ આધારિત સમયમર્યાદા",
    "param_group_label": "પરમ જૂથનું લેબલ",
    "param_group": "પરમ જૂથ",
    "add_param_group_to_list": "સૂચિમાં પરમ જૂથ ઉમેરો",
    "dynamic_logger_based_data_sheet": "ગતિશીલ લોગ આધારિત ડેટા શીટ",
    "dynamic_log_label": "ગતિશીલ લોગ લેબલ",
    "parameter_equation_based_sheet": "પરિમાણ સમીકરણ આધારિત શીટ",
    "data_source": "આંકડા",
    "equation_label": "સમીકરણ લેબલ",
    "add_equation_to_list": "સૂચિમાં સમીકરણ ઉમેરો",
    "absolute_values": "પૂર્ણ મૂલ્યો",
    "consumption_based_sheet": "વપરાશ આધારિત પત્ર",
    "parameter_name": "પરિમાણ નામ",
    "statistical_analysis_based_sheet": "આંકડાકીય વિશ્લેષણ આધારિત શીટ",
    "parameter_instantaneous_data_sheet": "પરિમાણ ત્વરિત ડેટા શીટ",
    "create_sheet_column": "શીટ સ્તંભ બનાવો",
    "column_label": "સ્તંભનું લેબલ",
    "machine_id": "યંત્ર -આઈડી",
    "create_machine_list": "મશીન સૂચિ બનાવો",
    "create_param_list": "પરિમાણ સૂચિ બનાવો",
    "create_sheets": "શીટ્સ બનાવો",
    "create_shift": "પાળી બનાવો",
    "description_optional": "વર્ણન (વૈકલ્પિક)",
    "create_sku": "સ્કુ બનાવો",
    "create_location": "સ્થાન બનાવો",
    "location_type": "સ્થાન પ્રકાર",
    "from_location": "સ્થાનથી",
    "to_location": "સ્થાન",
    "event_type": "ઘટના પ્રકાર",
    "create_location_event_type": "સ્થાન ઇવેન્ટ પ્રકાર બનાવો",
    "create_list": "સૂચિ બનાવો",
    "create_param_equation": "પરમ સમીકરણ બનાવો",
    "positive_params": "સકારાત્મક પરમ",
    "params_to_be_substracted": "બાદબાકી કરવા માટેના પરિમાણો",
    "create_combo_param": "કોમ્બો પરમ બનાવો",
    "combo_params": "સંયુક્ત પરિમાણો",
    "calculated_params": "ગણતરી કરેલ પરિમાણો",
    "inter_param_operator": "આંતર પરમત્ર",
    "correction_operator": "કરેક્શન પ્રચારક",
    "correction_operand": "સુધારણા -વ્યવસ્થાપન",
    "create_calculated_param": "ગણતરી કરેલ પરમ બનાવો",
    "calculated_param": "ગણતરી કરેલ પરમ",
    "correction_factor": "સુધારણા પરિબળ",
    "param1_type": "પરમ 1 પ્રકાર",
    "param1": "પરમ 1",
    "param1_operator": "પરમ 1 ઓપરેટર",
    "param1_operand": "પરમ 1 ઓપરેન્ડ",
    "param2_type": "પરમ 2 પ્રકાર",
    "param2": "પરમ 2",
    "param2_operator": "પરમ 2 ઓપરેટર",
    "param2_operand": "પરમ 2 ઓપરેન્ડ",
    "create_esg_scope_conversion": "ઇએસજી અવકાશ રૂપાંતર બનાવો",
    "category": "શ્રેણી",
    "esg_scope_logging": "ઇએસજી અવકાશ લોગિંગ",
    "get_from_param": "પરમમાંથી મેળવો",
    "choose_param": "પરમ પસંદ કરો",
    "create_target": "લક્ષ્ય બનાવો",
    "create_word": "શબ્દ બનાવો",
    "words": "શબ્દો",
    "choose_OEM": "OEM પસંદ કરો",
    "get_the_models": "મોડેલો મેળવો",
    "get_oem_devices": "OEM ઉપકરણો મેળવો",
    "oem_devices": "ઓ.એમ.",
    "model_number": "નમૂનો",
    "register": "નોંધણી કરવી",
    "add_modbus_gateway": "મોડબસ ગેટવે ઉમેરો",
    " add_modbus_TCP_gateway": "મોડબસ ટીસીપી ગેટવે ઉમેરો",
    "IPAddress": "આઈપી સરનામું",
    "port_number": "બંદર નંબર",
    "HubID": "હબ આઈડી",
    "baud_rate": "બૌડ દર",
    "parity": "સમાનતા",
    "stop_bit": "બંધ કરવું",
    "update_wifi": "વાઇફાઇ અપડેટ કરો",
    "SSID": "સિસિડ",
    "password": "પાસશ",
    "restart_enture": "ફરી શરૂ થવું",
    "force_update_configs": "દબાણ અપડેટ રૂપરેખા",
    "activity_name": "પ્રવૃત્તિ નામ",
    "board": "બોર્ડ",
    "calender": "કેલેન્ડર",
    "maintenance": "જાળવણી",
    "welcome": "આવકાર્ય",
    "logout": "લોગઆઉટ",
    "help": "મદદ",
    "accounts": "હિસાબ",
    "organisation": "સંગઠન",
    "pointer_color_optional": "નિર્દેશક રંગ (વૈકલ્પિક)",
    "user_id": "વપરાશકર્તા આઈડી",
    "confirm_new_password": "નવા પાસવર્ડની પુષ્ટિ કરો",
    "service": "સેવા",
    "send_grid": "મોકલવું",
    "default_enture_view": "ડિફોલ્ટ એન્ટર દૃશ્ય",
    "permissions": "મંજૂરી",
    "areas": "વિસ્તારો",
    "remark": "ટીકા",
    "days": "દિવસ",
    "yesterdays_aqi": "ગઈકાલે એક્યુઆઈ",
    "aqi": "એક્યુઆઈ",
    "choose_air_quality_device": "હવા ગુણવત્તા ઉપકરણ પસંદ કરો",
    "delete_this_device": "આ ઉપકરણ કા Delete ી નાખો",
    "air_quality_label": "હવા ગુણવત્તા લેબલ",
    "humidity": "ભેજ",
    "temperature": "તાપમાન",
    "watcher": "જોનાર",
    "area_reference": "વિસ્તાર સંદર્ભ",
    "write_data": "ડેટા લખો",
    "day_of_week": "અઠવાડિયાનો દિવસ",
    "month": "મહિનો",
    "diagnostics_notification_groups": "ડાયગ્નોસ્ટિક્સ સૂચના જૂથો",
    "hub_name": "હબ નામ",
    "hub_model": "કેન્દ્ર",
    "hub_type": "હબ પ્રકાર",
    "hub": "હબ",
    "update_your_MQTT_bridge_config": "તમારા એમક્યુટીટી બ્રિજ રૂપરેખાને અપડેટ કરો",
    "MQTT_bridge_mode": "એમ.ક્યુ.ટી.ટી. બ્રિજ મોડ",
    "broker_address": "દલાલ સરનામું",
    "update_your_OPC_config": "તમારું ઓપીસી રૂપરેખા અપડેટ કરો",
    "OPC_client_mode": "ઓપીસી ક્લાયંટ મોડ",
    "OPC_server_URL": "ઓપીસી સર્વર URL",
    "user_auth": "વપરાશકર્તા લેખક",
    "mode": "પદ્ધતિ",
    "security_policy": "સલામતી નીતિ",
    "Update_your_Edge_Hub_restart_Schedule": "તમારા એજ હબ ફરીથી પ્રારંભ શેડ્યૂલને અપડેટ કરો",
    "auto_restart": "ઓટો ફરીથી પ્રારંભ કરો",
    "time_24Hrs": "સમય (24 કલાક)",
    "modbus_ping_latency": "મોડબસ પિંગ લેટન્સી",
    "modbus_unhealthy_wait": "મોડબસ સ્વાસ્થ્યપ્રદ પ્રતીક્ષા",
    "edit_written_log_book": "લેખિત લોગ પુસ્તક સંપાદિત કરો",
    "field_cant_change": "આ ક્ષેત્ર બદલી શકતું નથી",
    "re_write": "ફરી લખવું",
    "write_in_logbook": "લોગબુકમાં લખો",
    "write": "લખવું",
    "download_template": "નમૂના ડાઉનલોડ કરો",
    "write_in_logbook_csv": ".Csv દ્વારા લોગ બુકમાં લખો",
    "upload_file": "અપલોડ ફાઇલ",
    "batch": "ચોપડી",
    "move_batch": "બેચ ખસેડો",
    "on": "ચાલુ",
    "off": "Offંચું",
    "fast_mode": "ઝડપી પદ્ધતિ",
    "batch_shipping_details": "બેચ શિપિંગ વિગતો",
    "vehicle_no": "વાહન નંબર",
    "net_weight": "ચોખ્ખું વજન",
    "actual_weight": "ખરેખર વજન",
    "vendor": "વિક્રેતા",
    "loaded_by": "દ્વારા ભરેલું",
    "verified_by": "દ્વારા ચકાસી શકાય તેવું",
    "document_no": "દસ્તાવેજ ના",
    "multiple_machine_param": "બહુવિધ મશીન અને પરિમાણો",
    "map_paramid_paramequation_to_row": "રોમાં પરમ આઈડી અથવા પરમ સમીકરણો નકશો",
    "add_current_date_to_sheet": "શીટમાં વર્તમાન તારીખ ઉમેરો",
    "create_parameter_from_template": "નમૂનામાંથી પરિમાણ બનાવો",
    "selected_parameters_count": "પસંદ કરેલા પરિમાણો ગણતરી",
    "restricted_user_access": "મર્યાદિત વપરાશકર્તા પ્રવેશ",
    "select_all_parameters": "બધા પરિમાણો પસંદ કરો",
    "edit_angular_gauge_widget": "કોણીય ગેજ વિજેટમાં ફેરફાર કરો",
    "add_angular_gauge_widget": "કોણીય ગેજ વિજેટ ઉમેરો",
    "widget_label": "વિજેટનું લેબલ",
    "widget_width": "વિજેટની પહોળાઈ",
    "widget_height": "વિજેટની .ંચાઈ",
    "param_label": "પરમ લેબલ",
    "choose_parameter": "પરિમાણ પસંદ કરો",
    "processing": "પ્રક્રિયા",
    "edit_banner_widget": "બેનર વિજેટ સંપાદિત કરો",
    "add_banner_widget": "બેનર વિજેટ ઉમેરો",
    "edit_bartd_widget": "બાર ટીડી વિજેટ સંપાદિત કરો",
    "add_bar_widget": "બાર વિજેટ ઉમેરો",
    "yaxis_label": "વાય - અક્ષ લેબલ",
    "xaxis_label": "એક્સ - અક્ષ લેબલ",
    "y1axis_label": "વાય 1 - અક્ષ લેબલ",
    "y2axis_label": "વાય 2 - અક્ષ લેબલ",
    "edit_dual_axis_bar_trend_widget": "ડ્યુઅલ એક્સિસ બાર ટ્રેન્ડ વિજેટને સંપાદિત કરો",
    "add_dual_axis_bar_trend_widget": "ડ્યુઅલ એક્સિસ બાર ટ્રેન્ડ વિજેટ ઉમેરો",
    "y1_graph_type": "Y1 અક્ષ ગ્રાફ પ્રકાર",
    "y2_graph_type": "વાય 2 અક્ષ ગ્રાફ પ્રકાર",
    "idealValue": "આદર્શ મૂલ્ય",
    "idealLabel": "આદર્શલેબેલ",
    "add_parameters": "પરિમાણો ઉમેરો",
    "add_equation": "સમીકરણ ઉમેરો",
    "add_params": "પરમ ઉમેરો",
    "enter_of_machines": "મશીનોનો નંબર દાખલ કરો",
    "edit_bar_trend_td_widget": "બાર ટ્રેન્ડ ટીડી વિજેટ સંપાદિત કરો",
    "add_bar_trend_td_widget": "બાર ટ્રેન્ડ ટીડી વિજેટ ઉમેરો",
    "graph_type": "આલેખ પ્રકાર",
    "aggregation": "એકંદર",
    "default_graph_type": "ડિફોલ્ટ ગ્રાફ પ્રકાર",
    "bar_mode": "બારમાખી",
    "show_ideal_line": "આદર્શ રેખા બતાવો",
    "equations": "સમીકરણો",
    "edit_bitwise_word_widget": "બીટવાઇઝ વર્ડ વિજેટને સંપાદિત કરો",
    "add_bitwise_word_widget": "બીટવાઇઝ વર્ડ વિજેટ ઉમેરો",
    "show_bit_status": "બીટ સ્ટેટસ બતાવો",
    "edit_bullet_gauge_widget": "બુલેટ ગેજ વિજેટ સંપાદિત કરો",
    "add_bullet_gauge_widget": "બુલેટ ગેજ વિજેટ ઉમેરો",
    "reference_value_for": "સંદર્ભ મૂલ્ય",
    "gauge_type": "પૌરાણિક કથા",
    "indicator_color": "સૂચક",
    "busbar_temperature_graph_widget": "બસ બાર તાપમાન ગ્રાફ વિજેટ",
    "busbar_module": "બસ પટ્ટી",
    "edit_consumption_gauge_widget": "વપરાશ ગેજ વિજેટમાં ફેરફાર કરો",
    "add_consumption_gauge_widget": "વપરાશ ગેજ વિજેટ ઉમેરો",
    "linearGauge": "રેખીય ધાડ",
    "show_target": "લક્ષ્યાંક",
    "healthy_on_below_target": "તંદુરસ્ત નીચે લક્ષ્ય પર",
    "edit_consumption_pie_widget": "વપરાશ પાઇ વિજેટમાં ફેરફાર કરો",
    "add_consumption_pie_widget": "વપરાશ પાઇ વિજેટ ઉમેરો",
    "add_color_break_point": "રંગ વિરામ બિંદુ ઉમેરો",
    "custom_color": "પર્વતનો રંગ",
    "color": "રંગ",
    "edit_heat_map_widget": "હીટ મેપ વિજેટને સંપાદિત કરો",
    "add_heat_map__widget": "હીટ મેપ વિજેટ ઉમેરો",
    "choose_equation": "સમીકરણ પસંદ કરો",
    "label_star": "લેબલ*",
    "color_star": "રંગ",
    "operator1_star": "ઓપરેટર 1*",
    "value1_star": "મૂલ્ય 1*",
    "operator2_star": "ઓપરેટર 2*",
    "actions": "ક્રિયા",
    "condition": "શરત",
    "status_color": "દરજ્જાનો રંગ",
    "text_view": "પાઠ્ય દૃશ્ય",
    "edit_process_Status_widget": "પ્રક્રિયા સ્થિતિ વિજેટમાં ફેરફાર કરો",
    "add_process_Status_widget": "પ્રક્રિયા સ્થિતિ વિજેટ ઉમેરો",
    "edit_machine_Status_widget": "મશીન સ્થિતિ વિજેટ સંપાદિત કરો",
    "add_machine_Status_widget": "મશીન સ્થિતિ વિજેટ ઉમેરો",
    "choose_process": "પ્રક્રિયાઓ પસંદ કરો",
    "choose_machine": "મશીનો પસંદ કરો",
    "table_header_label": "કોષ્ટક મથારી",
    "edit_table_TD_widget": "ટેબલ ટીડી વિજેટ સંપાદિત કરો",
    "add_table_TD_widget": "કોષ્ટક ટીડી વિજેટ ઉમેરો",
    "configure_table": "કોષ્ટક ગોઠવો",
    "edit_target_based_trend_widget": "લક્ષ્ય આધારિત વલણ વિજેટમાં ફેરફાર કરો",
    "add_target_based_trend_widget": "લક્ષ્ય આધારિત વલણ વિજેટ ઉમેરો",
    "target": "નિશાન",
    "target_line_label": "લક્ષ્યાંક રેખા લેબલ",
    "edit_usage_score_card": "વપરાશ સ્કોરકાર્ડ વિજેટને સંપાદિત કરો",
    "add_usage_score_card": "વપરાશ સ્કોરકાર્ડ વિજેટ ઉમેરો",
    "compare_to": "ની સરખામણી કરવી",
    "assign_color": "રંગ સોંપવો",
    "enter": "પ્રવેશ",
    "gauge_to_dashboard": "હઠી",
    "widget_types": "વિજેટ પ્રકારો",
    "from_value": "મૂલ્યથી",
    "to_value": "મૂલ્ય",
    "machine_status": "યંત્ર -દરજ્જો",
    "deviceA_type": "એક પ્રકાર",
    "templates": "નમૂનાઓ",
    "add_triggers_to_parameter": "પરિમાણમાં ટ્રિગર્સ ઉમેરો",
    "triggers": "ઉત્તેજન આપવું",
    "show_filter": "બતાવો",
    "view_id": "ID ID",
    "move_up": "આગળ વધવું",
    "move_down": "આગળ વધવું",
    "multiplier_types": "ગુણાકાર",
    "energy_report": "E ર્જા અહેવાલ",
    "add_machine_param": "મશીન પરમ ઉમેરો",
    "aliasing": "પરવાના",
    "no_zero_read": "શૂન્ય વાંચન નથી",
    "remove_negative_entropy": "નકારાત્મક એન્ટ્રોપી દૂર કરો",
    "calculate_consumption": "વપરાશની ગણતરી કરો",
    "disabled": "અયોગ્ય",
    "limiter_value": "મર્યાદા મૂલ્ય",
    "machine_scroll_view_page": "મશીન સ્ક્રોલ જુઓ પૃષ્ઠ",
    "create_mannual_parameter": "મેન્યુઅલ પરિમાણ બનાવો",
    "restrict_user_access": "વપરાશકર્તા access ક્સેસને પ્રતિબંધિત કરો",
    "add_device_parameter": "ઉપકરણ પરિમાણ ઉમેરો",
    "entropy": "પ્રાણ",
    "idle_latency": "નિષ્ક્રિયતા",
    "add_OPC_parameter": "ઓપીસી પરિમાણ ઉમેરો",
    "node_id": "નોડ આઈ.",
    "byte_format": "બાયટ ફોર્મેટ",
    "param_scroll_view_page": "પરમ સ્ક્રોલ જુઓ પૃષ્ઠ",
    "timeframed_sec_report": "ટાઇમ ફ્રેમ્ડ એસઇસી રિપોર્ટ",
    "prod_unit": "ઉપશામક",
    "SEC_unit": "સેકંડ",
    "energy_parameters": "Energyર્જા પરિમાણો",
    "prod_param_type": "પ્રોડ પરમ પ્રકાર",
    "production_parameters": "ઉત્પાદન પરિમાણો",
    "no_of_intervals": "અંતરાલ ના",
    "granular_data": "દાણાદાર -માહિતી",
    "csv": "સી.એસ.વી.",
    "delete_rule": "નિયમ કા delી નાખો",
    "enable_disable_all_parameters": "બધા પરિમાણોને સક્ષમ/અક્ષમ કરો",
    "disable": "અપંગ કરવું",
    "enable": "સક્ષમ કરવું",
    "edit_computed_parameter": "ગણતરી પરિમાણ સંપાદિત કરો",
    "quantization": "જથ્થો",
    "command": "આદેશ આપવો",
    "register_size": "રજિસ્ટર",
    "consumer_ID": "ઉપભોક્તા આઈડી",
    "site_id": "સ્થળ",
    "get_data": "ડેટા મેળવો",
    "update_param_type": "અપડેટ પરમ પ્રકાર",
    "write_in_data_logbook": "ડેટા લોગબુકમાં લખો",
    "daily_data": "દૈનિક માહિતી",
    "log_entry": "લોગ નોંધ",
    "enable_Scroll_mode": "સ્ક્રોલ મોડને સક્ષમ કરો",
    "multiple_areas": "અનેક ક્ષેત્ર",
    "time_of_day": "દિવસનો સમય",
    "capacity_logger": "ક્ષમતાનો લંગર",
    "provider": "પ્રદાતા",
    "total_sites_received_bills_MTD": "કુલ સાઇટ્સને બીલ એમટીડી મળ્યા",
    "sites_yet_to_receive_bills": "સાઇટ્સ હજી બીલ મેળવવાની બાકી છે",
    "total_sites": "કુલ સ્થળો",
    "sites_received_bills_MTD": "%સાઇટ્સને બીલ એમટીડી મળ્યા",
    "total": "કુલ",
    "total_bills_received_mtd": "કુલ બીલોને એમટીડી મળ્યો",
    "arrear_1": "બાકી 1",
    "arrear_2": "બાકી 2",
    "no_reading": "વાંચન",
    "bills_received_in_5_days": "છેલ્લા 5 દિવસમાં બીલ પ્રાપ્ત થયા",
    "site_wise_status": "ઓકે સ્ટેટસ સિવાયની સાઇટ મુજબની સ્થિતિ",
    "site_wise_final_summary": "સ્થળ મુજબની અંતિમ સારાંશ",
    "source_link": "સ્ત્રોત લિંક",
    "data_type": "આધાર સામગ્રી",
    "select_an_image_to_upload": "અપલોડ કરવા માટે છબી પસંદ કરો",
    "user_group_Id": "જૂથ -આઈડી",
    "user_group_name": "જૂથનું નામ",
    "delay_in_mins": "(મિનિટ) માં વિલંબ",
    "delete_task": "કાર્ય કા .ી નાખો",
    "watchers": "નિરીક્ષકો",
    "comments": "ટિપ્પણી",
    "asset_under_maintenance": "જાળવણી હેઠળની સંપત્તિ",
    "start_time": "પ્રારંભ સમય",
    "end_time": "અંતિમ સમય",
    "download_latest_usage_table": "નવીનતમ વપરાશ ટેબલ ડાઉનલોડ કરો",
    "tag": "ટ tag ગ",
    "add_target_data": "લક્ષ્ય ડેટા ઉમેરો",
    "edit_target": "લક્ષ્યાંક સંપાદિત કરો",
    "delete_target": "લક્ષ્યાંક કા Delete ી નાખો",
    "prop": "આગળનો ભાગ",
    "excel_template_based_report": "ઉત્તમ નમૂના આધારિત અહેવાલ",
    "configured_hub": "રૂપરેખાંકિત હબ",
    "configured_relay": "રૂપરેખાંકિત રિલે",
    "desired_state": "ઇચ્છિત રાજ્ય",
    "configured_notification_group": "રૂપરેખાંકિત સૂચના જૂથ",
    "email_ids": "ઇમેઇલ ID",
    "logs": "લાડક",
    "unit_id": "એકમની આઈડી",
    "download_report": "ડાઉનલોડ અહેવાલ",
    "frequency": "આવર્તન",
    "ip": "આદ",
    "edit_wifi": "વાઇફાઇ સંપાદિત કરો",
    "details": "વિગતો",
    "connected_to": "સાથે જોડાયેલા",
    "software_version": "સ Sonc ફ સ Reg ફ સરંજામ",
    "mac_address": "મેક સરનામું",
    "opc_client_enabled": "ઓપીસી ક્લાયંટ સક્ષમ",
    "MQTT_bridge_enabled": "એમક્યુટીટી બ્રિજ સક્ષમ",
    "hub_specification": "કેન્દ્ર",
    "hub_details": "કેન્દ્ર વિગતો",
    "restart_schedule": "ફરીથી પ્રારંભ સમયપત્રક",
    "restart_device": "ઉપકરણ ફરીથી પ્રારંભ કરો",
    "set_up_restart_time": "ફરીથી પ્રારંભ કરો સમય સેટ કરો",
    "connectivity_logs": "કનેક્ટિવિટી લ s ગ્સ",
    "internal_cache_size": "આંતરિક કેશ કદ",
    "default": "ચૂક",
    "configs": "રૂપરેખા",
    "processed": "પ્રક્રિયાને લગતું",
    "space_available": "ઉપલબ્ધ જગ્યા",
    "kb": "કે.બી.",
    "mb": "એમબી",
    "last_ping": "છેલ્લું પિંગ",
    "toggle": "ટોકવું",
    "configured_creds": "રૂપરેખાંકિત ક્રેડિટ",
    "psk": "પી.સી.કે.",
    "set_value": "નિર્ધારિત કિંમત",
    "time_current_time": "સમય (જો ઉલ્લેખ કરવામાં ન આવે તો વર્તમાન સમય લેવામાં આવશે)",
    "Set": "સમૂહ",
    "health": "આરોગ્ય",
    "port": "બંદર",
    "mqtt_params": "Qકરું",
    "delete_device": "ઉપકરણ કા Delete ી નાખો",
    "view_details": "વિગતો જુઓ",
    "parameter_id": "પરિમાણ ID",
    "email_address": "ઇમેઇલ સરનામું",
    "attached_triggers": "જોડાયેલા ટ્રિગર્સ",
    "parameter_details": "પરિમાણ વિગતો",
    "enable_write": "લખવા માટે સક્ષમ કરો",
    "disable_write": "લખવાનું અક્ષમ કરો",
    "offset": "Offંચું કરવું",
    "no_of_parameters": "પરિમાણો નહીં",
    "creation_time": "બનાવટનો સમય",
    "not_configured": "ગોઠવેલ નથી",
    "add_rule": "નિયમ વધારવો",
    "sl_no": "એસ.એલ. નંબર",
    "targeted_by_value": "મૂલ્ય દ્વારા લક્ષિત",
    "configured_action": "નિર્ધારિત ક્રિયા",
    "add_action": "ક્રિયા ઉમેરો",
    "action_id": "કાર્ય",
    "delete_user": "વપરાશકર્તા કા Delete ી નાખો",
    "bit": "બચ્ચું",
    "low": "નીચું",
    "high": "Highંચું",
    "esg_scope_conversion": "ઇએસજી અવકાશ રૂપાંતર",
    "batch_production_logs": "બેચ ઉત્પાદન લ s ગ્સ",
    "esg_data_logs": "ઇએસજી ડેટા લ s ગ્સ",
    "remove_from_dashboard": "ડેશબોર્ડથી દૂર કરો",
    "data_logs": "આંકડાકીય માહિતી",
    "select_device": "ઉપકરણ પસંદ કરો",
    "fetch_connectivity_logs": "કનેક્ટિવિટી લ s ગ્સ લાવો",
    "hub_internet_connectivity_logs": "હબ ઇન્ટરનેટ કનેક્ટિવિટી લ s ગ્સ",
    "modbus_gateways": "મોડબસ પ્રવેશદ્વાર",
    "device_switched_on": "ઉપકરણ ચાલુ છે",
    "device_switched_off": "ઉપકરણ બંધ છે",
    "parameter_report": "પરિમાણ અહેવાલ",
    "timeframed_specific_energy_consumption_report": "સમયમર્યાદા ચોક્કસ energy ર્જા વપરાશ અહેવાલ",
    "ebill_distribution_report": "ઈ-બિલ વિતરણ અહેવાલ",
    "root_user_management": "મૂળ -વ્યવસ્થા",
    "user_acces_management": "વપરાશકર્તા પ્રવેશ સંચાલન",
    "inference": "અનુમાન",
    "turn_off": "બંધ કરવું",
    "turn_on": "ચાલુ કરવું",
    "diagram_pallette": "આકૃતિ",
    "add_component": "ઘટક ઉમેરો",
    "components": "ઘટકો",
    "add_text": "લખાણ ઉમેરો",
    "add_realtime_data": "રીઅલટાઇમ ડેટા ઉમેરો",
    "shapes": "આકાર",
    "lines": "ચકુર",
    "text_realtime_component": "ટેક્સ્ટ રીઅલટાઇમ ઘટક",
    "shapes_pallette": "આકાર પેલેટ",
    "lines_pallette": "પ palણ",
    "click_choose": "એસએલડીમાં ઉમેરવા માટે આકારો ક્લિક કરો અને પસંદ કરો",
    "choose_line": "એસએલડી ઉમેરવા માટે લાઇન પસંદ કરો",
    "choose_image": "છબી પસંદ કરો",
    "upload_image_to_add": "એસએલડી ઉમેરવા માટે છબી અપલોડ કરો",
    "add_realtime_textdata": "એસએલડીમાં રીઅલ ટાઇમ અથવા ટેક્સ્ટ ડેટા ઉમેરો",
    "update_user": "અપડેટ વપરાશકર્તા",
    "add_watcher": "નિરીક્ષક ઉમેરો",
    "premise_area": "આધાર -ક્ષેત્ર",
    "create_computed_param": "ગણતરી કરેલ પરમ બનાવો",
    "create_derived_param": "તારવેલા પરમ બનાવો",
    "proceed_to_ship": "વહાણમાં આગળ વધવું",
    "esg_data_collection": "ઇએસજી ડેટા સંગ્રહ",
    "esg_scope_details": "ઇએસજી અવકાશ વિગતો",
    "select_custom_date_range": "કસ્ટમ તારીખ શ્રેણી પસંદ કરો",
    "days_365": "365 દિવસ",
    "days_180": "180 દિવસ",
    "days_30": "30 દિવસ",
    "create_user_for_SSO": "એસએસઓ માટે વપરાશકર્તા બનાવો",
    "add_widget": "વિજેટ ઉમેરો",
    "add_machine_status_rules": "મશીન સ્થિતિ નિયમો ઉમેરો",
    "add_trigger": "ટ્રિગર ઉમેરો",
    "add_logged_parameter": "લ logged ગ કરેલા પરિમાણ ઉમેરો",
    "edit_logged_parameter": "લ logged ગ કરેલા પરિમાણને સંપાદિત કરો",
    "create_mannual_param": "મેન્યુઅલ પરમ બનાવો",
    "add_opc_param": "ઓપીસી પરમ ઉમેરો",
    "create_write_block": "લેખન અવરોધ બનાવો",
    "all_parameters": "બધા પરિમાણો",
    "edit_device": "ઉપકરણ સંપાદિત કરો",
    "edit_modbus_device": "મોડબસ ડિવાઇસ સંપાદિત કરો",
    "edit_mqtt_device": "એમક્યુટીટી ડિવાઇસ સંપાદિત કરો",
    "limit_entropy": "મર્યાદા entrંચર",
    "emails": "ઇમેઇલ",
    "power_factor": "પાવરફેક્ટર (પીએફ)",
    "tarrif_rate": "પ્રશુલ્ક દર",
    "apparent_power": "સ્પષ્ટ શક્તિ",
    "reactive_power": "પ્રતિક્રિયાશીલ શક્તિ",
    "active_power": "સક્રિય શક્તિ",
    "energy_usage_view_page": "Energy ર્જા વપરાશ દૃશ્ય પૃષ્ઠ",
    "switch_organisation": "સ્વિચ સ્વિચ",
    "wireless_creds": "તાર ક્રેડિટ",
    "latest": "અદ્યતન",
    "value_is": "મૂલ્ય છે",
    "please_take_necessary_action": "કૃપા કરીને જરૂરી કાર્યવાહી કરો",
    "edit_role": "ભૂમિકા સંપાદિત કરો",
    "delete_role": "ભૂમિકા કા delી નાંખો",
    "today": "આજ",
    "days_4": "4 દિવસ",
    "due": "યોગ્ય",
    "move_to_done": "થઈ ગયું",
    "power_system_fault": "પાવર સિસ્ટમ ફોલ્ટ ગણતરી (ગયા અઠવાડિયે)",
    "alarms": "રણ",
    "electricity_consumption": "વીજળીનો વપરાશ (આજ સુધીનો મહિનો)",
    "average_demand": "સરેરાશ માંગ",
    "water_use_intensity": "પાણીનો ઉપયોગ તીવ્રતા (WUI)",
    "average_water_cost": "સરેરાશ પાણીનો ખર્ચ (માસિક)",
    "water_use_efficiency": "પાણીનો ઉપયોગ કાર્યક્ષમતા (ડબ્લ્યુયુયુ)",
    "batch_logs": "બેચ લ s ગ્સ",
    "edit_log": "લોગ સંપાદિત કરો",
    "delete_log": "લોગ કા Delete ી નાખો",
    "dates": "તારીખ",
    "log_details": "લોગ વિગતો",
    "view": "દૃશ્ય",
    "scope1_emission": "અવકાશ 1 ઉત્સર્જન",
    "scope2_emission": "અવકાશ 2 ઉત્સર્જન",
    "lmtd": "L",
    "company_data": "કંપનીનો ડેટા",
    "historian": "ઇતિહાસકાર",
    "from_optional": "થી (વૈકલ્પિક)",
    "to_optional": "માટે (વૈકલ્પિક)",
    "trends": "વલય",
    "generate_trend": "વલણ પેદા કરો",
    "by": "પાસે",
    "check_logs": "લોગ તપાસો",
    "workspaces": "કામકાજ",
    "types_of_nodes_connected": "કનેક્ટેડ ગાંઠોનાં પ્રકારો",
    "types_of_modbus_device_connected": "મોડબસ ઉપકરણોના પ્રકારો જોડાયેલા",
    "no_of_registers": "રજિસ્ટર નથી",
    "write_register": "રજિસ્ટર લખો",
    "setting_view": "નિર્ધારણ",
    "sign_up": "સાઇન અપ કરવું",
    "reset": "પુનર્જીવિત કરવું",
    "mobile_number": "ફરતી નંબર",
    "nebeskie_labs": "નેબેસ્કી લેબ્સ",
    "please_register_and_send_otp": "કૃપા કરીને તમારી રજિસ્ટર્ડ ઇમેઇલ ID દાખલ કરો અને મોકલો ઓટીપી પર ક્લિક કરો.",
    "reset_password": "પાસવર્ડ ફરીથી સેટ કરો",
    "back_to_login": "લ login ગિન પર પાછા",
    "otp": "ઓટપી",
    "send_otp": "ઓટીપી મોકલો",
    "org_alias": "ઉર્ફે",
    "please_stand_by": "કૃપા કરીને stand ભા રહો",
    "total_usage_this_month": "આ મહિને કુલ વપરાશ",
    "total_usage_last_month": "ગયા મહિને કુલ વપરાશ",
    "generate_report_manually": "મેન્યુઅલી રિપોર્ટ જનરેટ કરો",
    "energy_distribution_detailed_report": "Energyર્જા વિતરણ અહેવાલ",
    "the_report_is_processing": "રિપોર્ટ પ્રક્રિયા કરી રહ્યો છે",
    "enter_active_energy_manually": "સક્રિય energy ર્જા જાતે દાખલ કરો",
    "include_others": "અન્ય શામેલ કરો",
    "bill_amount": "હૂંડી રકમ",
    "yes_delete_it": "હા, તેને કા delete ી નાખો",
    "create_write_param": "લખો પરમ બનાવો",
    "report_list": "અહેવાલ -યાદી",
    "plant_id": "વનસ્પતિ -આઈડી",
    "plant_name": "છોડનું નામ",
    "plant_address": "વનસ્પતિનું પ્રમાણ",
    "add_selected": "પસંદ કરેલ ઉમેરો",
    "clear_selection": "સ્પષ્ટ પસંદગી",
    "real_time_value": "વાસ્તવિક સમય મૂલ્ય",
    "default_status": "કણ દરજ્જા",
    "default_label": "પરત લેબલ",
    "default_color_optional": "ડિફોલ્ટ રંગ વૈકલ્પિક",
    "rule_based_status": "નિયમ આધારિત દરજ્જો",
    "show_label_only": "ફક્ત લેબલ બતાવો",
    "show_unit": "પ્રદર્શન એકમ",
    "status_rules": "દરજ્જો",
    "configure": "પસંદ કરવું",
    "monthly_usage": "માસિક ઉપયોગ",
    "meter_consumption_kwh": "મીટર વપરાશ (કેડબ્લ્યુએચ)",
    "total_unit": "કુલ એકમ",
    "region": "પ્રદેશ",
    "average_energy_consumed": "સરેરાશ energy ર્જા વપરાશ",
    "average_production": "સરેરાશ ઉત્પાદન",
    "average_sec": "સરેરાશ સેકંડ",
    "total_production": "કુલ ઉત્પાદન",
    "total_energy_consumed": "કુલ energy ર્જા વપરાશ",
    "max_sec": "મહત્તમ",
    "device_1": "ઉપકરણ 1",
    "device_parameter_1": "ઉપકરણ પરિમાણ 1",
    "device_2": "ઉપકરણ 2",
    "device_parameter_2": "ઉપકરણ પરિમાણ 2",
    "create_param": "પરમ બનાવો",
    "update_tags": "અપડેટ ટ s ગ્સ",
    "remove_from_process": "પ્રક્રિયામાંથી દૂર કરો",
    "machine_parameters": "મશીન પરિમાણો",
    "energy": "શક્તિ",
    "power": "શક્તિ",
    "remove_from_featured": "ફીચર્ડથી દૂર કરો",
    "other_parameters": "અન્ય પરિમાણો",
    "add_to_featured": "ફીચર્ડમાં ઉમેરો",
    "logged_parameters": "લ logged ગ કરેલા પરિમાણો",
    "tags": "ટ tag ગ",
    "machine_status_rules": "મશીન સ્થિતિ -નિયમો",
    "connected_communication_devices": "જોડાયેલ સંચાર ઉપકરણો",
    "mqtt_devices": "Mqtt ઉપકરણો",
    "modbus_devices": "પણ મોડબસ ઉપકરણો",
    "total_no_of_params": "પરમનો કુલ નંબર",
    "edge_devices": "ધારનાં ઉપકરણો",
    "machines_configured": "મશીનો ગોઠવેલ",
    "reports_configured": "રૂપરેખાંકિત અહેવાલો",
    "views_configured": "દૃશ્યો ગોઠવેલ",
    "send_email_notification": "ઇમેઇલ સૂચના મોકલો",
    "enture_send_email_notification": "ચાલો પસંદ કરેલા વપરાશકર્તા જૂથને ઇમેઇલ સૂચના મોકલો",
    "agree_and_send": "સંમત થવું અને મોકલો",
    "ebill": "મસાલા",
    "monthly_trend": "દર્પણ",
    "param_view": "પરમ દૃશ્ય",
    "calories": "કેલોરી",
    "param_count_view": "પરમ ગણતરી દૃશ્ય",
    "created": "બનાવેલું",
    "api_data_count_view": "API ડેટા દૃશ્ય",
    "diesel_emissions": "ડીઝલ ઉત્સર્જન",
    "eb_emissions": "ઇબી ઉત્સર્જન",
    "emissions_kg_co2": "ઉત્સર્જન કે.જી.-કો 2",
    "kpis": "કેપી",
    "jan_23": "જાન્યુ -23",
    "feb_23": "ફેબ્રુઆરી -23",
    "mar_23": "માર્ચ -23",
    "apr_23": "એપ્રિલ -23",
    "may_23": "મે -23",
    "jun_23": "જૂન -23",
    "lpg_emissions": "એલપીજી ઉત્સર્જન",
    "petrol_emissions": "પેટ્રોલ ઉત્સર્જન",
    "bullet_gauge": "ગોળી -ધારણ",
    "equipment": "સામાન",
    "enter_no_of_machines": "મશીનોનો નંબર દાખલ કરો",
    "shift": "પાળી",
    "upload": "અપલોડ કરવું",
    "image_upload": "છબી અપલોડ",
    "derived_parameter": "પરિમાણ પરિમાણ",
    "value_type": "મૂલ્ય_ટાઇપ",
    "enable_entropy": "એન્ટ્રોપી સક્ષમ કરો",
    "remove_decimal": "દશાંશ દૂર કરો",
    "limiter_entropy": "મર્યાદા entrંચર",
    "show_sku_id": "એસ.કે.યુ. આઈ.ડી.",
    "production_start_date": "ઉત્પાદન પ્રારંભ તારીખ (વૈકલ્પિક)",
    "production_start_time": "ઉત્પાદન પ્રારંભ સમય (વૈકલ્પિક)",
    "time": "સમય",
    "temperature_c": "તાપમાન (સી)",
    "set_temperature_c": "તાપમાન સેટ કરો (સી)",
    "no_of_people": "લોકો નહીં",
    "co2_level": "સીઓ 2 સ્તર",
    "aqi_optional": "એક્યુઆઈ વૈકલ્પિક",
    "delete_sheets": "શીટ્સ કા Delete ી નાખો",
    "root_login": "રુટ લ login ગિન",
    "uam_login": "યુએએમ ​​લ login ગિન",
    "login": "ફટકારવું",
    "add_oem_logo": "OEM લોગો ઉમેરો",
    "devices": "ક્વસ",
    "derived_data_units": "તારવેલી/ડેટા એકમો",
    "roles": "ભૂમિકા",
    "engineering": "ઈજનેરી",
    "control_panel": "નિયંત્રણ પેનલ",
    "views": "દૃશ્યો",
    "reports_analysis": "અહેવાલો",
    "actions_triggers": "ક્રિયાઓ અને ટ્રિગર્સ",
    "modules": "અંતલો",
    "processes": "પ્રક્રિયાઓ",
    "air_quality": "હવા ગુણવત્તા",
    "manufacturing": "ઉત્પાદન",
    "esg": "ઇએસજી",
    "task_managment": "કાર્ય વ્યવસ્થાપન",
    "sustainability": "ટકાઉપણું",
    "groups": "જૂથ",
    "log_book": "લોગ બુક",
    "monitor": "મોનીટર",
    "enture_settings": "દાખલ સેટિંગ્સ",
    "configuration": "ગોઠવણી",
    "bar": "અટકણ",
    "line": "પંક્તિ",
    "s7_device": "એસ 7 ઉપકરણ",
    "rack": "દાદર",
    "slot": "સ્લોટ",
    "db_number": "ડી.બી. નંબર",
    "byte_offset": "બાઇટ -સરભર",
    "bit_offset": "બચ્ચું",
    "data_length": "આંકડાકીય લંબાણ",
    "val_type": "મૂલ્ય પ્રકાર",
    "create_s7_device": "એસ 7 ડિવાઇસ ઉમેરો",
    "scope_label": "નિશાની",
    "client_safety": "ગ્રાહક",
    "edit": "સંપાદન કરવું",
    "delete": "કા delી નાખવું",
    "choose_param_to_monitor": "મોનિટર કરવા માટે પરમ પસંદ કરો",
    "rules": "નિયમો",
    "add_to_row": "પંક્તિમાં ઉમેરો",
    "add_usage": "ઉપયોગ ઉમેરો",
    "notifications": "સૂચના",
    "organisation_details": "સંગઠન વિગતો",
    "profile": "રૂપરેખા",
    "user_access": "વપરાશકર્તા પ્રવેશ",
    "admin": "વહીવટ",
    "shifts": "પાળી",
    "add_pie_TD_widget": "પાઇ ટીડી વિજેટ ઉમેરો",
    "edit_pie_TD_widget": "પાઇ ટીડી વિજેટ સંપાદિત કરો",
    "legend": "દંતકથા",
    "pie": "પાઇ",
    "edit_bar_trend_widget": "બાર વલણ વિજેટમાં ફેરફાર કરો",
    "add_bar_trend_widget": "બાર વલણ વિજેટ ઉમેરો",
    "edit_horizantal_bar_trend_widget": "આડી બાર ટ્રેન્ડ વિજેટમાં ફેરફાર કરો",
    "add_horizantal_bar_trend_widget": "આડી બાર ટ્રેન્ડ વિજેટ ઉમેરો",
    "too_long": "ખૂબ લાંબું",
    "trigger_logs": "લોગ ટ્રિગર",
    "create_trigger": "ટ્રિગર બનાવો",
    "right_click_to_configure": "ગોઠવવા માટે જમણું ક્લિક કરો",
    "width": "પહોળાઈ",
    "height": "heightંચાઈ",
    "hide_value": "છુપાયેલું મૂલ્ય",
    "edit_machine": "સંપાદન યંત્ર",
    "wait_time": "રાહ સમય",
    "production_date": "ઉત્પાદનની તારીખ",
    "connectivity_status": "જોડાણની સ્થિતિ",
    "production_plans": "ઉત્પાદન યોજના",
    "production_pending": "બાકી ઉત્પાદન",
    "pending_shipment": "બાકી શિપમેન્ટ",
    "trace_logs": "ટ્રેસ લ s ગ્સ",
    "movement_events": "ચળવળની ઘટનાઓ",
    "kgco2": "કે.જી., કે.જી.",
    "entropy_threshold": "ષડયંત્ર",
    "threshold_value": "થ્રેશોલ્ડ મૂલ્ય",
    "pending_dispatch": "બાકી રવાનગી",
    "dispatched": "રવાના",
    "dispatch_details": "રવાનગી વિગતો",
    "dispatch": "રવાનગી",
    "raw_materials": "કાચી સામગ્રી",
    "pour_qty": "જથ્થો નાખવો",
    "summary": "સારાંશ",
    "optional": "વૈકલ્પિક",
    "param_mqtt_map": "પરમ એમક્યુટીટી નકશો",
    "machine_status_mqtt_map": "મશીન સ્થિતિ એમક્યુટીટી નકશો",
    "edit_trigger_count_table_widget": "ટ્રિગર કાઉન્ટ ટેબલ વિજેટને સંપાદિત કરો",
    "add_trigger_count_table_widget": "ટ્રિગર કાઉન્ટ ટેબલ વિજેટ ઉમેરો",
    "count": "ગણતરી",
    "real_time_consumption": "વાસ્તવિક સમય વપરાશ",
    "search_view": "શોધ દૃશ્ય",
    "multiple_entries": "બહુવિધ પ્રવેશો",
    "combined_parameter": "સંયુક્ત પરિમાણ",
    "background_color": "પૃષ્ઠભૂમિ રંગ",
    "resize": "પ્રતિકાર કરવો",
    "remove": "દૂર કરવું",
    "select_views": "દૃશ્યો પસંદ કરો",
    "difference": "તફાવત",
    "sum": "રકમ",
    "set_param_type_for_machine": "મશીન માટે પરમ પ્રકાર સેટ કરો",
    "y1axis": "વાય 1 અક્ષ",
    "y2axis": "વાય 2 અક્ષ",
    "temperature_parameters": "તાપમાન પરિમાણો",
    "area_name": "વિસ્તારનું નામ",
    "maintenance_due_on": "ને કારણે જાળવણી",
    "days_left": "દિવસો બાકી",
    "enture_insights": "સાહસ આંતરદૃષ્ટિ",
    "reports": "અહેવાલો",
    "till_date_pie_chart": "આજ સુધી પાઇ ચાર્ટ",
    "till_date_bar_chart": "આજ સુધી બાર ચાર્ટ",
    "tod_billing_widget": "ટોડ બિલિંગ વિજેટ",
    "bar_trend_widget": "પટ્ટી -વિજેટ",
    "target_based_bar_trend_widget": "લક્ષ્યાંક આધારિત બાર -વિજેટ",
    "gauge_widget": "ગેજ વિજેટ",
    "consumption_pie_widget": "વપરાશ -વિજેટ",
    "consumption_gauge_widget": "વપરાશ -ગેજ વિજેટ",
    "angular_gauge_widget": "કોઠાર",
    "table_widget": "કોષ્ટક -વિજેટ",
    "banner_widget": "બેનર વિજેટ",
    "param_equation_banner_widget": "પરમ સમીકરણ બેનર વિજેટ",
    "shift_based_usage_table": "પાળી આધારિત વપરાશ કોષ્ટક",
    "bitwise_word_widget": "બિટવાઇઝ વર્ડ વિજેટ",
    "dynamic_log_widget": "ગતિશીલ લોગ વિજેટ",
    "machine_status_timeline_widget": "મશીન સ્થિતિ સમયરેખા વિજેટ",
    "process_or_machine_status_widget": "પ્રક્રિયા/મશીન સ્થિતિ વિજેટ",
    "parameter_status_based_usage_status": "pareterstatusbasedwidget",
    "parameter_insights_widget": "પરિમાણ આંતરદૃષ્ટિ વિજેટ",
    "heat_map": "ગરમીનો નકશો",
    "usage_score_card_widget": "વપરાશ -સ્કોર કાર્ડ વિજેટ",
    "busbar_live_monitoring": "બસબાર લાઇવ મોનિટરિંગ",
    "bullet_or_angular_guage_widget": "બુલેટ/કોણીય ભાષા વિજેટ",
    "dual_axis_bar_trend_widget": "બેવડી અક્ષ બાર વલણ વિજેટ",
    "trigger_count_table_widget": "ટ્રિગર ગણતરી ટેબલ વિજેટ",
    "bar_trend_td_widget": "બાર વલણ ટી.ડી. વિજેટ",
    "horizantal_bar_trend_widget": "આડા બાર વલણ વિજેટ",
    "shift_timeframed_trends": "શિફ્ટ આધારિત સમય ફ્રેમવાળા વલણો",
    "candlestick_trends": "કેન્ડલસ્ટિક વલણો",
    "free_ram": "Free RAM",
    "item": "Item",
    "item_code": "Item Code",
    "department": "Department",
    "departments": "Departments",
    "issue_vouchers": "Issue Vouchers",
    "issue_voucher": "Issue Voucher",
    "to_be_approved": "To Be Approved",
    "to_be_issued": "To Be Issued",
    "intended": "Intended",
    "approved": "Approved",
    "received": "Received",
    "receiver": "Receiver",
    "material": "material",
    "material_group": "material group",
    "material_desc": "Material Description",
    "uom": "Unit Of Measurement",
    "create_item_code": "Create Item Code",
    "create_department": "Create Department",
    "users": "Users",
    "items": "Items",
    "users_in_department": "Users in Department",
    "authorisers_in_department": "Authorisers in Department",
    "issuers_in_department": "Issuers in Department",
    "item_issuer_list": "Item Issuer List",
    "add_item_issuer": "Add Item Issuer",
    "store": "Store",
    "create_issue_voucher": "Create Issue Voucher",
    "voucher_requests": "Voucher Requests",
    "intended_qty": "Intended Qty",
    "issued_qty": "Issued Qty",
    "issue_items": "Issue Items",
    "voucher_receipt": "Voucher Receipt",
    "add_stock": "Add Stock",
    "attached_targets": "Attached Targets",
    "attach_target": "Attach Target",
    "add_target_to_parameter": "Add Target to Parameter",
    "empty": "Empty",
    "add_day_wise_target": "Add Day wise Target",
    "day_wise_targets": "Day Wise Targets",
    "fetch_targets": "Fetch Targets",

    "day_wise_machine_status_widget": "દિનપ્રતિદિન મશીન સ્ટેટસ વિજેટ",
    "add_day_wise_machine_status_widget": "દિનપ્રતિદિન મશીન સ્ટેટસ વિજેટ ઉમેરો",
    "edit_day_wise_machine_status_widget": "દિનપ્રતિદિન મશીન સ્ટેટસ વિજેટ સંપાદિત કરો",
    "add_machines": "મશીનો ઉમેરો",
    "added_machines": "ઉમેરેલ મશીનો",
    "consumption_start_time":"Consumption Start time",



}