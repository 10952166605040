<template>
    <v-container fluid :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode }">
        <v-card 
            flat 
            :dark="$store.getters.getColorPalette().isDark"
            :background-color="$store.getters.getColorPalette().backgroundColorCode"
        >
            <v-tabs 
                :dark="$store.getters.getColorPalette().isDark"
                :background-color="$store.getters.getColorPalette().backgroundColorCode"
                v-model="tab" 
                show-arrows
            >
                <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
                <v-tab 
                    v-for="(item, index) in list.filter(item => item.condition)" 
                    :key="index" :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().btnborderColorCode"
                >
                    {{ item.title }}
                </v-tab>
            </v-tabs>
            <v-tabs-items 
                v-model="tab" :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-tab-item 
                    v-for="(item, index) in list.filter(item => item.condition)" 
                    :key="index"
                >
                    <v-card 
                        flat :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().backgroundColorCode"
                    >
                        <v-card-text>
                            <component :is="item.component"></component>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>

<script>
import ChecklistDataLists from './checklist/ChecklistDataLists.vue';
import ChecklistConfigurations from './checklist/ChecklistConfigurations.vue';
import ChecklistHistoricalDataList from './checklist/ChecklistHistoricalDataList.vue';
import ChecklistSummary from './checklist/ChecklistSummary.vue';
export default {
    name: 'Checklist',
    components: {
        ChecklistConfigurations,
        ChecklistDataLists,
        ChecklistHistoricalDataList,
        ChecklistSummary
    },
    data() {
        return {
            tab: 0, // Default tab index
            list: [
                {
                    title: "Home",
                    component: 'ChecklistDataLists',
                    condition:true
                },
                {
                    title: "Checklists",
                    component: 'ChecklistHistoricalDataList',
                    condition:true
                },
                {
                    title: "Summary",
                    component: ChecklistSummary,
                    condition:this.$store.state.user && this.$store.getters.getAccessByLevels(['report'])
                },
                {
                    title: "Configurations",
                    component: 'ChecklistConfigurations',
                    condition:this.$store.state.user && this.$store.getters.getAccessByLevels(['engineeraccess'])
                },
            ],
        };
    },
    mounted() {
        if (!this.$store.state.loggedIn) {
            this.$router.push("/");
        }
    },
};
</script>
