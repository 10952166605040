import English from "@/utillities/languages/English";

export default {
  ...English,
  start: "शुरू",
  filling: "भरना",

  accept: "स्वीकार करें",
reject: "अस्वीकार करें",
move_to_next_stage: "अगले चरण पर जाएं",
save_as_draft: "ड्राफ्ट के रूप में सहेजें",
save_and_submit: "सहेजें और सबमिट करें",
closure: "बंद करना",
duration: "अवधि",
refill_request: "रीफिल के लिए अनुरोध",
is_be_cancelled: "रद्द कर दिया जाएगा",
delete_this: "इसे हटाएं",
event: "घटना",

  for: "के लिए",
  of: "का",
  downtime: "डाउनटाइम",

  metrics: "मीट्रिक्स",
  oee_by_day: "दिन के अनुसार OEE",

  ideal_cycle_time: "आदर्श चक्र समय",
  
  minutes: "मिनट",
  production_time: "उत्पादन समय",
  back: "वापस",
  down_times: "डाउन टाइम्स",

  top_5_reasons: "शीर्ष 5 कारण",
  top_5_categories: "शीर्ष 5 श्रेणियाँ",
  enter_apparent_energy_manually: "सप्रकट ऊर्जा को मैन्युअली दर्ज करें",

  oee: "OEE",
  reason: "कारण",
  add_downtime_reason: "डाउनटाइम का कारण जोड़ें",

  default_target: "डिफ़ॉल्ट लक्ष्य",

  enter_in_minutes: "मिनटों में दर्ज करें",
  custome_fields: "कस्टम फ़ील्ड",
  enter_data_in_minutes: "मिनटों में डेटा दर्ज करें",
  enter_data_in_hours: "घंटों में डेटा दर्ज करें",

  create_tod_billing_widget: "टोड बिलिंग विजेट बनाएं",
  edit_tod_billing_widget: "टोड बिलिंग विजेट संपादित करें",
  choose_from_machine_groups: "मशीन समूहों से चुनें",
  tod_billing: "टोड बिलिंग",
  choose: "चुनें",

  create_shift_based_machine_usage_log: "मशीन उपयोग लॉग बनाएं",
  shift_based_machine_usage_log: "मशीन उपयोग लॉग",
  rejected: "अस्वीकृत",
  machine_operator: "मशीन ऑपरेटर",
  production_end_timestamp: "उत्पादन समाप्ति समय",
  production_start_timestamp: "उत्पादन प्रारंभ समय",
  running_time: "संचालन समय",
  idle_time: "निष्क्रिय समय",
  down_time: "डाउन टाइम",

  date_row_index: "तिथि पंक्ति",
  date_position: "तिथि स्थिति",

  safety_incident_sheet: "सुरक्षा घटना पत्र",
  safety_inspection_sheet: "सुरक्षा निरीक्षण पत्र",

  ghg_emission_logs: "जीएचजी उत्सर्जन लॉग",
  add_ghg_emission_data: "जीएचजी उत्सर्जन लॉग जोड़ें",
  ghg_scope_conversion: "जीएचजी स्कोप रूपांतरण",
  create_ghg_scope_conversion: "जीएचजी स्कोप रूपांतरण बनाएं",
  ghg_data_logs: "जीएचजी उत्सर्जन डेटा लॉग",

  choose_inspection_type_optional: "निरीक्षण प्रकार चुनें (वैकल्पिक)",

  supplier_code: "आपूर्तिकर्ता कोड",
  contact: "संपर्क",
  point_of_contact: "संपर्क व्यक्ति",
  quantity_supplied: "आपूर्ति की गई मात्रा",
  create_product: "उत्पाद बनाएं",
  products: "उत्पाद",
  data: "डेटा",
  timezone: "समय क्षेत्र",
  tariffs: "शुल्क",

  days_delta: "दिनों का डेल्टा",
  machine_groups: "मशीन समूह",
  param_groups: "पैरामीटर समूह",
  user_groups: "उपयोगकर्ता समूह",

  topic_prefix: "विषय उपसर्ग",
  message_direction: "संदेश की दिशा",

  safety_inspection: "सुरक्षा निरीक्षण",
  write_inspection_log: "निरीक्षण लॉग लिखें",
  inspection_id: "निरीक्षण आईडी",
  inspector_id: "निरीक्षक आईडी",
  inspection_date: "निरीक्षण तिथि",
  inspection_type: "निरीक्षण प्रकार",
  findings: "खोजें",
  actions_required: "आवश्यक कार्यवाही",
  followup_date: "अनुवर्ती तिथि",
  status: "स्थिति",

  safety_incident: "सुरक्षा घटना",
  write_incident: "घटना लिखें",
  incident_description: "घटना का विवरण",
  severity: "गंभीरता",
  accident: "दुर्घटना",
  accident_with_loss: "हानि के साथ दुर्घटना",
  closed: "बंद",
  corrective_action: "सुधारात्मक कार्रवाई",
  employee_id: "कर्मचारी आईडी",
  apply: "लागू करें",
  choose_location_optional: "स्थान चुनें (वैकल्पिक)",


  create_tariff: "टैरिफ बनाएं",
  default_tariff_val: "डिफ़ॉल्ट टैरिफ मान",
  add_tariff_data: "टैरिफ डेटा जोड़ें",
  edit_tariff: "टैरिफ संपादित करें",
  delete_tariff: "टैरिफ हटाएं",
  tariff_value: "टैरिफ मान",

  machine_status_timeline: "मशीन स्थिति समयरेखा",
  total_duration: "कुल अवधि",
  generate: "उत्पन्न करें",
  remove_machine: "मशीन हटाएं",
  edit_machine_status_timeline_widget: "मशीन स्थिति समयरेखा विजेट संपादित करें",
  add_machine_Status__timeline_widget: "मशीन स्थिति समयरेखा विजेट जोड़ें",
  select_timeframe: "समय सीमा चुनें",
  timeframe_type_shift: "शिफ्ट",
  timeframe_type_custom: "कस्टम",
  timeframe_type_yesterday: "कल",
  edit_report_details: "जानकारी संपादित करें",
  download_current_template: "वर्तमान टेम्पलेट डाउनलोड करें",
  date_column_index: "तारीख स्तंभ (इंडेक्स)",
  select_area: "क्षेत्र चुनें",
  select_shift: "शिफ्ट चुनें",

  dashboard_heading: "डैशबोर्ड",

  production_params: "उत्पादन पैरामीटर",

  no_modules_configured: "कोई मॉड्यूल कॉन्फ़िगर नहीं किया गया",

  dashboard_panel: "डैशबोर्ड पैनल",

  choose_type: "प्रकार चुनें",

  name_is_required: "नाम आवश्यक है",

  description_is_required: " विवरण आवश्यक है",

  required: "आवश्यक",

  you_have_to_select_something: "आपको कुछ सेलेक्ट करना है",

  name_must_be_less_than_30_characters: "नाम 30 अक्षर से कम होना चाहिए",

  name_must_be_less_than_25_characters: "नाम 25 अक्षर से कम होना चाहिए",

  name_must_be_less_than_50_characters: "नाम 50 अक्षर से कम होना चाहिए",

  description_must_be_less_than_35_characters:
    "विवरण 35 अक्षरों से कम होना चाहिए",

  description_must_be_less_than_55_characters:
    "विवरण 55 अक्षरों से कम होना चाहिए",

  number_must_be_less_than_6_digits: "संख्या 6 अंकों से कम होनी चाहिए",

  number_must_be_less_than_8_digits: "संख्या 8 अंकों से कम होनी चाहिए",

  number_must_be_less_than_7_digits: "संख्या 7 अंकों से कम होनी चाहिए",

  panel: "पैनल",

  gauge: "गेज",

  param: "परम",

  machine: "मशीन",

  add_panel_to_dashboard: "डैशबोर्ड में पैनल जोड़ें",

  edit_dashboard_panel: "डैशबोर्ड पैनल संपादित करें",

  param_type: "परम प्रकार",


  workspace: "कार्यस्थान",

  device: "उपकरण",

  name: "नाम",
  language: "भाषा",
  time_duration: "समय अवधि",

  submit: "जमा करना",

  cancel: "रद्द करना",

  close: "बंद करना",

  usage: "प्रयोग",

  peak_value: "अधिकतम संख्या",

  current_data: "मौजूदा डेटा",

  parameter: "पैरामीटर",

  computed_param: "परिकलित परम",

  last_7_days: "पिछले 7 दिन",

  last_30_days: "पिछले 30 दिनों में",

  last_60_days: "पिछले 60 दिन",

  last_90_days: "पिछले 90 दिन",

  last_180_days: "पिछले 180 दिन",

  last_365_days: "पिछले 365 दिन",

  last_1_hour: "अंतिम 1 घंटा",

  last_3_hours: "पिछले 3 घंटे",

  last_6_hours: "पिछले 6 घंटे",

  reorder_views: "दृश्य पुन व्यवस्थित करें",

  create_view: "दृश्य बनाएँ",

  clear_view_name_filter: "क्लियर फ़िल्टर",

  view_type: "प्रकार देखें",

  description: "विवरण",

  table_view: "तालिका दृश्य",

  widget_view: "विजेट दृश्य ",

  dynamic_timeframed_widget_view: "डायनामिक टाइमफ़्रेमयुक्त विजेट दृश्य",

  diagram_view: "आरेख दृश्य",

  energy_usage_view: "ऊर्जा उपयोग दृश्य",

  machine_scroll_view: "मशीन स्क्रॉल दृश्य",

  param_scroll_view: "परम स्क्रॉल दृश्य",

  consolidated_view: "समेकित दृश्य",

  air_quality_view: "वायु गुणवत्ता दृश्य",

  view_auto_change: "स्वतः परिवर्तन देखें",

  no_views_configured: "कोई दृश्य कॉन्फ़िगर नहीं किया गया",

  add_to_dashboard: "डैशबोर्ड में जोड़ें",

  edit_view_name: "नाम देखें संपादित करें",
  edit_view: "नाम देखें संपादित",
  save: " बचाना ",
  discard: "खारिज करना",
  refresh: "पुनः करे",
  adjust: "समायोजित करना",

  create_machine: "मशीन बनाएं",

  add_machine: "मशीन जोड़ें",

  sequence: "अनुक्रम",

  machine_type: "मशीन की तरह",

  physical_machine: "भौतिक मशीन",

  virtual_machine: "आभासी मशीन",

  positive_workspace: "सकारात्मक कार्यक्षेत्र",

  positive_machine: "सकारात्मक मशीन",

  negative_workspace: "नकारात्मक कार्यक्षेत्र",

  negative_machine: "नकारात्मक मशीन",

  label: "Label",

  positive_machine_Ids: "सकारात्मक मशीन आईडी",

  negative_machine_Ids: "नकारात्मक मशीन आईडी",

  trend_analysis_tools: "रुझान विश्लेषण उपकरण",

  energy_consumption_by_machines: "मशीनों द्वारा ऊर्जा की खपत",

  Create_report: "रिपोर्ट बनाएं",

  view_report: "रिपोर्ट देखें",

  tarrif_on: "टैरिफ चालू",

  active_energy: "सक्रिय ऊर्जा",

  apparent_energy: "स्पष्ट ऊर्जा",

  automate_report: "स्वचालित रिपोर्ट",

  add_root_machine: "रूट मशीन जोड़ें",

  add_common_machine: "सामान्य मशीन जोड़ें",

  notification_groups: "अधिसूचना समूह",

  add_machines_to_list: "सूची में मशीनें जोड़ें",

  machine_list: "मशीन सूची",

  data_historian: "डेटा इतिहासकार",

  timeframed_trends: "समयबद्ध रुझान",

  dual_axis_trend_comparison: "दोहरी अक्ष रुझान तुलना",

  granular24_hrs_trend: "ग्रैन्युलर 24 घंटे का रुझान",

  machinewise_trend: "मशीन के अनुसार रुझान",

  basic_statistical_trends: "बुनियादी सांख्यिकीय रुझान",

  log_analysis: "लॉग विश्लेषण",

  aqi_comparison_panel: "AQI तुलना पैनल",

  time_of_day_trend: "दिन के समय का रुझान",

  param_historical_comparisons: "परम ऐतिहासिक तुलना",

  energy_usage_trends: "ऊर्जा उपयोग के रुझान",

  machine_name: "मशीन का नाम",

  param_name: "परम नाम",

  trend_view: "प्रवृत्ति दृश्य",

  raw: "अनिर्मित",

  trend: "रुझान",

  normalised: "सामान्यीकृत",

  from_date: "की तिथि से",

  to_date: "तारीख तक",

  from: "से",

  to: "को",

  hour: "घंटा",

  minute: "मिनट",

  generate_report: "रिपोर्ट बनाओ",

  time_range: "समय सीमा",

  no_data_available: "'कोई डेटा मौजूद नहीं",

  daily: "दैनिक",

  monthly: "महीने के",

  weekly: "साप्ताहिक",
  week: "साप्ताह",

  yearly: "सालाना",

  device_name: "डिवाइस का नाम",

  machine_wise_comparison: "मशीन के अनुसार तुलना",

  stats_detailed_report: "आँकड़े विस्तृत रिपोर्ट",

  log_type: "लॉग प्रकार",

  choose_Log: "लॉग चुनें",

  area: "क्षेत्र",

  aq_device: "एक्यू डिवाइस",

  nebeskie_labs_office: "नेबेस्की लैब्स कार्यालय",

  date_range: "तिथि सीमा",

  this_year: "इस साल",

  "last 6 months": "पिछले 6 महीने",

  "last quater": "आख़िरी तिमाही",

  mtd: "एमटीडी",

  ytd: "YTD",

  yesterday: "कल",

  custom: "पसंद के अनुसार",

  run_report: "रिपोर्ट चलाएं",

  create_report: "रिपोर्ट बनाएं",

  usage_report: "उपयोग रिपोर्ट",

  consolidated_report: "समेकित रिपोर्ट",

  dynamic_excel_report: "डायनामिक एक्सेल रिपोर्ट",

  energy_usage_report: "ऊर्जा उपयोग रिपोर्ट",

  create_action: "क्रिया बनाएं",

  action_type: "क्रिया का प्रकार",

  notification_group: "अधिसूचना समूह",

  address: "पता",

  number_of_registers: "पंजीकृत की संख्या",

  value: "मान",

  send_notification: "अधिसूचना भेजें",

  write_parameter: "पैरामीटर लिखें",

  relays: "रिले",

  relay_1: "रिले 1",

  relay_2: "रिले 2",

  relay_3: "रिले 3",

  relay_4: "रिले 4",

  email_must_be_valid: "ईमेल मान्य होना चाहिए",

  it_should_be_a_number: "यह एक संख्या होनी चाहिए",

  it_should_be_a_valid_number: "यह एक वैध संख्या होनी चाहिए",

  operator: "ऑपरेटर",

  operand: "ओपेरंड",

  target_value: "लक्ष्य मूल्य",

  wait_time_seconds: "प्रतीक्षा समय (सेकंड)",

  greater_than: "से अधिक",

  modulus_greater_than: "मापांक ग्रेटर दैन",

  lesser_than: "के मुकाबले कम",

  modulus_lesser_than: "मापांक से कम",

  equal_to: "के बराबर",

  not_equal_to: "असमान ",

  modulus_equal_to: "मापांक बराबर",

  greater_than_equal_to: "बराबर से भी बड़ा",

  modulus_greater_than_equal_to: "मापांक बराबर से बड़ा",

  lesser_than_equal_to: "बराबर से कम",

  modulus_lesser_than_equal_to: "मापांक बराबर से कम",

  bit_high: "थोड़ा ऊंचा",

  bit_low: "थोड़ा कम",

  it_should_be_a_positive_whole_number: "यह एक धनात्मक पूर्ण संख्या होनी चाहिए",

  it_should_be_a_positive: "यह एक सकारात्मक होना चाहिए",

  create_process: "प्रक्रिया बनाइये",

  edit_process: "प्रक्रिया संपादित करें",

  status_rule: "स्थिति नियम",

  create_process_status_rule: "प्रक्रिया स्थिति नियम बनाएं",

  tag_type: "टैग प्रकार",

  process_status: "प्रक्रिया स्थिति",

  machines: "मशीनों",

  running: "रनिंग",

  idling: "निष्क्रिय",

  offline: "ऑफलाइन",

  no_machines_configured: "कोई मशीन कॉन्फ़िगर नहीं की गई",

  delete_process: "प्रक्रिया हटाएँ",

  add_machine_to_process: "प्रक्रिया में मशीन जोड़ें",

  add: "शामिल करना",

  search: "खोज",

  switch_to: "पर स्विच",

  switch_to_base_org: "बेस ऑर्ग पर स्विच करें",

  switch: "बदलना",

  create_new_organisation: "नया संस्था बनाएं",

  organisation_name: "संस्था का नाम",

  organisation_type: "संस्था का प्रकार",
  country: "देश",
  country_code: "देश कोड",
  state: 'राज्य',

  clear: "स्पष्ट",

  change_organisation: "संस्था बदलें",

  organisation_id: "संस्था आईडी",

  update_your_oem_logo: "अपना OEM लोगो अपडेट करें",

  select_a_logo_to_upload: "अपलोड करने के लिए एक लोगो चुनें",

  submit_logo: "लोगो सबमिट करें",

  create_workspace: "कार्यक्षेत्र बनाएँ",

  hub_id: "हब आईडी",

  hub: "केंद्र",

  name_must_be_less_than_45_characters: "नाम 45 अक्षरों से कम होना चाहिए",

  id_is_required: "आईडी आवश्यक है",

  no_space_allowed: "जगह की अनुमति नहीं",

  id_must_be_less_than_6_characters: "आईडी 6 अक्षरों से कम होनी चाहिए",

  edit_workspace: "कार्यक्षेत्र संपादित करें",

  delete_workspace: "कार्यस्थान हटाएँ",

  add_modbus_device: "मोडबस डिवाइस जोड़ें",

  add_device: "डिवाइस जोडे",

  oops: "उफ़!!",

  ok: "ठीक है",

  slave_id: "स्लेव आईडी",

  device_type: "उपकरण का प्रकार",

  connection_type: "संपर्क का प्रकार",

  mGate: "एमगेट",

  make: "बनाना",

  model: "नमूना",

  name_must_be_less_than_33_characters: "नाम 33 अक्षरों से कम होना चाहिए",

  name_must_be_less_than_11_characters: "नाम 11 अक्षर से कम होना चाहिए",

  it_should_be_a_valid_slave_id: "यह एक वैध स्लेव आईडी होनी चाहिए",

  name_must_be_less_than_4_digits: "नाम 4 अंकों से कम होना चाहिए",

  modbus_tcp: "मोडबस टीसीपी",

  modbus_rtu: "मोडबस आरटीयू",

  add_virtual_device: "वर्चुअल डिवाइस जोड़ें",

  add_mqtt_device: "MQtt डिवाइस जोड़ें",

  topic: "विषय",

  identifier: "पहचानकर्ता",

  identifier_value: "पहचानकर्ता मान",

  name_must_be_less_than_20_characters: "नाम 20 अक्षर से कम होना चाहिए",

  no_special_characters_or_space_allowed:
    "किसी विशेष वर्ण या स्थान की अनुमति नहीं है",

  must_be_less_than_15_characters: "15 अक्षर से कम होना चाहिए",

  create_role: "भूमिका बनाएँ",

  role: "भूमिका",

  role_is_required: "भूमिका आवश्यक है",

  role_must_be_less_than_40_characters: "भूमिका 40 वर्णों से कम होनी चाहिए",

  role_should_not_contain_white_characters:
    "भूमिका में सफ़ेद अक्षर नहीं होने चाहिए",

  comparison: "तुलना",

  historical_time_of_day_comparison_parameter:
    "इतिहास दिन के समय की तुलना - पैरामीटर",

  device_parameter: "डिवाइस पैरामीटर",

  date_1: "दिनांक 1",

  date_2: "दिनांक 2",

  tod_comparison_report: "टीओडी तुलना रिपोर्ट",

  time_frame: "निर्धारित समय - सीमा",

  download_table: "तालिका डाउनलोड करें",

  table: "तालिका",

  showMinMax_values: "न्यूनतम और अधिकतम मान दिखाएँ",

  usage_or_average: "उपयोग या औसत",

  min_value: "न्यूनतम मान",

  last_12_weeks: "पिछले 12 सप्ताह",

  last_6_months: "पिछले 6 महीने",

  last_12_months: "पिछले 12 महीने",

  year_5: "साल 5",

  machinewise_comparison: "मशीनवार तुलना",

  timeFrame: "निर्धारित समय - सीमा",

  it_should_be_a_date: "यह एक तारीख होनी चाहिए",

  time_of_day_parameter: "दिन का समय- पैरामीटर",

  tod_report: "टीओडी - रिपोर्ट",

  in_this_timeframe: "इस समय सीमा में",

  average: "औसत",

  maximum: "अधिकतम",

  minimum: "न्यूनतम",

  energy_usage_trend: "ऊर्जा उपयोग की प्रवृत्ति",

  standard_deviation: "मानक विचलन",

  statistical_mode: "सांख्यिकीय मोड",

  production_logs: "उत्पादन लॉग",

  capacity_logs: "क्षमता लॉग",

  dynamic_logs: "गतिशील लॉग",

  pollutants: "प्रदूषण",

  group_by: "द्वारा समूह बनाएं",

  generate_grouped_trends: "समूहीकृत रुझान उत्पन्न करें",

  tCo2: "tCo2",

  scope_1: "स्कोप 1",

  scope_2: "स्कोप 2",

  scope_3: "स्कोप 3",

  source: "स्रोत",

  type: "प्रकार",

  user_access_log: "उपयोगकर्ता पहुँच लॉग",

  user_logs: "उपयोगकर्ता लॉग",

  fetch_logs: "लॉग प्राप्त करें",

  update_profile: "प्रोफ़ाइल अपडेट करें",

  lang: "लैंग",

  theme: "विषयवस्तु",

  phone: "फ़ोन",

  update: "नवीनतम बनाये",

  update_password: "पासवर्ड अपडेट करें",

  change_password: "पासवर्ड बदलें",

  old_password: "पुराना पासवर्ड",

  new_password: "नया पासवर्ड",

  confirm_password: "पासवर्ड की पुष्टि कीजिये",

  password_must_be_more_than_8_characters: "पासवर्ड 8 अक्षर से अधिक होना चाहिए",

  passwords_do_not_match: "सांकेतिक शब्द मेल नहीं खाते",

  add_hub: "हब शामिल करें",

  id_must_be_less_than_4_characters: "आईडी 4 अक्षरों से कम होनी चाहिए",

  add_maintainer: "अनुरक्षक जोड़ें",

  add_parent_org: "अभिभावक संस्था जोड़ें",

  add_parent_to_organisation: "अभिभावक को संस्था में जोड़ें",

  parent_org_id: "मूल संस्था आईडी",

  add_maintainer_to_organization: "संस्था में अनुरक्षक जोड़ें",

  maintainer_id: "अनुरक्षक आईडी",

  update_organisation_details: "संस्था विवरण अपडेट करें",

  update_your_organization_details: "अपने संस्था का विवरण अपडेट करें",

  default_email: "डिफ़ॉल्ट ईमेल",

  picker_in_menu: "मेनू में पिकर",

  param_diagnostics: "परम डायग्नोस्टिक्स",

  show_logo: "लोगो दिखाएँ",

  show_oem_logo: "OEM लोगो दिखाएँ",

  organisation_alias: "संस्था उपनाम",

  update_organisation_alias: "संस्था उपनाम अपडेट करें",

  update_org_alias: "संस्था उपनाम अपडेट करें",

  update_your_organization_logo: "अपने संस्था का लोगो अपडेट करें",

  select_a_logo: "एक लोगो चुनें",

  update_logo: "लोगो अपडेट करें",

  create_uam_user: "UAM उपयोगकर्ता बनाएँ",

  overview: "अवलोकन",

  batches: "बैच",

  track_batch: "ट्रैक बैच",

  batch_movement: "बैच गति",

  production_planner: "उत्पादन योजनाकार",

  show_unshipped_only: "केवल अनशिप किया गया दिखाएँ",

  view_logs: "लॉग्स को देखें",

  params: "पैरामीटर",

  add_filter: "फ़िल्टर शामिल करें",

  filters: "फिल्टर",

  batch_id: "बैच_आईडी",

  show_details: "प्रदर्शन का विवरण",

  tracing_logs: "ट्रेसिंग लॉग",

  quantity: "मात्रा",

  location_event_type: "स्थान इवेंट प्रकार",

  fetch_plans: "योजनाएँ प्राप्त करें",

  sku: "SKU",

  code: "कोड",

  add_production_plans: "उत्पादन योजनाएँ जोड़ें",

  date: "तारीख",

  from_time: "समय से",

  to_time: "समय पर",

  activities: "गतिविधियाँ",

  task_name: "कार्य का नाम",

  task_details: "कार्य विवरण",

  start_date: "आरंभ करने की तिथि",

  end_date: "अंतिम तिथि",

  assignee: "संपत्ति-भागी",

  manager: "प्रबंधक",

  activity: "गतिविधि",

  activity_type: "क्रिया के प्रकार",

  escalation_matrix: "मैट्रिक्स की वृद्धि",

  maintenance_activity: "रखरखाव गतिविधि",

  em_level: "ईएम स्तर",

  delay: "देरी",

  user_group: "यूजर ग्रुप",

  mins_5: "5 मिनट",

  mins_10: "10 मिनटों",

  mins_15: "15 मिनटों",

  mins_30: "30 मिनट",

  hour_1: "1 घंटा",

  hour_6: "6 घंटे",

  day_1: "1 दिन",
  day: "दिन",

  day_3: "3 दिन",

  insights: "इनसाइट्स",

  create_emailer_group: "ईमेलर ग्रुप बनाएं",

  notification_type: "जानकारी प्रकार",

  email: "ईमेल",

  sms: "एसएमएस",

  create_machine_group: "मशीन समूह बनाएं",

  create_param_group: "परम ग्रुप बनाएं ",

  create_user_group: "उपयोगकर्ता समूह बनाएं",

  create_module: "मॉड्यूल बनाएं",

  busbar_temp_module: "बसबार तापमान मॉड्यूल",

  power_quality_module: "विद्युत गुणवत्ता मॉड्यूल",

  esg_module: "ईएसजी मॉड्यूल",

  edge_hub: "एज हब",

  tolerance: "टॉलरेंस",

  ambient_temp_input_type: "परिवेश तापमान इनपुट प्रकार",

  ambient_temp: "व्यापक तापमान",

  workspace_ambient_temp: "कार्यस्थल परिवेश तापमान",

  device_ambient_temp: "डिवाइस परिवेश तापमान",

  r_temperature: "R तापमान",

  y_temperature: "Y तापमान",

  b_temperature: "B तापमान",

  n_temperature: "N तापमान",

  rated_current: "वर्तमान मूल्यांकित",

  no_load_current: "अभी कोई बोझ नहीं",

  r_current: "R धारा",

  y_current: "Y धारा",

  b_current: "B धारा",

  n_current: "N धारा",

  delete_activity: "गतिविधि हटाएँ",

  substation_voltage_kv: "सबस्टेशन वोल्टेज (केवी)",

  transformer_rating_mva: "ट्रांसफार्मर रेटिंग (एमवीए)",

  percentage_impedance_of_the_transformer: "ट्रांसफार्मर का % प्रतिबाधा",

  voltage_rv_y: "वोल्टेज RV(Y)",

  voltage_yb_v: "वोल्टेज YB(V)",

  voltage_br_v: "वोल्टेज BR(V)",

  current_r_a: "धारा R(A)",

  current_y_a: "धारा Y(A)",

  current_b_a: "धारा B(A)",

  thd_i: "THD I",

  thd_v: "THD V",

  thd_i1: "THD I1",

  active_power_kw: "सक्रिय पावर (kw)",

  apparent_power_kva: "प्रत्यक्ष पावर (KVA)",

  reactive_power_kvar: "रिएक्टिव पावर (KVAR)",

  active_energy_kwh: "सक्रिय ऊर्जा (KWH)",

  apparent_energy_kvah: "स्पष्ट ऊर्जा(KVAH)",

  pf: "पीएफ",

  text: "मूलपाठ",

  energy_source: "ऊर्जा स्रोत",

  scope: "दायरा",

  system_logs: "सिस्टम लॉग",

  system_logbook: "सिस्टम लॉग बुक",

  esg_data_log: "ईएसजी डेटा लॉग",

  add_esg_data: "ईएसजी डेटा जोड़ें",

  add_esg_data_log: "ईएसजी डेटा लॉग जोड़ें",

  source_type: "स्रोत प्रकार",

  unit_of_measurement: "माप की इकाई",

  from_date_optional: "दिनांक से (वैकल्पिक)",

  "emission_factor": "उत्सर्जन कारक (kgCO2)",

  create_dynamic_logger: "डायनामिक लॉगर बनाएं",

  dynamic_log: "गतिशील लॉग",

  primary_field_label: "प्राथमिक फ़ील्ड लेबल",

  primary_field_type: "प्राथमिक फ़ील्ड प्रकार",

  primary: "प्राथमिक",

  additional_fields: "अतिरिक्त फ़ील्ड",

  field_label: "फील्ड लेबल",

  field_type: "क्षेत्र प्रकार",

  choose_list: "सूची चुनें",

  list: "सूची",

  create_capacity_logger: "क्षमता लोगर बनाएँ",

  capacity_log: "क्षमता लॉग",

  create: "बनाएं",

  create_production_logger: "प्रोडक्शन लॉगर बनाएं",

  production_log: "उत्पादन लॉग",

  production_logger: "उत्पादन लोगर",

  input_type: "इनपुट का प्रकार",

  input: "इनपुट",

  input_name: "इनपुट नाम",

  output_type: "आउटपुट का प्रकार",

  output: "आउटपुट",

  output_name: "आउटपुट नाम",

  output_params: "आउटपुट पैरामीटर्स",

  compute_production: "उत्पादन की गणना करें",

  compute_energy: "ऊर्जा की गणना करें",

  energy_param_type: "ऊर्जा परम प्रकार",

  energy_device: "ऊर्जा उपकरण",

  energy_param: "ऊर्जा परम",

  production_operator: "उत्पादन संचालक",

  production_correction_factor: "उत्पादन सुधार कारक",

  output_operator: "आउटपुट ऑपरेटर",

  output_correction_factor: "आउटपुट सुधार कारक",

  input_operator: "इनपुट ऑपरेटर",

  input_correction_factor: "आउटपुट सुधार कारक",

  energy_unit: "ऊर्जा इकाई",

  production_unit: "उत्पादन इकाई",

  production: "उत्पादन",

  create_data_logger: "डेटा लॉगर बनाएं",

  data_log: "डेटा लॉग",

  add_param: "परम शामिल करें",

  param_id: "परम आईडी",

  create_batch_production_logger: "बैच प्रोडक्शन लॉगर बनाएं",

  batch_tracing_log: "बैच ट्रेसिंग लॉग",

  log_name: "लॉग नाम",

  location: "जगह",

  info: "जानकारी",

  remarks: "टिप्पणी",
  code_will_be_available_to_log_by_default:
    "कोड डिफ़ॉल्ट रूप से लॉग इन करने के लिए उपलब्ध होगा",
  fields: "जगह",
  additional_info: "अतिरिक्त जानकारी",
  action: "कार्रवाई",
  report_type: "आख्या की प्रकार",
  create_parameter_report: "पैरामीटर रिपोर्ट बनाएं",
  data_processing: "डाटा प्रासेसिंग",
  addition: "जोड़ना",
  singular: "विलक्षण",
  report: "प्रतिवेदन",
  energy_parameters_kwh: "ऊर्जा पैरामीटर्स (KWH)",
  apparent_energy_KVAh: "स्पष्ट ऊर्जा KVAh",
  maximum_demand_MD: "अधिकतम मांग (एमडी)",
  excel_frequency: "एक्सेल फ्रीक्वेंसी",
  multiplier: "गुणक",
  single: "अकेला",
  incomers: "प्रवेश करनेवाला",
  sections: "खंड",
  energy_distribution_report: "ऊर्जा वितरण रिपोर्ट",
  root_machine: "Root मशीन",
  tariff_on: "Tarrif on",
  add_root_common_machine: "रूट और सामान्य मशीन जोड़ें",
  common_machine: "सामान्य मशीन",
  energy_bill_distribution_report: "ऊर्जा बिल वितरण रिपोर्ट",
  create_consolidated: "समेकित बनाएं",
  choose_report: "रिपोर्ट चुनें",
  id: "पहचान",
  timeframed_sec: "समय सीमा सेक",
  create_dynamic_excel_report: "डायनामिक एक्सेल रिपोर्ट बनाएं",
  update_dynamic_excel_report: "डायनामिक एक्सेल रिपोर्ट अपडेट करें",
  add_sheet: "शीट जोड़ें",
  sheet_name: "शीट का नाम",
  sheet: "शीट",
  sheets: "शीट",
  esg_scope_logging: "ईएसजी स्कोप लॉगिंग",
  targets: "लक्ष्यों को",
  sheet_id: "शीट ID",
  delete_sheet: "Delete शीट",
  report_name: "रिपोर्ट का नाम",
  update_details: "अपडेट विवरण",
  unit: "इकाई",
  parameters: "पैरामीटर",
  send_excel_file: "एक्सेल फ़ाइल भेजें",
  update_excel_template_based_report:
    "एक्सेल टेम्पलेट आधारित रिपोर्ट अपडेट करें",
  create_excel_template_based_report: "एक्सेल टेम्पलेट आधारित रिपोर्ट बनाएं",
  upload_excel_template_xlsx: "एक्सेल टेम्पलेट अपलोड करें (xlsx)",
  param_equation: "परम समीकरण",
  edit_sheets: "शीट संपादित करें",
  row_no: "पंक्ति संख्या",
  column_no: "कॉलम संख्या",
  row_number: "पंक्ति संख्या",
  colummn_number: "कॉलम संख्या",
  no_sheets_added: "कोई शीट नहीं जोड़ी गई",
  process_based_usage_sheet: "प्रक्रिया आधारित उपयोग शीट",
  process: "प्रक्रिया",
  process_label: "प्रक्रिया लेबल",
  add_tag_types: "टैग प्रकार जोड़ें",
  add_process_to_list: "सूची में प्रक्रिया जोड़ें",
  machine_tag_based_daily_data_sheet: "मशीन टैग आधारित दैनिक डेटा शीट",
  machine_label: "मशीन लेबल",
  add_machine_to_list: "सूची में मशीन जोड़ें",
  add_tag_type_to_list: "सूची में टैग प्रकार जोड़ें",
  shift_based_usage_sheet: "शिफ्ट आधारित उपयोग शीट",
  shift: "शिफ्ट ",
  transpose: "स्थानांतरित करना",
  process_based_timeframed_data_sheet:
    "प्रक्रिया आधारित समय-सीमा वाली डेटा शीट",
  add_pocess_to_list: "सूची में प्रक्रिया जोड़ें",
  tag_types: "टैग प्रकार",
  " consolidated_shift_based_usage_data_sheet":
    " समेकित शिफ्ट आधारित उपयोग डेटा शीट",
  date_format: "तारिख का प्रारूप",
  include_process_total: "प्रक्रिया कुल शामिल करें",
  machine_group_based_usage_sheet: "मशीन समूह आधारित उपयोग शीट",
  machine_group_label: "मशीन समूह लेबल",
  machine_group: "मशीन समूह",
  add_machine_group_to_list: "सूची में मशीन समूह जोड़ें",
  parameter_based_usage_data_sheet: "पैरामीटर आधारित उपयोग डेटा शीट",
  "show_month_start&end_data": "माह प्रारंभ और समाप्ति डेटा दिखाएँ",
  statistics: "आंकड़े",
  add_param_to_list: "परम को सूची में जोड़ें",
  parameter_group_based_timeframed: "पैरामीटर समूह आधारित समय-सीमा",
  param_group_label: "परम ग्रुप लेबल",
  param_group: "परम ग्रुप",
  add_param_group_to_list: "परम समूह को सूची में जोड़ें",
  dynamic_logger_based_data_sheet: "डायनामिक लॉगर आधारित डेटा शीट",
  dynamic_log_label: "गतिशील लॉग लेबल",
  parameter_equation_based_sheet: "पैरामीटर समीकरण आधारित शीट",
  data_source: "डेटा स्रोत",
  equation_label: "समीकरण लेबल",
  add_equation_to_list: "सूची में समीकरण जोड़ें",
  absolute_values: "सम्पूर्ण मान",
  consumption_based_sheet: "उपभोग आधारित शीट",
  parameter_name: "मापदण्ड नाम",
  statistical_analysis_based_sheet: "सांख्यिकीय विश्लेषण आधारित शीट",
  create_sheet_column: "शीट कॉलम बनाएं",
  column_label: "कॉलम लेबल",
  machine_id: "मशीन ID",
  create_machine_list: "मशीन सूची बनाएं",
  create_sheets: "शीट्स बनाएं",
  create_shift: "शिफ्ट बनाएं",
  description_optional: "विवरण (वैकल्पिक)",
  create_sku: "SKU बनाएं",
  create_location: "स्थान बनाएँ",
  location_type: "स्थान प्रकार",
  from_location: "स्थान से",
  to_location: "स्थान के लिए",
  event_type: "घटना प्रकार",
  create_location_event_type: "स्थान इवेंट प्रकार बनाएं",
  create_list: "सूची बनाएं",
  create_param_equation: "परम समीकरण बनाएं",
  positive_params: "सकारात्मक पैरामीटर",
  params_to_be_substracted: "घटाए जाने वाले पैरामीटर",
  create_combo_param: "कॉम्बो परम बनाएं",
  inter_param_operator: "इंटर परम ऑपरेटर",
  correction_operator: "सुधार संचालक",
  correction_operand: "सुधार संकार्य",
  create_calculated_param: "परिकलित परम बनाएं",
  calculated_param: "परिकलित परम",
  correction_factor: "सुधार कारक",
  param1_type: "परम 1 प्रकार",
  param1: "परम 1",
  param1_operator: "परम 1 ऑपरेटर",
  param1_operand: "परम 1 संकार्य",
  param2_type: "परम 2 प्रकार",
  param2: "परम 2",
  param2_operator: "परम 2 ऑपरेटर",
  param2_operand: "परम 2 संकार्य",
  create_esg_scope_conversion: "ईएसजी स्कोप रूपांतरण बनाएं",
  category: "वर्ग",
  get_from_param: "परम से प्राप्त करें",
  choose_param: "परम चुनें",
  create_target: "लक्ष्य बनाएं",
  create_word: "शब्द बनाएं",
  words: "शब्द",
  choose_OEM: "OEM चुनें",
  get_the_models: "मॉडल प्राप्त करें",
  get_oem_devices: "OEM डिवाइस प्राप्त करें",
  oem_devices: "ओईएम उपकरण",
  model_number: "मॉडल संख्या",
  register: "पंजीकरण करे",
  add_modbus_gateway: "मोडबस गेटवे जोड़ें",
  " add_modbus_TCP_gateway": " मॉडबस टीसीपी गेटवे जोड़ें",
  IPAddress: "आईपी पता",
  port_number: "पोर्ट नंबर",
  HubID: "हब आईडी",
  baud_rate: "बॉड दर",
  parity: "समानता",
  stop_bit: "स्टॉप बिट",
  update_wifi: "वाईफ़ाई अपडेट करें",
  SSID: "SSID",
  password: "पासवर्ड",
  restart_enture: "पुनः आरंभ करें",
  force_update_configs: "बलपूर्वक अद्यतन कॉन्फ़िगरेशन",
  activity_name: "गतिविधि का नाम",
  board: "बोर्ड",
  calender: "कैलेंडर",
  maintenance: "रखरखाव",
  welcome: "स्वागत",
  logout: "लॉग आउट",
  help: "मदद",
  accounts: "खाता",
  organisation: "संस्था",
  pointer_color_optional: "सूचक रंग (वैकल्पिक)",
  user_id: "उपयोगकर्ता पहचान",
  confirm_new_password: "नए पासवर्ड की पुष्टि करें",
  service: "सर्विस",
  send_grid: "ग्रिड भेजें",
  default_enture_view: "डिफ़ॉल्ट enture दृश्य",
  permissions: "अनुमतियां",
  areas: "क्षेत्रों",
  remark: "टिप्पणी",
  days: "दिन",
  "t4_days": "4-दिन",
  yesterdays_aqi: "कल का AQI",
  aqi: "AQI",
  choose_air_quality_device: "वायु गुणवत्ता उपकरण चुनें",
  delete_this_device: "इस डिवाइस को हटाएँ",
  air_quality_label: "वायु गुणवत्ता लेबल",
  humidity: "नमी",
  temperature: "तापमान",
  watcher: "पहरेदार",
  area_reference: "क्षेत्र संदर्भ",
  write_data: "डेटा लिखें",
  day_of_week: "सप्ताह का दिन",
  month: "महीना",
  diagnostics_notification_groups: "निदान अधिसूचना समूह",
  hub_name: "हब का नाम",
  hub_model: "हब मॉडल",
  hub_type: "हब प्रकार",
  update_your_MQTT_bridge_config:
    "अपना एमक्यूटीटी ब्रिज कॉन्फ़िगरेशन अपडेट करें",
  MQTT_bridge_mode: "एमक्यूटीटी ब्रिज मोड",
  broker_address: "ब्रोकर का पता",
  update_your_OPC_config: "अपना ओपीसी कॉन्फिगरेशन अपडेट करें",
  OPC_client_mode: "ओपीसी क्लाइंट मोड",
  OPC_server_URL: "ओपीसी सर्वर यूआरएल",
  user_auth: "उपयोगकर्ता प्रमाणीकरण",
  mode: "मोड",
  security_policy: "सुरक्षा नीति",
  Update_your_Edge_Hub_restart_Schedule:
    "अपने एज हब रीस्टार्ट शेड्यूल को अपडेट करें",
  auto_restart: "स्वतः पुनः आरंभ",
  time_24Hrs: "समय (24 घंटे)",
  modbus_ping_latency: "मोडबस पिंग विलंबता",
  modbus_unhealthy_wait: "मॉडबस अस्वस्थ प्रतीक्षा करें",
  edit_written_log_book: "लिखित लॉग बुक संपादित करें",
  field_cant_change: "यह फ़ील्ड परिवर्तित नहीं हो सकती",
  re_write: "पुनर्लेखन",
  write_in_logbook: "लॉगबुक में लिखें",
  write: "लिखे",
  download_template: "टेम्पलेट डाउनलोड करें",
  write_in_logbook_csv: "csv द्वारा लॉग बुक में लिखें",
  upload_file: "फ़ाइल अपलोड करें",
  batch: "बैच",
  move_batch: "बैच ले जाएँ",
  on: "शुरू",
  off: "बंद",
  fast_mode: "फ़ास्ट मोड",
  batch_shipping_details: "बैच शिपिंग विवरण",
  vehicle_no: "वाहन नं",
  net_weight: "शुद्ध वजन",
  actual_weight: "वास्तविक वजन",
  vendor: "विक्रेता",
  loaded_by: "द्वारा लोड किया गया",
  verified_by: "के द्वारा सत्यापित",
  document_no: "दस्तावेज़ संख्या",
  multiple_machine_param: "एकाधिक मशीन और पैरामीटर",
  map_paramid_paramequation_to_row:
    "परम आईडी या परम समीकरणों को पंक्ति में मैप करें",
  add_current_date_to_sheet: "शीट में वर्तमान दिनांक जोड़ें",
  create_parameter_from_template: "टेम्पलेट से पैरामीटर बनाएं",
  selected_parameters_count: "चयनित पैरामीटर गणना",
  restricted_user_access: "प्रतिबंधित उपयोगकर्ता पहुंच",
  select_all_parameters: "सभी पैरामीटर्स का चयन करें",
  edit_angular_gauge_widget: "कोणीय गेज विजेट संपादित करें",
  add_angular_gauge_widget: "कोणीय गेज विजेट जोड़ें",
  widget_label: "विजेट लेबल",
  widget_width: "विजेट की चौड़ाई",
  widget_height: "विजेट ऊंचाई",
  " param_label": "परम लेबल",
  choose_parameter: "पैरामीटर चुनें",
  processing: "प्रोसेसिंग",
  edit_banner_widget: "बैनर विजेट संपादित करें",
  add_banner_widget: "बैनर विजेट जोड़ें",
  edit_bartd_widget: "बार टीडी विजेट संपादित करें",
  add_bar_widget: "बार विजेट जोड़ें",
  yaxis_label: "Y - एक्सिस लेबल",
  xaxis_label: "X - एक्सिस लेबल",
  y1axis_label: "Y1 - अक्ष लेबल",
  y2axis_label: "Y2 - अक्ष लेबल",
  "y1axis": "y1 अक्ष",
  "y2axis": "y2 अक्ष",
  edit_dual_axis_bar_trend_widget: "दोहरी अक्ष बार ट्रेंड विजेट संपादित करें",
  add_dual_axis_bar_trend_widget: "दोहरी अक्ष बार ट्रेंड विजेट जोड़ें",
  y1_graph_type: "Y1 अक्ष ग्राफ प्रकार",
  y2_graph_type: "Y2 अक्ष ग्राफ प्रकार",
  idealValue: "आदर्श मूल्य",
  idealLabel: "आदर्श लेबल",
  add_parameters: "पैरामीटर्स जोड़ें",
  add_equation: "समीकरण जोड़ें",
  add_params: "पैराम्स जोड़ें",
  enter_of_machines: "मशीनों की संख्या दर्ज करें",
  edit_bar_trend_td_widget: "बार ट्रेंड टीडी विजेट संपादित करें",
  add_bar_trend_td_widget: "बार ट्रेंड टीडी विजेट जोड़ें",
  graph_type: "ग्राफ़ प्रकार",
  aggregation: "एकत्रीकरण",
  default_graph_type: "डिफ़ॉल्ट ग्राफ़ प्रकार",
  bar_mode: "बार मोड",
  show_ideal_line: "आदर्श रेखा दिखाएँ",
  equations: "समीकरण",
  edit_bitwise_word_widget: "बिटवाइज वर्ड विजेट संपादित करें",
  add_bitwise_word_widget: "बिटवाइज वर्ड विजेट जोड़ें",
  show_bit_status: "बिट स्थिति दिखाएं",
  edit_bullet_gauge_widget: "गोली गेज़ विजेट संपादित करें",
  add_bullet_gauge_widget: "गोली गेज़ विजेट जोड़ें",
  reference_value_for: "संदर्भ मूल्य के लिए",
  gauge_type: "गेज़ प्रकार",
  indicator_color: "संकेतक रंग",
  busbar_temperature_graph_widget: "बसबार तापमान ग्राफ़ विजेट",
  busbar_module: "बसबार मॉड्यूल",
  edit_consumption_gauge_widget: "खपत गेज़ विजेट संपादित करें",
  add_consumption_gauge_widget: "खपत गेज़ विजेट जोड़ें",
  linearGauge: "रैखिक गेज़",
  show_target: "लक्ष्य दिखाएं",
  healthy_on_below_target: "लक्ष्य के नीचे स्वस्थ",
  edit_consumption_pie_widget: "खपत पाई विजेट संपादित करें",
  add_consumption_pie_widget: "खपत पाई विजेट जोड़ें",
  add_color_break_point: "रंग ब्रेक पॉइंट जोड़ें",
  custom_color: "कस्टम रंग",
  color: "रंग",
  edit_heat_map_widget: "हीट मैप विजेट संपादित करें",
  add_heat_map__widget: "हीट मैप विजेट जोड़ें",
  choose_equation: "समीकरण चुनें",
  label_star: "लेबल *",
  color_star: "रंग *",
  operator1_star: "ऑपरेटर 1 *",
  value1_star: "मान 1 *",
  operator2_star: "ऑपरेटर 2 *",
  actions: "क्रियाएँ",
  condition: "शर्तें",
  status_color: "स्थिति का रंग",
  text_view: "पाठ दृश्य",
  edit_process_Status_widget: "प्रक्रिया की स्थिति विजेट संपादित करें",
  add_process_Status_widget: "प्रक्रिया की स्थिति विजेट जोड़ें",
  edit_machine_Status_widget: "मशीन की स्थिति विजेट संपादित करें",
  add_machine_Status_widget: "मशीन की स्थिति विजेट जोड़ें",
  choose_process: "प्रक्रिया चुनें",
  choose_machine: "मशीन चुनें",
  table_header_label: "टेबल हेडर लेबल",
  edit_table_TD_widget: "टेबल टीडी विजेट संपादित करें",
  add_table_TD_widget: "टेबल टीडी विजेट जोड़ें",
  configure_table: "टेबल कॉन्फ़िगर करें",
  edit_target_based_trend_widget: "लक्ष्य आधारित प्रवृत्ति विजेट संपादित करें",
  add_target_based_trend_widget: "लक्ष्य आधारित प्रवृत्ति विजेट जोड़ें",
  target: "लक्ष्य",
  target_line_label: "लक्ष्य रेखा लेबल",
  edit_usage_score_card: "उपयोग स्कोरकार्ड विजेट संपादित करें",
  add_usage_score_card: "उपयोग स्कोरकार्ड विजेट जोड़ें",
  compare_to: "के साथ तुलना करें",
  assign_color: "रंग निर्धारित करें",
  enter: "दर्ज करें",
  gauge_to_dashboard: "डैशबोर्ड के लिए गेज़",
  widget_types: "विजेट प्रकार",
  from_value: "मूल्य से",
  to_value: "मूल्य तक",
  machine_status: "मशीन स्थिति",
  deviceA_type: "डिवाइस ए के प्रकार",
  templates: "टेम्पलेट्स",
  add_triggers_to_parameter: "पैरामीटर में ट्रिगर्स जोड़ें",
  triggers: "ट्रिगर्स",
  show_filter: "फिल्टर दिखाएं",
  view_id: "दृश्य आईडी",
  move_up: "ऊपर जाएं",
  move_down: "नीचे जाएं",
  multiplier_types: "गुणाकार प्रकार",
  energy_report: "ऊर्जा रिपोर्ट",
  add_machine_param: "मशीन पैरामीटर जोड़ें",
  aliasing: "अलायसिंग",
  no_zero_read: "शून्य पढ़ें नहीं",
  remove_negative_entropy: "नकारात्मक एंट्रोपी हटाएं",
  calculate_consumption: "खपत की गणना करें",
  disabled: "अक्षम",
  limiter_value: "सीमा मूल्य",
  machine_scroll_view_page: "मशीन स्क्रॉल व्यू पृष्ठ",
  create_mannual_parameter: "मैन्युअल पैरामीटर बनाएं",
  restrict_user_access: "उपयोगकर्ता पहुंच प्रतिबंधित करें",
  add_device_parameter: "डिवाइस पैरामीटर जोड़ें",
  entropy: "एंट्रोपी",
  idle_latency: "निष्क्रिय लैटेंसी",
  add_OPC_parameter: "OPC पैरामीटर जोड़ें",
  node_id: "नोड आईडी",
  byte_format: "बाइट स्वरूप",
  param_scroll_view_page: "पैरामीटर स्क्रॉल व्यू पृष्ठ",
  timeframed_sec_report: "समय-फ्रेम्ड SEC रिपोर्ट",
  prod_unit: "उत्पाद इकाई",
  SEC_unit: "SEC इकाई",
  energy_parameters: "ऊर्जा पैरामीटर",
  prod_param_type: "उत्पाद पैरामीटर प्रकार",
  production_parameters: "उत्पादन पैरामीटर",
  no_of_intervals: "अंतरालों की संख्या",
  granular_data: "कणवाला डेटा",
  csv: "सीएसवी",
  delete_rule: "नियम हटाएं",
  enable_disable_all_parameters: "सभी पैरामीटरों को सक्षम/अक्षम करें",
  disable: "अक्षम करें",
  enable: "सक्षम करें",
  edit_computed_parameter: "संगणित पैरामीटर संपादित करें",
  quantization: "माप",
  command: "कमांड",
  register_size: "रजिस्टर का आकार",
  consumer_ID: "उपभोक्ता आईडी",
  site_id: "साइट आईडी",
  get_data: "डेटा प्राप्त करें",
  update_param_type: "पैरामीटर प्रकार को अपडेट करें",
  write_in_data_logbook: "डेटा लॉगबुक में लिखें",
  daily_data: "दैनिक डेटा",
  log_entry: "लॉग प्रविष्टि",
  enable_Scroll_mode: "स्क्रॉल मोड सक्षम करें",
  multiple_areas: "एकाधिक क्षेत्र",
  time_of_day: "दिन का समय",
  capacity_logger: "क्षमता लॉगर",
  provider: "प्रदाता",
  total_sites_received_bills_MTD: "महीने के बिल प्राप्त साइटों की कुल संख्या",
  sites_yet_to_receive_bills: "अब तक बिल प्राप्त नहीं हुई साइटें",
  total_sites: "कुल साइटें",
  sites_received_bills_MTD: "प्राप्त बिलों का प्रतिशत",
  total: "कुल",
  total_bills_received_mtd: "महीने के बिल प्राप्त कुल संख्या",
  arrear_1: "अवकाश 1",
  arrear_2: "अवकाश 2",
  no_reading: "कोई पढ़ाई नहीं",
  bills_received_in_5_days: "पिछले 5 दिनों में प्राप्त बिल",
  site_wise_status: "साइट के अन्य स्थिति के अनुसार",
  site_wise_final_summary: "साइट-वाइज अंतिम सारांश",
  source_link: "स्रोत लिंक",
  data_type: "डेटा प्रकार",
  select_an_image_to_upload: "अपलोड करने के लिए एक छवि का चयन करें",
  user_group_Id: "उपयोगकर्ता समूह आईडी",
  user_group_name: "उपयोगकर्ता समूह नाम",
  delay_in_mins: "देरी (मिनट में)",
  delete_task: "कार्य हटाएं",
  watchers: "निरीक्षकों",
  comments: "टिप्पणियाँ",
  asset_under_maintenance: "असेट अन्योन्नति के अंतर्गत",
  start_time: "प्रारंभ समय",
  end_time: "समाप्ति समय",
  download_latest_usage_table: "नवीनतम उपयोग तालिका डाउनलोड करें",
  tag: "टैग",
  add_target_data: "लक्ष्य डेटा जोड़ें",
  edit_target: "लक्ष",
  delete_target: "लक्ष्य हटाएं",
  prop: "प्रॉप",
  excel_template_based_report: "एक्सेल टेम्पलेट आधारित रिपोर्ट",
  configured_hub: "कॉन्फ़िगर्ड हब",
  configured_relay: "कॉन्फ़िगर्ड रिले",
  desired_state: "वांछित स्थिति",
  configured_notification_group: "कॉन्फ़िगर्ड सूचना समूह",
  email_ids: "ईमेल आईडी",
  logs: "लॉग्स",
  unit_id: "यूनिट आईडी",
  download_report: "रिपोर्ट डाउनलोड करें",
  frequency: "आवृत्ति",
  ip: "आईपी",
  edit_wifi: "वाईफाई संपादित करें",
  details: "विवरण",
  connected_to: "कनेक्टेड टू",
  software_version: "सॉफ़्टवेयर संस्करण",
  mac_address: "मैक एड्रेस",
  opc_client_enabled: "OPC क्लाइंट सक्षम",
  MQTT_bridge_enabled: "MQTT ब्रिज सक्षम",
  hub_specification: "हब विनिर्देशिका",
  hub_details: "हब विवरण",
  restart_schedule: "पुनः प्रारंभ करें कार्यक्रम",
  restart_device: "पुनः प्रारंभ करें डिवाइस",
  set_up_restart_time: "पुनः प्रारंभ समय सेट करें",
  connectivity_logs: "कनेक्टिविटी लॉग्स",
  internal_cache_size: "आंतरिक कैश का आकार",
  default: "डिफ़ॉल्ट",
  configs: "कॉन्फ़िगरेशन",
  processed: "प्रोसेस किया गया",
  space_available: "उपलब्ध स्थान",
  kb: "केबी",
  mb: "एमबी",
  last_ping: "अंतिम पिंग",
  toggle: "टॉगल",
  configured_creds: "कॉन्फ़िगर किए गए क्रेडेंशियल्स",
  psk: "पीएसके",
  set_value: "मान सेट करें",
  time_current_time: "समय (यदि निर्दिष्ट नहीं किया गया हो तो वर्तमान समय लिया जाएगा)",
  Set: "सेट",
  health: "स्वास्थ्य",
  port: "पोर्ट",
  mqtt_params: "Mqtt-पैरामीटर",
  delete_device: "डिवाइस हटाएं",
  view_details: "विवरण देखें",
  parameter_id: "पैरामीटर आईडी",
  email_address: "ईमेल पता",
  attached_triggers: "संलग्न ट्रिगर",
  parameter_details: "पैरामीटर विवरण",
  enable_write: "लेखन सक्षम करें",
  disable_write: "लेखन अक्षम करें",
  offset: "ऑफसेट",
  no_of_parameters: "पैरामीटरों की संख्या",
  creation_time: "निर्माण का समय",
  not_configured: "कॉन्फ़िगर नहीं किया गया",
  add_rule: "नियम जोड़ें",
  sl_no: "क्रमांक",
  targeted_by_value: "मूल्य द्वारा लक्षित",
  configured_action: "कॉन्फ़िगर किया गया कार्रवाई",
  add_action: "कार्रवाई जोड़ें",
  action_id: "कार्रवाई आईडी",
  delete_user: "उपयोगकर्ता हटाएं",
  bit: "बिट",
  low: "कम",
  high: "उच्च",
  esg_scope_conversion: "ईएसजी दायरा परिवर्तन",
  batch_production_logs: "बैच उत्पादन लॉग",
  esg_data_logs: "ईएसजी डेटा लॉग",
  remove_from_dashboard: "डैशबोर्ड से हटाएं",
  data_logs: "डेटा लॉग",
  select_device: "डिवाइस का चयन करें",
  fetch_connectivity_logs: "कनेक्टिविटी लॉग्स प्राप्त करें",
  hub_internet_connectivity_logs: "हब इंटरनेट कनेक्टिविटी लॉग",
  modbus_gateways: "मॉडबस गेटवे",
  device_switched_on: "डिवाइस चालू है",
  device_switched_off: "डिवाइस बंद है",
  parameter_report: "पैरामीटर रिपोर्ट",
  timeframed_specific_energy_consumption_report:
    "समय-अवधि विशेष ऊर्जा खपत रिपोर्ट",
  ebill_distribution_report: "ई-बिल वितरण रिपोर्ट",
  root_user_management: "रूट उपयोगकर्ता प्रबंधन",
  user_acces_management: "उपयोगकर्ता अक्सेस प्रबंधन",
  inference: "निष्कर्ष",
  turn_off: "बंद करें",
  turn_on: "चालू करें",
  diagram_pallette: "डायग्राम पैलेट",
  add_component: "कॉम्पोनेंट जोड़ें",
  components: "घटक",
  add_text: "टेक्स्ट जोड़ें",
  add_realtime_data: "रियलटाइम डेटा जोड़ें",
  shapes: "आकार",
  lines: "रेखाएँ",
  text_realtime_component: "टेक्स्ट रियलटाइम कॉम्पोनेंट",
  shapes_pallette: "आकार पैलेट",
  lines_pallette: "रेखा पैलेट",
  click_choose: "क्लिक करें और डायग्राम में जोड़ने के लिए आकार चुनें",
  choose_line: "सीधी रेखा चुनें जो डायग्राम में जोड़ें",
  choose_image: "चित्र चुनें",
  upload_image_to_add: "डायग्राम में जोड़ने के लिए छवि अपलोड करें",
  add_realtime_textdata: "रियलटाइम या टेक्स्ट डेटा जोड़ें",
  update_user: "उपयोगकर्ता को अपडेट करें",
  add_watcher: "दर्शक जोड़ें",
  premise_area: "परिसर क्षेत्र",
  create_computed_param: "गणितीय पैरामीटर बनाएं",
  create_derived_param: "प्रारंभिक पैरामीटर बनाएं",
  proceed_to_ship: "नौवहन के लिए आगे बढ़ें",
  esg_data_collection: "ईएसजी डेटा संग्रह",
  esg_scope_details: "ईएसजी दायरा विवरण",
  select_custom_date_range: "कस्टम तिथि सीमा चुनें",
  days_365: "365 दिन",
  days_180: "180 दिन",
  days_30: "30 दिन",

  create_user_for_SSO: "SSO के लिए उपयोगकर्ता बनाएं",
  add_widget: "विजेट जोड़ें",
  add_machine_status_rules: "मशीन स्थिति नियम जोड़ें",
  add_trigger: "ट्रिगर जोड़ें",
  add_logged_parameter: "लॉग्ड पैरामीटर जोड़ें",
  create_mannual_param: "Mannual Param",
  add_opc_param: "OPC पैराम",
  create_write_block: "राइट ब्लॉक बनाएं",
  all_parameters: "सभी पैरामीटर",
  edit_device: "उपकरण संपादित करें",
  edit_modbus_device: "मोडबस डिवाइस",
  edit_mqtt_device: "Mqtt डिवाइस",
  limit_entropy: "लिमिट एनट्रॉपी",
  emails: "ईमेल",
  power_factor: "PowerFactor(PF)",
  tarrif_rate: "टैरिफ दर",
  apparent_power: "अनुकूल पावर",
  reactive_power: "प्रतिक्रियाशील शक्ति",
  active_power: "सक्रिय शक्ति",
  energy_usage_view_page: "ऊर्जा उपयोग पृष्ठ देखें",
  switch_organisation: "संगठन बदलें",
  wireless_creds: "वायरलेस Creds",
  latest: "नवीनतम",
  value_is: "मूल्य",
  please_take_necessary_action: "आवश्यक हो कार्रवाई",
  edit_role: "भूमिका",
  delete_role: "भूमिका",
  today: "आज",
  days_4: "4 दिन",
  due: "देय",
  move_to_done: "Done",
  power_system_fault: "पावर सिस्टम फॉल्ट गिनती (अंतिम सप्ताह)",
  alarms: "अलार्म",
  electricity_consumption: "बिजली की खपत (माह तक की तारीख)",
  average_demand: "औसत मांग",
  water_use_intensity: "जल उपयोग तीव्रता (WUI)",
  average_water_cost: "औसत जल लागत (मासिक)",
  water_use_efficiency: "जल उपयोग क्षमता (WUE)",
  batch_logs: "बैच लॉग",
  edit_log: "लॉग इन करें",
  delete_log: "लॉग इन करें",
  dates: "तारीख",
  log_details: "लॉग इन करें",
  view: "देखें",
  scope1_emission: "स्कोप 1 उत्सर्जन",
  scope2_emission: "स्कोप 2 उत्सर्जन",
  lmtd: "LMTD",
  company_data: "कंपनी डेटा",
  historian: "इतिहासकार",
  from_optional: "(वैकल्पिक) से",
  to_optional: "(वैकल्पिक)",
  trends: "रुझान",
  generate_trend: "रुझान",
  by: "द्वारा",
  check_logs: "लॉग चेक",
  workspaces: "कार्यस्थानों",
  types_of_nodes_connected: "नोड्स कनेक्टेड के प्रकार",
  types_of_modbus_device_connected: "मोडबस उपकरणों के प्रकार कनेक्ट",
  no_of_registers: "रजिस्टर की संख्या",
  write_register: "रजिस्टर",
  setting_view: "सेटिंग देखें",
  sign_up: "साइन अप करें",
  reset: "रीसेट करें",
  mobile_number: "मोबाइल नंबर",
  nebeskie_labs: "Nebeskie Lab",
  please_register_and_send_otp: "अपने पंजीकृत ईमेल दर्ज करें Id and click on Send OTP.",
  reset_password: "पासवर्ड रीसेट करें",
  back_to_login: "लॉग इन",
  otp: "ओटीपी",
  send_otp: "OTP भेजें",
  org_alias: "ऑर्ग अली",
  please_stand_by: "कृपया खड़े हो जाओ",
  total_usage_this_month: "कुल उपयोग यह महीना",
  total_usage_last_month: "पिछला महीना",
  generate_report_manually: "मैन्युअल रूप से रिपोर्ट उत्पन्न करना",
  energy_distribution_detailed_report: "ऊर्जा वितरण विस्तृत रिपोर्ट",
  the_report_is_processing: "रिपोर्ट प्रसंस्करण है",
  enter_active_energy_manually: "मैन्युअल रूप से सक्रिय ऊर्जा दर्ज करें",
  include_others: "अन्य",
  bill_amount: "बिल राशि",
  yes_delete_it: "हाँ, इसे हटा दें",
  create_write_param: "लेखक पैराम",
  report_list: "रिपोर्ट सूची",
  plant_id: "प्लांट आईडी",
  plant_name: "संयंत्र का नाम",
  plant_address: "संयंत्र का पता",
  add_selected: "चयनित जोड़ें",
  clear_selection: "स्पष्ट चयन",
  real_time_value: "वास्तविक मूल्य",
  default_status: "डिफ़ॉल्ट स्थिति",
  default_label: "डिफ़ॉल्ट लेबल",
  default_color_optional: "डिफ़ॉल्ट रंग वैकल्पिक",
  rule_based_status: "नियम आधारित स्थिति",
  show_label_only: "केवल लेबल दिखाएं",
  show_unit: "शो यूनिट",
  status_rules: "स्थिति नियम",
  configure: "कॉन्फ़िगर करना",
  monthly_usage: "मासिक उपयोग",
  meter_consumption_kwh: "मीटर की खपत (किलोवाट)",
  total_unit: "कुल इकाई",
  region: "क्षेत्र",
  average_energy_consumed: "औसत ऊर्जा खपत",
  average_production: "औसत उत्पादन",
  average_sec: "औसत SEC",
  total_production: "कुल उत्पादन",
  total_energy_consumed: "कुल ऊर्जा खपत",
  max_sec: "एसईसी",
  device_1: "डिवाइस 1",
  device_parameter_1: "डिवाइस पैरामीटर 1",
  device_2: "डिवाइस 2",
  device_parameter_2: "डिवाइस पैरामीटर 2",
  create_param: "पैराम बनाएँ",
  update_tags: "अद्यतन टैग",
  remove_from_process: "प्रक्रिया से निकालें",
  machine_parameters: "मशीन पैरामीटर",
  energy: "ऊर्जा",
  power: "शक्ति",
  remove_from_featured: "बाहर निकालें",
  other_parameters: "अन्य पैरामीटर",
  add_to_featured: "विशेष रुप से प्रदर्शित",
  logged_parameters: "लॉग इन करें",
  tags: "टैग",
  machine_status_rules: "मशीन स्थिति नियम",
  connected_communication_devices: "कनेक्टेड संचार उपकरण",
  mqtt_devices: "Mqtt उपकरण",
  modbus_devices: "मोडबस उपकरण",
  total_no_of_params: "पैराम की कुल संख्या",
  edge_devices: "एज उपकरण",
  machines_configured: "मशीनें कॉन्फ़िगर",
  reports_configured: "रिपोर्ट कॉन्फ़िगर",
  views_configured: "दृष्टिकोण कॉन्फ़िगर",
  send_email_notification: "ईमेल अधिसूचना भेजें",
  enture_send_email_notification: "आइए एन्चर चयनित उपयोगकर्ता समूह को ईमेल अधिसूचना भेजें",
  agree_and_send: "Agree and Send",
  ebill: "एबिल",
  monthly_trend: "मासिक रुझान",
  param_view: "परम दृश्य",
  calories: "कैलोरी",
  param_count_view: "पैराम गणना देखें",
  created: "बनाना",
  api_data_count_view: "एपीआई डेटा गणना देखें",
  diesel_emissions: "डीजल उत्सर्जन",
  eb_emissions: "EB उत्सर्जन",
  emissions_kg_co2: "उत्सर्जन किलोग्राम-Co2",
  kpis: "KPI",
  jan_23: "जनवरी-23",
  feb_23: "फ़रवरी-23",
  mar_23: "मार्च-23",
  apr_23: "अप्रैल-23",
  may_23: "मई-23",
  jun_23: "जून-23",
  lpg_emissions: "एलपीजी उत्सर्जन",
  petrol_emissions: "पेट्रोल उत्सर्जन",
  bullet_gauge: "बुलेट गेज",
  equipment: "उपकरण",
  enter_no_of_machines: "मशीनों की संख्या दर्ज करें",
  upload: "अपलोड करना",
  image_upload: "छवि अपलोड",
  derived_parameter: "Derived पैरामीटर",
  value_type: "मूल्य",
  enable_entropy: "एन्ट्रॉपी सक्षम करें",
  remove_decimal: "दशमलव",
  limiter_entropy: "लिमिटर एन्ट्रापी",
  show_sku_id: "शो में SKU ID",
  production_start_date: "उत्पादन प्रारंभ तिथि (वैकल्पिक)",
  production_start_time: "उत्पादन प्रारंभ समय(वैकल्पिक)",
  time: "समय",
  temperature_c: "तापमान (c)",
  set_temperature_c: "सेट तापमान (C)",
  no_of_people: "नहीं",
  co2_level: "Co2 स्तर",
  aqi_optional: "AQI वैकल्पिक",
  delete_sheets: "Delete Sheets",
  root_login: "रूट लॉगिन",
  uam_login: "यूएएम लॉगिन",
  login: "Login",
  add_oem_logo: "OEM लोगो जोड़ें",
  devices: "उपकरण",
  derived_data_units: "व्युत्पन्न/डेटा इकाइयाँ",
  roles: "भूमिकाएँ",
  engineering: "अभियांत्रिकी",
  control_panel: "कंट्रोल पैनल",
  views: "दृश्य",
  reports_analysis: "रिपोर्ट एवं विश्लेषण",
  actions_triggers: "क्रियाएँ और ट्रिगर",
  modules: "मॉड्यूल",
  processes: "प्रक्रियाओं",
  air_quality: "हवा की गुणवत्ता",
  manufacturing: "उत्पादन",
  esg: "ईएसजी",
  task_managment: "कार्य प्रबंधन",
  sustainability: "वहनीयता",
  groups: "समूह",
  log_book: "लॉग बुक",
  monitor: "निगरानी करना",
  enture_settings: "उद्यम सेटिंग्स",
  configuration: "विन्यास",
  bar: "Bar",
  line: "Line",
  s7_device: "S7 Device",
  rack: "Rack",
  slot: "Slot",
  db_number: "DB Number",
  byte_offset: "Byte Offset",
  bit_offset: "Bit Offset",
  data_length: "Data Length",
  val_type: "Value Type",
  create_s7_device: "Create S7 Device",
  scope_label: "Scopes",
  client_safety: "Client Safety",
  edit: "संपादन करना",
  delete: "इसे मिटायें",
  choose_param_to_monitor: "मॉनिटर करने के लिए परम चुनें",
  rules: "नियम",
  add_to_row: "Row में जोड़ें",
  add_usage: "उपयोग जोड़ें",
  notifications: "सूचनाएं",
  organisation_details: "संगठन विवरण",
  profile: "प्रोफ़ाइल",
  user_access: "उपयोगकर्ता की पहुंच",
  skus: "स्टॉक कीपिंग इकाइयाँ",
  locations: "स्थानों",
  locations_event_types: "स्थान घटना प्रकार",
  lists: "सूचियों",
  param_equations: "पैरामीटर समीकरण",
  combo_params: "संयुक्त पैरामीटर",
  calculated_params: "परिकलित पैरामीटर",
  admin: "व्यवस्थापक",
  "shifts": "परिवर्तन",
  "add_pie_TD_widget": "पाई टिल डेट विजेट जोड़ें",
  "edit_pie_TD_widget": "पाई टिल डेट विजेट संपादित करें",
  "legend": "Legend",
  "pie": "Pie",
  "edit_bar_trend_widget": "बार ट्रेंड विजेट संपादित करें",
  "add_bar_trend_widget": "बार ट्रेंड विजेट जोड़ें",
  "edit_horizantal_bar_trend_widget": "क्षैतिज बार ट्रेंड विजेट संपादित करें",
  "add_horizantal_bar_trend_widget": "क्षैतिज बार ट्रेंड विजेट जोड़ें",
  "too_long": "बहुत लंबा",
  "trigger_logs": "ट्रिगर लॉग",
  "create_trigger": "ट्रिगर बनाएं",
  "right_click_to_configure": "कॉन्फ़िगर करने के लिए राइट क्लिक करें",
  "width": "चौड़ाई",
  "height": "ऊंचाई",
  "hide_value": "मान छिपाएँ",
  "edit_machine": "मशीन संपादित करें",
  "wait_time": "प्रतीक्षा समय",
  "production_date": "उत्पादन की तारीख",
  "connectivity_status": "कनेक्टिविटी स्थिति",
  "production_plans": "उत्पादन योजनाएँ",
  "production_pending": "उत्पादन लंबित",
  "pending_shipment": "लंबित लदान",
  "trace_logs": "ट्रेस लॉग",
  "movement_events": "बैच मूवमेंट इवेंट",
  "entropy_threshold": "एन्ट्रापी दहलीज",
  "threshold_value": "सीमा मूल्य",
  "pending_dispatch": "लंबित प्रेषण",
  "dispatched": "भेजा गया",
  "dispatch_details": "प्रेषण विवरण",
  "dispatch": "इसे भेजें",
  "raw_materials": "कच्चा माल",
  "pour_qty": "मात्रा डाली गई",
  "summary": "सारांश",
  "optional": "वैकल्पिक",
  "param_mqtt_map": "परम एमक्यूटीटी मानचित्र",
  "machine_status_mqtt_map": "मशीन स्थिति एमक्यूटीटी मानचित्र",
  "edit_trigger_count_table_widget": "ट्रिगर गणना तालिका विजेट संपादित करें",
  "add_trigger_count_table_widget": "ट्रिगर गणना तालिका विजेट जोड़ें",
  "count": "गिनती",
  "real_time_consumption": "वास्तविक समय की खपत",
  "search_view": "खोज दृश्य",
  "multiple_entries": "अनेक प्रविष्टियाँ",
  "combined_parameter": "संयुक्त पैरामीटर",
  "background_color": "पृष्ठभूमि का रंग",
  "resize": "आकार बदलें",
  "remove": "हटाएँ",
  "select_views": "दृश्य चुनें",
  "difference": "अंतर",
  "sum": "जोड़",
  "set_param_type_for_machine": "मशीन के लिए परम प्रकार सेट करें",
  "temperature_parameters": "तापमान पैरामीटर",
  "area_name": "क्षेत्र का नाम",
  "maintenance_due_on": "रखरखाव देय है",
  "days_left": "दिन शेष",
  "enture_insights": "उद्यम अंतर्दृष्टि",
  "edit_logged_parameter": "लॉग किए गए पैरामीटर संपादित करें",
  "reports": "रिपोर्टों",
  "add_to_list": "सूची में शामिल",
  "till_date_pie_chart": "तारीख तक पाई चार्ट",
  "till_date_bar_chart": "तारीख तक बार चार्ट",
  "tod_billing_widget": "TOD बिलिंग विजेट",
  "bar_trend_widget": "बार ट्रेंड विजेट",
  "target_based_bar_trend_widget": "लक्ष्य आधारित बार ट्रेंड विजेट",
  "gauge_widget": "गेज विजेट",
  "consumption_pie_widget": "उपयोग पाई विजेट",
  "consumption_gauge_widget": "उपयोग गेज विजेट",
  "angular_gauge_widget": "एंगुलर गेज विजेट",
  "table_widget": "टेबल विजेट",
  "banner_widget": "बैनर विजेट",
  "param_equation_banner_widget": "पैरामीटर समीकरण बैनर विजेट",
  "shift_based_usage_table": "शिफ्ट आधारित उपयोग तालिका",
  "bitwise_word_widget": "बिटवाइज वर्ड विजेट",
  "dynamic_log_widget": "डायनामिक लॉग विजेट",
  "machine_status_timeline_widget": "मशीन स्थिति टाइमलाइन विजेट",
  "process_or_machine_status_widget": "प्रक्रिया/मशीन स्थिति विजेट",
  "parameter_insights_widget": "पैरामीटर इनसाइट्स विजेट",
  "heat_map": "हीट मैप",
  "usage_score_card_widget": "उपयोग स्कोर कार्ड विजेट",
  "busbar_live_monitoring": "बसबार लाइव मॉनिटरिंग",
  "bullet_or_angular_guage_widget": "बुलेट/एंगुलर गेज विजेट",
  "dual_axis_bar_trend_widget": "डुअल एक्सिस बार ट्रेंड विजेट",
  "trigger_count_table_widget": "ट्रिगर गिनती तालिका विजेट",
  "bar_trend_td_widget": "बार ट्रेंड टीडी विजेट",
  "horizantal_bar_trend_widget": "क्षैतिज बार ट्रेंड विजेट",
  "parameter_status_based_usage_status": "पैरामीटर स्थिति आधारित विजेट",
  "choose_from_templates": "टेम्प्लेट्स से चुनें",
  "shift_timeframed_trends": "शिफ्ट आधारित समय-सीमा वाले रुझान",
  "candlestick_trends": "कैंडलस्टिक रुझान",
  "free_ram": "Free RAM",
  "item": "Item",
  "item_code": "Item Code",
  "department": "Department",
  "departments": "Departments",
  "issue_vouchers": "Issue Vouchers",
  "issue_voucher": "Issue Voucher",
  "to_be_approved": "To Be Approved",
  "to_be_issued": "To Be Issued",
  "intended": "Intended",
  "approved": "Approved",
  "received": "Received",
  "receiver": "Receiver",
  "material": "material",
  "material_group": "material group",
  "material_desc": "Material Description",
  "uom": "Unit Of Measurement",
  "create_item_code": "Create Item Code",
  "create_department": "Create Department",
  "users": "Users",
  "items": "Items",
  "users_in_department": "Users in Department",
  "authorisers_in_department": "Authorisers in Department",
  "issuers_in_department": "Issuers in Department",
  "item_issuer_list": "Item Issuer List",
  "add_item_issuer": "Add Item Issuer",
  "store": "Store",
  "create_issue_voucher": "Create Issue Voucher",
  "voucher_requests": "Voucher Requests",
  "intended_qty": "Intended Qty",
  "issued_qty": "Issued Qty",
  "issue_items": "Issue Items",
  "voucher_receipt": "Voucher Receipt",
  "add_stock": "Add Stock",
  "attached_targets": "Attached Targets",
  "attach_target": "Attach Target",
  "add_target_to_parameter": "Add Target to Parameter",
  "empty": "Empty",
  "add_day_wise_target": "Add Day wise Target",
  "day_wise_targets": "Day Wise Targets",
  "fetch_targets": "Fetch Targets",

  "day_wise_machine_status_widget": "दैनिक मशीन स्थिति विजेट",
  "add_day_wise_machine_status_widget": "दैनिक मशीन स्थिति विजेट जोड़ें",
  "edit_day_wise_machine_status_widget": "दैनिक मशीन स्थिति विजेट संपादित करें",
  "add_machines": "मशीनें जोड़ें",
  "added_machines": "जोड़ित मशीनें",
  "consumption_start_time":"Consumption Start time",







};
