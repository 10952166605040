<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card flat :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}">
            <v-card-title>{{userGroup.name}} 
                <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="290"><CreateUserGroup :userGroup="userGroup" v-on:close="onClose" /></v-dialog>
                <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['uam'])">
                    <v-icon small class="mr-2" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['uam'])" @click="dialog=!dialog">
                        mdi-pencil
                    </v-icon>
                    <DeleteConfirmation  v-on:confirm="deleteGroup()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
                        <v-icon small>mdi-delete</v-icon>
                    </DeleteConfirmation>
                </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle >
                <v-row v-if="loading">
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['uam']) ">
                    <v-col cols="1">
                        <span>
                            <v-btn small fab :dark="$store.getters.getColorPalette().isDark" @click="addDialog=!addDialog" color="blue" >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
                <v-row v-if="addDialog">
                    <v-col>
                        <v-form ref="addForm" v-model="valid">
                            <v-row>
                                <v-col>
                                <v-text-field
                                    v-if="!loading"
                                    v-model="form.label"
                                    :label="$store.getters.getTextMap().label"
                                    :rules="nameRules"
                                    required
                                    :dark="$store.getters.getColorPalette().isDark" 
                                ></v-text-field>
                                </v-col>
                                
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-autocomplete :dark="$store.getters.getColorPalette().isDark"
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="user"
                                    :items="userOptions"
                                    :label="$store.getters.getTextMap().user"
                                    outlined
                                    item-text="label"
                                    item-value="value"
                                    required
                                    :rules="objectRules"
                                ></v-autocomplete>
                            </v-col>
                            <v-col>
                                <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" text @click="addTupleToGroup">
                                   {{$store.getters.getTextMap().add}}
                                </v-btn>
                            </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-simple-table height="150px">
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    <strong>{{$store.getters.getTextMap().label}}</strong>
                                </th>
                                <th class="text-left">
                                    <strong>{{$store.getters.getTextMap().user_id}}</strong>
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in tuples"
                                :key="item.userid"
                                >
                                <td>{{ item.label }}</td>
                                <td>{{ item.userid }}</td>
                                <td v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['uam']) "><v-icon small color="red" @click="deleteTupleFromGroup(item)">mdi-delete</v-icon></td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-subtitle>
        </v-card>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import FormRules from '@/utillities/FormRules'
import CreateUserGroup from '@/components/account_components/CreateUserGroup'

export default {
name:'UserGroup',
props:['userGroup'],
components:{
    InfoAlert,
    CreateUserGroup,
    DeleteConfirmation
},
mounted(){
    this.getTuples()
},
data(){
    return {
        showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        addDialog:false,
        valid:false,
        dialog: false,
        user:null,
        tuples:[],
        form:{

        },
        stringRules: [
            v => !!v || 'Required',
            v => /\S+/.test(v) || 'Required',
            v => (v && v.length <= 30 && v.length > 0) || 'Cannot be more than 30 characters',
        ],
        objectRules:[
            v => !!v || 'Required',
        ],
        bitRules:[
            v => !!v || 'Required',
            v => /[0-9]+$/.test(v) || 'It should be a number',
            v => (v && !isNaN(v))|| 'It should be a valid number',
            v => (v && v.length <= 2 && v.length > 0) || "It can't be more that 2 digits",
        ],
        emailRules:FormRules.emailRules,
        nameRules:FormRules.nameRules
    }
},
computed:{
    userOptions(){
        let op=[]
        let g=this.$store.state.UAMUsers
            for(let i of g){
                
                    op.push( {value:i.userid, label: i.userid+' - '+i.name })
                
                
            }
        return op

    }
},
methods:{
    onClose(){
        this.dialog=false,
        this.$emit('close')
    },
    getTuples(){
        this.loading=true
        axios.post(this.$store.state.api+'getTuplesByUserGroup',this.userGroup,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.tuples=response.data.data
                    this.loading=false
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
    },
    deleteGroup(){
        axios.post(this.$store.state.api+'deleteUserGroup',this.userGroup,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
                this.$store.dispatch('refreshUserGroups')
                this.loading=false
                this.info='The User Group has been deleted'
                this.showDismissibleAlert=true
            }else{
                this.loading=false
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
        }).catch(err=>{
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        })
    },
    deleteTupleFromGroup(tuple){
        axios.post(this.$store.state.api+'deleteUserFromGroup',tuple,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
                //this.$store.dispatch('refreshListData')
                this.loading=false
                this.info='The User has been removed'
                this.showDismissibleAlert=true
                this.tuples=this.tuples.filter((t)=>{return t.userid!=tuple.userid})
            }else{
                this.loading=false
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
        }).catch(err=>{
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        })
    },
    addTupleToGroup(){
        this.$refs.addForm.validate()
        if(this.valid){
            this.loading=true
            this.form['group_id']=this.userGroup.group_id
            this.form.userid = this.user
            axios.post(this.$store.state.api+'addTupleToUserGroup',this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    this.info='The User has been added'
                    this.showDismissibleAlert=true
                    this.tuples.push(response.data.tuple)
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        }
    },
  cancel(){
      this.showDialog=false
    },
    confirm(){
      this.showDialog=false
    }    
},
watch:{
    userGroup: function (){
        this.getTuples()
    },
}
}
</script>