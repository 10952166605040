<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form
            ref="form"
            v-model="valid" >
        <v-row>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-menu :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.from_date"
            :label="$store.getters.getTextMap().from_date"
            prepend-icon="mdi-calendar"
            readonly
            outlined
            dense
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="[rules.required]"
          ></v-text-field>
        </template>
        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.from_date"
          @input="menu1 = false"
        ></v-date-picker>  
      </v-menu>
            </v-col>
            <v-col>
                <v-menu :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.from_date"
            :label="$store.getters.getTextMap().to_date"
            prepend-icon="mdi-calendar"
            readonly
            outlined
            dense
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="[rules.required]"
          ></v-text-field>
        </template>
        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.to_date"
          @input="menu2 = false"
        ></v-date-picker>  
      </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn
                v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
           
            small
            @click="onSubmit"
          >
            {{ $store.getters.getTextMap().submit }}
          </v-btn>
            </v-col>
        </v-row>
    </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
// import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
name:'WorkspaceMachineForm',
props:['multiple'],
components:{
    InfoAlert,
},
data(){
    return {
        showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        valid:false,
        menu1:null,
        menu2:null,
        form:{},
        rules:FormRules.rules,
        objectRules:[
            v => !!v || 'Required',
        ],
    }
},
computed:{
},
methods:{

    onSubmit(){
        this.$refs.form.validate()
        if(this.valid){
        this.$emit('value',this.form)
        }
    },
    close(){
        this.$emit('close')
    }
},
watch:{

}
}
</script>