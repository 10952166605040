<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        {{ valid }}
        <v-form
            ref="form"
            v-model="valid" >
        <v-row>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
                v-if="!loading"
            v-model="form.type"
            :items="typeOptions"
            :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            :rules="objectRules"
            label="Type"
            item-text="label"
            item-value="value"
          ></v-autocomplete>
            </v-col>
            <v-col >
                <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-text-field :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.value"
            label="value"
            
            outlined
            dense
            :loading="loading"
            :rules="objectRules"
          ></v-text-field>
            </v-col>
            <v-col>
                <v-btn
                v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
           
            small
            @click="onSubmit"
          >
            {{ $store.getters.getTextMap().submit }}
          </v-btn>
            </v-col>
        </v-row>
    </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'

export default {
name:'HubCommandForm',
props:[],
components:{
    InfoAlert,
},
data(){
    return {
        showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        valid:false,
        form:{
            type:null,
            value:null
        },
        objectRules:[
            v => !!v || 'Required',
        ],
        typeOptions:[
            { label:'Usage Pointer Update', value:'updateUsagePointer' },
        ]
    }
},
computed:{
},
methods:{
    onSubmit(){
        this.loading=true
        this.$refs.form.validate()
        if(this.valid){
        this.$emit('value',this.form)
        // console.log('onsubmit emit')
        }
        this.loading=false
    },
},
watch:{

}
}
</script>