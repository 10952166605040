import English from "@/utillities/languages/English";

export default {
  ...English,
  start: "Start",
  filling: "Füllung",

  accept: "Akzeptieren",
reject: "Ablehnen",
move_to_next_stage: "Zur nächsten Stufe wechseln",
save_as_draft: "Als Entwurf speichern",
save_and_submit: "Speichern und einreichen",
closure: "Schließung",
duration: "Dauer",
refill_request: "Nachfüllanfrage",
is_be_cancelled: "wird storniert",
delete_this: "Lösche dies",
event: "Ereignis",

  for: "Für",
  of: "Von",
  downtime: "Ausfallzeit",

  metrics: "Metrics",
  oee_by_day: "OEE nach Tag",

  ideal_cycle_time: "Ideale Zykluszeit",
  
  minutes: "Minuten",
  production_time: "Produktionszeit",
  back: "Zurück",
  down_times: "Ausfallzeiten",

  top_5_reasons: "Top 5 Gründe",
  top_5_categories: "Top 5 Kategorien",
  enter_apparent_energy_manually: "Scheinenergie manuell eingeben",

  oee: "OEE",
  reason: "Grund",
  add_downtime_reason: "Grund für Ausfall hinzufügen",

  default_target: "Standardziel",

  enter_in_minutes: "In Minuten eingeben",
  custome_fields: "Benutzerdefinierte Felder",
  enter_data_in_minutes: "Daten in Minuten eingeben",
  enter_data_in_hours: "Daten in Stunden eingeben",

  create_tod_billing_widget: "TOD-Abrechnungs-Widget erstellen",
  edit_tod_billing_widget: "TOD-Abrechnungs-Widget bearbeiten",
  choose_from_machine_groups: "Aus Maschinengruppen auswählen",
  tod_billing: "TOD-Abrechnung",
  choose: "Wählen",

  create_shift_based_machine_usage_log: "Maschinennutzungsprotokoll erstellen",
  shift_based_machine_usage_log: "Maschinennutzungsprotokoll",
  rejected: "Abgelehnt",
  machine_operator: "Maschinenbediener",
  production_end_timestamp: "Produktionsendzeit",
  production_start_timestamp: "Produktionsstartzeit",
  running_time: "Betriebszeit",
  idle_time: "Leerlaufzeit",
  down_time: "Ausfallzeit",

  date_row_index: "Datum Zeilenindex",
  date_position: "Datum Position",

  safety_incident_sheet: "Sicherheitsvorfallblatt",
  safety_inspection_sheet: "Sicherheitsinspektionsblatt",

  ghg_emission_logs: "GHG-Emissionsprotokolle",
  add_ghg_emission_data: "GHG-Emissionsprotokolle hinzufügen",
  ghg_scope_conversion: "GHG-Umfangsumrechnung",
  create_ghg_scope_conversion: "GHG-Umfangsumrechnung erstellen",
  ghg_data_logs: "GHG-Emissionsdatenprotokolle",

  choose_inspection_type_optional: "Inspektionstyp wählen (Optional)",

  supplier_code: "Lieferantencode",
  contact: "Kontakt",
  point_of_contact: "Ansprechpartner",
  quantity_supplied: "Gelieferte Menge",
  create_product: "Produkt erstellen",
  products: "Produkte",
  data: "Daten",
  timezone: "Zeitzone",
  tariffs: "Tarife",

  days_delta: "Tagesdelta",
  machine_groups: "Maschinengruppen",
  param_groups: "Parametergruppen",
  user_groups: "Benutzergruppen",

  topic_prefix: "Themenpräfix",
  message_direction: "Nachrichtenrichtung",

  safety_inspection: "Sicherheitsinspektion",
  write_inspection_log: "Inspektionsprotokoll schreiben",
  inspection_id: "Inspektions-ID",
  inspector_id: "Inspektor-ID",
  inspection_date: "Inspektionsdatum",
  inspection_type: "Inspektionstyp",
  findings: "Ergebnisse",
  actions_required: "Erforderliche Maßnahmen",
  followup_date: "Nachverfolgungsdatum",
  status: "Status",

  safety_incident: "Sicherheitsvorfall",
  write_incident: "Vorfall schreiben",
  incident_description: "Vorfallsbeschreibung",
  severity: "Schwere",
  accident: "Unfall",
  accident_with_loss: "Unfall mit Verlust",
  closed: "Geschlossen",
  corrective_action: "Korrekturmaßnahme",
  employee_id: "Mitarbeiter-ID",
  apply: "Anwenden",
  choose_location_optional: "Ort wählen (Optional)",

  create_tariff: "Tarif Erstellen",
  default_tariff_val: "Standardtarifwert",
  add_tariff_data: "Tarifdaten Hinzufügen",
  edit_tariff: "Tarif Bearbeiten",
  delete_tariff: "Tarif Löschen",
  tariff_value: "Tarifwert",

  machine_status_timeline: "Maschinenstatus-Zeitachse",
  total_duration: "Gesamtdauer",
  generate: "Generieren",
  remove_machine: "Maschine entfernen",
  edit_machine_status_timeline_widget: "Maschinenstatus-Timeline-Widget bearbeiten",
  add_machine_Status__timeline_widget: "Maschinenstatus-Timeline-Widget hinzufügen",
  select_timeframe: "Zeitraum auswählen",
  timeframe_type_shift: "Schicht",
  timeframe_type_custom: "Benutzerdefiniert",
  timeframe_type_yesterday: "Gestern",
  edit_report_details: "Details zum Bericht bearbeiten",
  download_current_template: "Aktuelle Vorlage herunterladen",
  date_column_index: "Datum Spalte (Index)",
  select_area: "Wählen Sie den Bereich",
  select_shift: "Schicht auswählen",

  dashboard_heading: "Das ist das",
  production_params: "Produktionsparamen",
  no_modules_configured: "Keine Module konfiguriert",
  dashboard_panel: "Das ist das..",
  choose_type: "Typ wählen",
  name_is_required: "Name ist erforderlich",
  description_is_required: "Beschreibung ist erforderlich",
  required: "Erforderlich",
  you_have_to_select_something: "Sie müssen etwas auswählen",
  name_must_be_less_than_30_characters: "Name muss weniger als 30 Zeichen sein",
  name_must_be_less_than_25_characters: "Name muss weniger als 25 Zeichen sein",
  name_must_be_less_than_50_characters: "Name muss weniger als 50 Zeichen sein",
  description_must_be_less_than_35_characters:
    "Beschreibung muss kleiner als 35 Zeichen sein",
  description_must_be_less_than_55_characters:
    "Beschreibung muss weniger als 55 Zeichen sein",
  number_must_be_less_than_6_digits: "Nummer muss weniger als 6 Digits sein",
  number_must_be_less_than_8_digits: "Nummer muss weniger sein 8stellig",
  number_must_be_less_than_7_digits: "Nummer muss weniger sein Über 7 Ziffern",
  panel: "Panel",
  gauge: "Prüfung",
  param: "Parasiten",
  machine: "Maschine",
  add_panel_to_dashboard: "Panel zu Dashboard hinzufügen",
  edit_dashboard_panel: "Dashboard Panel bearbeiten",
  param_type: "Typ der Parasiten",
  workspace: "Arbeitsraum",
  device: "Gerät",
  hub: "Nabe",
  name: "Name",
  language: "Sprache",
  time_duration: "Zeitdauer",
  submit: "Einreichung",
  cancel: "Abbrechen",
  close: "Schließen",
  usage: "Verwendung",
  peak_value: "Spitzenwert",
  current_data: "Aktuelle Daten",
  parameter: "Parameter",
  computed_param: "Gelobte Param",
  derived_param: "Abgeleitete Param",
  last_7_days: "Letzte 7 Tage",
  "t4_days": "4 Tage",
  last_30_days: "Letzte 30 Tage",
  last_60_days: "Letzte 60 Tage",
  last_90_days: "letzte 90 Tage",
  last_180_days: "Letzte 180 Tage",
  last_365_days: "Lat 365 Tage",
  last_1_hour: "1 stunde",
  last_3_hours: "letzte 3 stunden",
  last_6_hours: "letzte 6 stunden",
  reorder_views: "Neuordnung",
  create_view: "Erstellung",
  clear_view_name_filter: "Filter löschen",
  view_type: "Typ der Person",
  description: "Warenbezeichnung",
  table_view: "Tabelle",
  widget_view: "Widget View",
  dynamic_timeframed_widget_view: "Dynamische Zeitrahmen Widget Ansicht",
  diagram_view: "Diagrammansicht",
  energy_usage_view: "Energienutzungsansicht",
  machine_scroll_view: "Maschinen und Anlagen Blick",
  param_scroll_view: "Param Scroll Blick",
  consolidated_view: "Konsolidierte Ansicht",
  air_quality_view: "Blick auf die Luftqualität",
  view_auto_change: "Automatische Änderung anzeigen",
  no_views_configured: "Keine Ansichten konfiguriert",
  add_to_dashboard: "Zu Dashboard hinzufügen",
  edit_view_name: "Name des Benutzers",
  edit_view: "des Benutzers",
  refresh: "Refreshing",
  create_machine: "Maschine erstellen",
  save: "Speichern",
  discard: "verwerfen",
  adjust: "anpassen",
  add_machine: "Fügen Sie Maschine",
  delete_premise_area: "Räumlichkeitenbereich löschen",
  sequence: "Folgen",
  machine_type: "Maschinentyp",
  physical_machine: "Physikalische Maschine",
  virtual_machine: "Virtuelle Maschine",
  positive_workspace: "Positiver Workspace",
  positive_machine: "Positive Maschine",
  negative_workspace: "Negativer Arbeitsraum",
  negative_machine: "Negative Maschine",
  label: "Bezeichnung",
  positive_machine_Ids: "Positive Maschine Ausweis",
  negative_machine_Ids: "Negative Maschine Ausweis",
  trend_analysis_tools: "Werkzeuge für die Analyse",
  energy_consumption_by_machines: "Energieverbrauch Von Maschinen",
  Create_report: "Bericht erstellen",
  view_report: "Bericht anzeigen",
  tarrif_on: "Tarrif auf",
  active_energy: "Aktive Energie",
  apparent_energy: "Offene Energie",
  automate_report: "Automatischer Bericht",
  add_root_machine: "Root Machine hinzufügen",
  add_common_machine: "Fügen Sie gemeinsame Maschine",
  notification_groups: "Notifizierungsgruppen",
  add_machines_to_list: "Maschinen hinzufügen Zur Liste",
  machine_list: "Liste der Maschinen",
  data_historian: "Daten Historisch",
  timeframed_trends: "Zeitrahmende Trends",
  dual_axis_trend_comparison: "Dual Axis Trendvergleich",
  granular24_hrs_trend: "Granular24 Hrs Trend",
  machinewise_trend: "Machine Wise Trend",
  basic_statistical_trends: "Statistische Grundzahlen Entwicklung",
  log_analysis: "Analyse der Ergebnisse",
  aqi_comparison_panel: "AQI Vergleich Panel",
  time_of_day_trend: "Zeit des Tages Trend",
  param_historical_comparisons: "Param Historische Vergleiche",
  energy_usage_trends: "Energienutzungstrends",
  machine_name: "Bezeichnung",
  param_name: "Name des Parasiten",
  trend_view: "Blick auf die Zukunft",
  raw: "Roh",
  trend: "Trend",
  normalised: "Normalisiert",
  from_date: "Datum",
  to_date: "Datum",
  from: "Von",
  to: "Zu",
  hour: "Geruch",
  minute: "Minuten",
  generate_report: "Bericht erstellen",
  time_range: "Zeitbereich",
  no_data_available: "Keine Daten verfügbar",
  daily: "Täglich",
  monthly: "Monthy",
  weekly: "Wochen",
  week: "Woch",
  yearly: "Jährlich",
  device_name: "Name des Geräts",
  machine_wise_comparison: "Vergleich der Maschine",
  stats_detailed_report: "Detailbericht",
  log_type: "Typ der Anmeldung",
  choose_Log: "Wählen Sie das Protokoll",
  area: "Gebiet",
  aq_device: "AQ-Gerät",
  nebeskie_labs_office: "Nebeskie Labs Büro",
  date_range: "Datum",
  this_year: "Dieses Jahr",
  "last 6 months": "Letzte 6 Monate",
  "last quater": "Letzter Beitrag",
  mtd: "MT",
  ytd: "YTD",
  yesterday: "Gestern",
  custom: "Zoll",
  run_report: "EINFUHR",
  create_report: "Bericht erstellen",
  usage_report: "Nutzungsbericht",
  consolidated_report: "Konzernbericht",
  dynamic_excel_report: "Dynamischer Excel-Bericht",
  energy_usage_report: "Energienutzungsbericht",
  create_action: "Aktion erstellen",
  action_type: "Aktionsart",
  notification_group: "Notifizierungsgruppe",
  address: "Anschrift",
  number_of_registers: "Anzahl der Register",
  value: "Wert",
  send_notification: "Mitteilung senden",
  write_parameter: "Schreibparameter",
  relays: "Relais",
  relay_1: "Relais 1",
  relay_2: "Relais 2",
  relay_3: "Relais 3",
  relay_4: "Relais 4",
  email_must_be_valid: "E-Mail muss gültig sein",
  it_should_be_a_number: "Es sollte eine Nummer sein",
  it_should_be_a_valid_number: "Es sollte eine gültige Nummer sein",
  operator: "Betreiber",
  operand: "Oper und",
  target_value: "Zielwert",
  wait_time_seconds: "Wartezeit (zweite)",
  greater_than: "Mehr zum Thema",
  modulus_greater_than: "Modularität Than",
  lesser_than: "Weniger als",
  modulus_lesser_than: "Modularer kleiner Than",
  equal_to: "Gleichheit",
  not_equal_to: "Nicht gleich",
  modulus_equal_to: "Modul zur Gleichheit",
  greater_than_equal_to: "Mehr als gleich",
  modulus_greater_than_equal_to: "Modulus größer als gleich",
  lesser_than_equal_to: "Weniger als gleich",
  modulus_lesser_than_equal_to: "Modulus Weniger als gleich",
  bit_high: "Bit High",
  bit_low: "Bit Low",
  it_should_be_a_positive_whole_number:
    "Es sollte eine positive ganze Zahl sein",
  it_should_be_a_positive: "Es sollte ein Positiv sein",
  create_process: "Erstellen von Prozessen",
  edit_process: "Bearbeitung",
  status_rule: "Artikel",
  create_process_status_rule: "Prozessstatusregel erstellen",
  tag_type: "Tag-Typ",
  process_status: "Prozessstatus",
  machines: "Maschinen",
  running: "Laufen",
  idling: "Imprägnierung",
  offline: "Offline",
  no_machines_configured: "Keine Maschinen konfiguriert",
  delete_process: "Löschen",
  add_machine_to_process: "Maschinen hinzufügen",
  add: "ADD",
  add_to_list: "Zur Liste hinzufügen",
  search: "Suche",
  switch_to: "Schalter",
  switch_to_base_org: "Schalter zur Basis Org",
  switch: "Schalter",
  create_new_organisation: "Neue Organisation erstellen",
  organisation_name: "Bezeichnung",
  organisation_type: "Organisationsart",
  country: "Land",
  country_code: "Ländercode",
  state: 'Staat',
  clear: "Klar",
  change_organisation: "Organisation ändern",
  organisation_id: "Organisationsausweis",
  update_your_oem_logo: "Aktualisieren Sie Ihr OEM Logo",
  select_a_logo_to_upload: "Wählen Sie ein Logo zum Hochladen",
  submit_logo: "Anmelden",
  create_workspace: "Workspace erstellen",
  hub_id: "HUB-ID",
  name_must_be_less_than_45_characters: "Name muss weniger als 45 Zeichen sein",
  id_is_required: "ID ist erforderlich",
  no_space_allowed: "Kein Platz erlaubt",
  id_must_be_less_than_6_characters: "Id muss weniger als 6 Zeichen sein",
  edit_workspace: "Workspace bearbeiten",
  delete_workspace: "Löschen von Workspace",
  add_modbus_device: "Modbus-Gerät hinzufügen",
  add_device: "Gerät hinzufügen",
  oops: "Ooops!",
  ok: "Ok",
  slave_id: "Slawische ID",
  device_type: "Gerätetyp",
  connection_type: "Anschlussart",
  mGate: "m Tor",
  make: "Mach's gut",
  model: "Modell",
  name_must_be_less_than_33_characters: "Name muss weniger als 33 Zeichen sein",
  name_must_be_less_than_11_characters: "Name muss weniger als 11 Zeichen sein",
  it_should_be_a_valid_slave_id: "Es sollte eine gültige Slave-ID sein",
  name_must_be_less_than_4_digits: "Name muss weniger als 4 Ziffern sein",
  modbus_tcp: "Modbus TCP",
  modbus_rtu: "Modbus RTU",
  add_virtual_device: "Virtual Device hinzufügen",
  add_mqtt_device: "MQtt-Gerät hinzufügen",
  topic: "Thema",
  identifier: "Kennung",
  identifier_value: "Kennungswert",
  name_must_be_less_than_20_characters: "Name muss weniger als 20 Zeichen sein",
  no_special_characters_or_space_allowed:
    "Keine Sonderzeichen oder Platz erlaubt",
  must_be_less_than_15_characters: "Müssen. weniger als 15 Zeichen",
  create_role: "Role erstellen",
  role: "Laufsohle",
  role_is_required: "Rolle ist erforderlich",
  role_must_be_less_than_40_characters:
    "Rolle muss weniger als 40 Zeichen sein",
  role_should_not_contain_white_characters:
    "Rolle sollte keine weißen Zeichen enthalten",
  comparison: "Vergleich",
  historical_time_of_day_comparison_parameter:
    "Geschichte Zeit des Tagesvergleichs - Parameter",
  device_parameter: "Geräteparameter",
  date_1: "Datum 1",
  date_2: "Datum 2",
  tod_comparison_report: "TOD-Vergleichsbericht",
  time_frame: "Zeitrahmen",
  download_table: "Zur Übersicht",
  table: "Tabelle",
  showMinMax_values: "Min & Max Werte anzeigen",
  usage_or_average: "Verwendung oder Durchschnitt",
  min_value: "Minenwert",
  last_12_weeks: "Letzte 12 Wochen",
  last_6_months: "Letzte 6 Monate",
  last_12_months: "Letzte 12 Monate",
  year_5: "5 jahre",
  machinewise_comparison: "Weiser Vergleich",
  timeFrame: "Zeitrahmen",
  it_should_be_a_date: "Es sollte ein Datum sein",
  time_of_day_parameter: "Zeit des Tages- Parameters",
  tod_report: "TOD - Bericht",
  in_this_timeframe: "In diesem Zeitrahmen",
  average: "Durchschnitt",
  maximum: "Höchstmenge",
  minimum: "Mindestens",
  energy_usage_trend: "Energienutzung Trend",
  standard_deviation: "Standardabweichung",
  statistical_mode: "Statistischer Modus",
  production_logs: "Produktionsprotokolle",
  capacity_logs: "Kapazitätsprotokolle",
  dynamic_logs: "Dynamische Protokolle",
  pollutants: "Schadstoffe",
  group_by: "Gruppe von",
  generate_grouped_trends: "Gruppiert generieren Entwicklung",
  tCo2: "tCo2",
  scope_1: "Artikel 1",
  scope_2: "Artikel 2",
  scope_3: "Artikel 3",
  source: "Quelle",
  type: "Typ",
  user_access_log: "User Access Log",
  user_logs: "Benutzerprotokolle",
  fetch_logs: "Fetch Logs",
  update_profile: "Profil anzeigen",
  lang: "Langlauf",
  theme: "Thema",
  phone: "Telefon",
  update: "Aktualisierung",
  update_password: "Passwort aktualisieren",
  change_password: "Passwort ändern",
  old_password: "Altes Passwort",
  new_password: "Neues Passwort",
  confirm_password: "Passwort bestätigen",
  password_must_be_more_than_8_characters:
    "Passwort muss mehr als 8 Zeichen sein",
  passwords_do_not_match: "Passwörter passen nicht",
  add_hub: "Fügen Sie Hub",
  id_must_be_less_than_4_characters: "ID muss weniger als 4 Zeichen sein",
  add_maintainer: "In den Warenkorb",
  add_parent_org: "Parent Org hinzufügen",
  add_parent_to_organisation: "Eltern hinzufügen",
  parent_org_id: "Parent Org ID",
  add_maintainer_to_organization: "Hinzufügen Maintainer zur Organisation",
  maintainer_id: "Personalausweis",
  update_organisation_details: "Details der Organisation",
  update_your_organization_details:
    "Aktualisieren Sie Ihre Organisation Details",
  default_email: "Default Email",
  picker_in_menu: "Picker im Menü",
  param_diagnostics: "Param Diagnostik",
  show_logo: "Auf der Karte anzeigen",
  show_oem_logo: "Zeige OEM Logo",
  organisation_alias: "Organisation Alias",
  update_organisation_alias: "Aktualisieren Organisation Alias",
  update_org_alias: "Update Org Alias",
  update_your_organization_logo: "Aktualisieren Sie Ihre Organisation Logo",
  select_a_logo: "Wählen Sie ein Logo",
  update_logo: "Update Logo",
  create_uam_user: "UAM erstellen Benutzer",
  overview: "Überblick",
  batches: "Schlachtkörper",
  track_batch: "Track Batch",
  batch_movement: "Batch Bewegung",
  production_planner: "Produktionsplaner",
  show_unshipped_only: "Nur ausgeliefert",
  view_logs: "Logs anzeigen",
  params: "Params",
  add_filter: "Filter hinzufügen",
  filters: "Filter",
  batch_id: "Batch-id",
  show_details: "Details anzeigen",
  tracing_logs: "Protokolle verfolgen",
  quantity: "Menge",
  location_event_type: "Veranstaltungsart",
  fetch_plans: "Fetch Plans",
  sku: "SKU",
  code: "Code",
  add_production_plans: "Produktionspläne hinzufügen",
  date: "Datum",
  from_time: "Aus der Zeit",
  to_time: "Zur Zeit",
  activities: "Aktivitäten",
  task_name: "Vorname",
  task_details: "Details zum Thema",
  start_date: "Startdatum",
  end_date: "Enddatum",
  assignee: "Empfänger",
  manager: "Manager",
  activity: "Tätigkeit",
  activity_type: "Art der Tätigkeit",
  escalation_matrix: "Eskalation Matrix",
  maintenance_activity: "Wartungsarbeiten",
  em_level: "EM-Ebene",
  delay: "Verzögerung",
  user_group: "Benutzergruppe",
  mins_5: "5 minuten",
  mins_10: "10 minuten",
  mins_15: "15 minuten",
  mins_30: "30 minuten",
  hour_1: "1 stunde",
  hour_6: "6 stunden",
  day_1: "1 tag",
  day: "tag",
  day_3: "3 tag",
  insights: "Einblicke",
  create_emailer_group: "E-Mail-Gruppe erstellen",
  notification_type: "Bezeichnung",
  email: "E-Mail senden",
  sms: "SMS",
  create_machine_group: "Maschinengruppe erstellen",
  create_param_group: "Param Group erstellen",
  create_user_group: "Benutzergruppe erstellen",
  create_module: "Modul erstellen",
  busbar_temp_module: "BusBar Temp Modul",
  power_quality_module: "Leistungs-Qualitätsmodul",
  esg_module: "ESG Modul",
  edge_hub: "Rand-Hub",
  tolerance: "Toleranz",
  ambient_temp_input_type: "Ambient Temp Input Typ",
  ambient_temp: "Ambient Temp",
  workspace_ambient_temp: "Workspace Ambient Temp",
  device_ambient_temp: "Gerät Umgebungstemperatur",
  r_temperature: "R Temperatur",
  y_temperature: "Y Temperatur",
  b_temperature: "B Temperatur",
  n_temperature: "N Temperatur",
  rated_current: "Nennstrom",
  no_load_current: "Kein Laststrom",
  r_current: "R Strom",
  y_current: "Y Strom",
  b_current: "B Strom",
  n_current: "N Strom",
  delete_activity: "Seite nicht gefunden",
  substation_voltage_kv: "Substation Spannung(KV)",
  transformer_rating_mva: "Transformer Rating(MVA)",
  percentage_impedance_of_the_transformer: "% Impedanz des Transformers",
  voltage_rv_y: "Spannung RV(Y)",
  voltage_yb_v: "Spannung YB(V)",
  voltage_br_v: "Spannung BR(V)",
  current_r_a: "Aktueller R(A)",
  current_y_a: "Aktuelles Y(A)",
  current_b_a: "Aktueller B(A)",
  thd_i: "THD I",
  thd_v: "THD V",
  thd_i1: "THD I1",
  active_power_kw: "Aktive Leistung (kw)",
  apparent_power_kva: "Offene Leistung (KVA)",
  reactive_power_kvar: "Reaktive Leistung (KVAR)",
  active_energy_kwh: "Aktive Energie (KWH)",
  apparent_energy_kvah: "Offene Energie (KVAH)",
  pf: "PF",
  text: "Text",
  energy_source: "Energiequelle",
  scope: "Anwendungsbereich",
  system_logs: "Systemprotokolle",
  system_logbook: "System Logbuch",
  esg_data_log: "ESG Datenlogger",
  add_esg_data: "ESG Daten",
  add_esg_data_log: "ESG Datenlogger",
  source_type: "Quelle",
  unit_of_measurement: "Maßeinheit",
  from_date_optional: "Ab dem Datum (Optional)",
  "emission_factor": "Emissionsfaktor (kgCO2)",
  create_dynamic_logger: "Dynamische Logger erstellen",
  dynamic_log: "Dynamisches Logbuch",
  primary_field_label: "Primäre Feldbezeichnung",
  primary_field_type: "Primäre Feldart",
  primary: "Primäre",
  additional_fields: "Zusätzliche Felder",
  field_label: "Feldbezeichnung",
  field_type: "Feldtyp",
  choose_list: "Wählen Sie",
  list: "Liste",
  create_capacity_logger: "Kapazitätslogger erstellen",
  capacity_log: "Kapazitätsprotokoll",
  create: "Erstellung",
  create_production_logger: "Produktionslogger erstellen",
  production_log: "Produktionsprotokoll",
  production_logger: "Produktionslogger",
  input_type: "Eingangstyp",
  input: "Eingang",
  input_name: "Eingangsbezeichnung",
  output_type: "Ausgangsart",
  output: "Ausgabe",
  output_name: "Ausgangsname",
  output_params: "Ausgabe Params",
  compute_production: "Gesamtproduktion",
  compute_energy: "Energie berechnen",
  energy_param_type: "Energieparametrie",
  energy_device: "Energiegerät",
  energy_param: "Energieparadies",
  production_operator: "Produktionsbetreiber",
  production_correction_factor: "Produktionskorrekturfaktor",
  output_operator: "Ausgangsoperator",
  output_correction_factor: "Ausgangskorrekturfaktor",
  input_operator: "Input Operator",
  input_correction_factor: "Ausgangskorrekturfaktor",
  energy_unit: "Energieeinheit",
  production_unit: "Produktionseinheit",
  production: "Produktion",
  create_data_logger: "Data Logger erstellen",
  data_log: "Datenlogger",
  add_param: "Hinzufügen von Param",
  param_id: "Parade-ID",
  create_batch_production_logger: "Stapelproduktionslogger erstellen",
  batch_tracing_log: "Stapelverfolgungsprotokoll",
  log_name: "Name des Herstellers",
  location: "Standort",
  info: "Info",
  remarks: "Bemerkungen",
  code_will_be_available_to_log_by_default:
    "code wird standardmäßig zur anmeldung verfügbar",
  fields: "Felder",
  additional_info: "Weitere Informationen",
  action: "Aktion",
  report_type: "Art des Berichts",
  create_parameter_report: "Parameterbericht erstellen",
  data_processing: "Datenverarbeitung",
  addition: "Addition",
  singular: "Singular",
  report: "Bericht",
  energy_parameters_kwh: "Energieparameter (KWH)",
  apparent_energy_KVAh: "Offene Energie KVAh",
  maximum_demand_MD: "Maximale Nachfrage (MD)",
  excel_frequency: "Excel Frequenz",
  multiplier: "Multiplikation",
  single: "Einheit",
  incomers: "Einkommen",
  sections: "Abschnitt",
  energy_distribution_report: "Energieverteilung Bericht",
  root_machine: "Root Machine",
  tariff_on: "Tarrif auf",
  add_root_common_machine: "Root & Common Machine hinzufügen",
  common_machine: "Gemeinsame Maschine",
  energy_bill_distribution_report: "Energierechnung Verteilungsbericht",
  create_consolidated: "Consolidated erstellen",
  choose_report: "Wählen Sie einen Bericht",
  id: "I",
  timeframed_sec: "Zeitrahmen Sec",
  create_dynamic_excel_report: "Dynamischer Excel-Bericht erstellen",
  update_dynamic_excel_report: "Dynamischer Excel-Bericht aktualisieren",
  add_sheet: "In den Warenkorb",
  sheet_name: "Bezeichnung",
  timestamp: "Zeitstempel",
  sheet: "Blatt",
  sheets: "Bleche",
  sheet_id: "Kennnummer",
  delete_sheet: "Löschen von Daten",
  esg_scope_logging: "ESG-Scope-Protokollierung",
  targets: "Ziele",
  report_name: "Bezeichnung",
  update_details: "Details zum Update",
  unit: "Einheit",
  parameters: "Parameter",
  send_excel_file: "Excel-Datei senden",
  update_excel_template_based_report:
    "Excel-Vorlage basierend auf Bericht aktualisieren",
  create_excel_template_based_report:
    "Erstellung von Excel-Vorlagen basierenden Bericht",
  upload_excel_template_xlsx: "Excel-Vorlage (xlsx) hochladen",
  param_equation: "Param Equity",
  edit_sheets: "Blätter bearbeiten",
  row_no: "Row No",
  column_no: "Spalte Nr",
  row_number: "Anzahl",
  column_number: "Spalte Nummer",
  colummn_number: "Spalte Nummer",
  no_sheets_added: "Keine Blätter hinzugefügt",
  process_based_usage_sheet: "Prozessbasiertes Nutzungsblatt",
  process: "Verfahren",
  process_label: "Prozesslabel",
  add_tag_types: "Tag-Typen hinzufügen",
  add_process_to_list: "Prozess zur Liste hinzufügen",
  machine_tag_based_daily_data_sheet: "Tag des Tages Daten Blatt",
  machine_label: "Maschinen und Geräte",
  add_machine_to_list: "Maschine zur Liste hinzufügen",
  add_tag_type_to_list: "Tag-Typ zur Liste hinzufügen",
  shift_based_usage_sheet: "Schichtbasiertes Nutzungsblatt",
  status_for_process: "Verfahren",
  status_for_machine: "Maschine",
  esg_summary_item: "Zusammenfassung",
  esg_details_item: "Details",
  esg_scope1_label: "Artikel 1",
  esg_scope2_label: "Artikel 2",
  esg_scope3_label: "Artikel 3",
  esg_scopes_list_label: "Anwendungsbereich",
  transpose: "Übertragung",
  process_based_timeframed_data_sheet: "Prozessbasierte Zeitrahmen Datenblatt",
  add_pocess_to_list: "Prozess zur Liste hinzufügen",
  tag_types: "Tag Arten",
  " consolidated_shift_based_usage_data_sheet":
    "Konsolidiertes Schichtbasiertes Nutzungsdatenblatt",
  date_format: "Datum Format",
  include_process_total: "Inklusive Prozess insgesamt",
  machine_group_based_usage_sheet: "Maschinengruppenbasiertes Nutzungsblatt",
  machine_group_label: "Maschinen und Geräte",
  machine_group: "Maschinengruppe",
  add_machine_group_to_list: "Maschinengruppe hinzufügen",
  parameter_based_usage_data_sheet: "Datenblatt für Parameterbasierte Nutzung",
  "show_month_start&end_data": "Month Start- und Enddaten anzeigen",
  statistics: "Statistik",
  add_param_to_list: "Param zu Liste hinzufügen",
  add_timestamp_to_list: "Timestamp zur Liste hinzufügen",
  parameter_group_based_timeframed: "Zeitrahmen der Parametergruppe",
  param_group_label: "Param Group Label",
  param_group: "Param Group",
  add_param_group_to_list: "Param Group zur Liste hinzufügen",
  dynamic_logger_based_data_sheet: "Dynamische Logger Basisdaten Blatt",
  dynamic_log_label: "Dynamisches Log-Label",
  parameter_equation_based_sheet: "Parameter Gleichungsbasiertes Blatt",
  data_source: "Datenquelle",
  equation_label: "Equity Label",
  add_equation_to_list: "Auflistung hinzufügen",
  absolute_values: "Absolute Werte",
  consumption_based_sheet: "Verbrauchsbasiertes Blatt",
  parameter_name: "Kennnummer",
  statistical_analysis_based_sheet: "Statistische Analyse basiertes Datenblatt",
  parameter_instantaneous_data_sheet: "Parameter Instantane Datenblatt",
  create_sheet_column: "Blechsäule erstellen",
  column_label: "Kolumne Label",
  machine_id: "Warenbezeichnung",
  create_machine_list: "Maschinenliste erstellen",
  create_param_list: "Parameter erstellen Liste",
  create_sheets: "Blätter erstellen",
  create_shift: "Erstellen von Shift",
  description_optional: "Beschreibung (optional)",
  create_sku: "SKU erstellen",
  create_location: "Location erstellen",
  location_type: "Ortstyp",
  from_location: "Vom Standort",
  to_location: "Zum Standort",
  event_type: "Art der Veranstaltung",
  create_location_event_type: "Veranstaltungsort anzeigen",
  create_list: "Liste erstellen",
  create_param_equation: "Param Equity erstellen",
  positive_params: "Positive Params",
  params_to_be_substracted: "Zu vergebende Params",
  create_combo_param: "Combo Param erstellen",
  inter_param_operator: "Inter Param Operator",
  correction_operator: "Korrektierer",
  correction_operand: "Korrektion Operand",
  create_calculated_param: "Kalkulierte Param erstellen",
  calculated_param: "Berechnete Param",
  correction_factor: "Korrekturfaktor",
  param1_type: "Fall 1 Typ",
  param1: "Fall 1",
  param1_operator: "Param 1 Betreiber",
  param1_operand: "Param 1 Operand",
  param2_type: "Fall 2 Typ",
  param2: "Fall 2",
  param2_operator: "Param 2 Operator",
  param2_operand: "Param 2 Operand",
  create_esg_scope_conversion: "ESG erstellen Umrechnung von Umfang",
  category: "Kategorie",
  get_from_param: "Von Param",
  choose_param: "Wählen Sie Param",
  create_target: "Ziel erstellen",
  create_word: "Das Wort",
  words: "Wörter",
  choose_OEM: "Wählen Sie OEM",
  get_the_models: "Die Modelle bekommen",
  get_oem_devices: "Soem Geräte",
  oem_devices: "OEM-Geräte",
  model_number: "Modellnummer",
  register: "Registrieren",
  add_modbus_gateway: "Modbus Gateway hinzufügen",
  " add_modbus_TCP_gateway": "Modbus TCP Gateway hinzufügen",
  IPAddress: "IP-Adresse",
  port_number: "Hafen Nummer",
  HubID: "Hub-ID",
  baud_rate: "Bausatz",
  parity: "Parität",
  stop_bit: "Hör auf",
  update_wifi: "Update Wifi",
  SSID: "SSID",
  password: "Passwort vergessen",
  restart_enture: "Neu im Sortiment",
  force_update_configs: "Force Update Configs",
  activity_name: "Bezeichnung",
  board: "Vorstand",
  calender: "Kalander",
  maintenance: "Wartung",
  welcome: "Willkommen",
  logout: "Anmeldung",
  help: "Hilfe",
  accounts: "Konten",
  organisation: "Organisation",
  pointer_color_optional: "Zeigerfarbe (optional)",
  user_id: "Benutzerkennung",
  confirm_new_password: "Neues Passwort bestätigen",
  service: "Service",
  send_grid: "Senden Grid",
  default_enture_view: "Default Entry View",
  permissions: "Genehmigungen",
  areas: "Gebiete",
  remark: "Bemerkung",
  days: "Tage",
  yesterdays_aqi: "Gestern AQI",
  aqi: "AQI",
  choose_air_quality_device: "Wählen Sie Luftqualitätsgerät",
  delete_this_device: "Löschen Sie dieses Gerät",
  air_quality_label: "Luftqualitätslabel",
  humidity: "Luftfeuchtigkeit",
  temperature: "Temperatur",
  watcher: "Uhren",
  area_reference: "Referenzgebiet",
  write_data: "Schreiben Sie uns",
  day_of_week: "Wochentag",
  month: "Monat",
  diagnostics_notification_groups: "Diagnostics Notification Groups",
  hub_name: "Name des Hubraums",
  hub_model: "Hubmodell",
  hub_type: "Hub",
  update_your_MQTT_bridge_config: "Aktualisieren Sie Ihre MQTT Bridge Config",
  MQTT_bridge_mode: "MQT Brücke",
  broker_address: "Broker Adresse",
  update_your_OPC_config: "Aktualisieren Sie Ihre OPC Config",
  OPC_client_mode: "OPC Client Modus",
  OPC_server_URL: "OPC Server URL",
  user_auth: "Benutzer-Auth",
  mode: "Modus",
  security_policy: "Sicherheitspolitik",
  Update_your_Edge_Hub_restart_Schedule:
    "Aktualisieren Sie Ihre Edge Hub neu starten",
  auto_restart: "Auto-Ausrüstung",
  time_24Hrs: "Zeit (24 Stunden)",
  modbus_ping_latency: "Modbus Ping Latency",
  modbus_unhealthy_wait: "Modbus ungesund warten",
  edit_written_log_book: "Schriftliches Logbuch bearbeiten",
  field_cant_change: "Dieses Feld kann sich nicht ändern",
  re_write: "Re Write",
  write_in_logbook: "in logbuch schreiben",
  write: "Schreiben",
  download_template: "Vorlage herunterladen",
  write_in_logbook_csv: "Schreiben Sie in Logbuch von .csv",
  upload_file: "Datei herunterladen",
  batch: "Batch",
  move_batch: "Los Batch",
  on: "Auf",
  off: "Aus",
  fast_mode: "Schneller Modus",
  batch_shipping_details: "Batch Versand Details",
  vehicle_no: "Fahrzeugnummer",
  net_weight: "Nettogewicht",
  actual_weight: "Tatsächliches Gewicht",
  vendor: "Verkäufer",
  loaded_by: "Geladen von",
  verified_by: "Verifiziert von",
  document_no: "Dokument Nr",
  multiple_machine_param: "Mehrere Maschinen und Params",
  map_paramid_paramequation_to_row:
    "Karte Param ID's oder Param Equations Zum Row",
  add_current_date_to_sheet: "Aktuelles Datum zum Blatt hinzufügen",
  create_parameter_from_template: "Parameter erstellen Aus der Vorlage",
  selected_parameters_count: "Ausgewählte Parameter Count",
  restricted_user_access: "Eingeschränkter Benutzerzugang",
  select_all_parameters: "Alle Parameter auswählen",
  edit_angular_gauge_widget: "Angular Gauge Widget bearbeiten",
  add_angular_gauge_widget: "Angular Gauge Widget hinzufügen",
  widget_label: "Widget Label",
  widget_width: "Widget Widget",
  widget_height: "Widget Höhe",
  param_label: "Parade Label",
  choose_parameter: "Wählen Sie den Parameter",
  processing: "Verarbeitung",
  edit_banner_widget: "Banner Widget bearbeiten",
  add_banner_widget: "Banner Widget hinzufügen",
  edit_bartd_widget: "Bar TD Widget bearbeiten",
  add_bar_widget: "Bar Widget hinzufügen",
  yaxis_label: "Y - Axis Label",
  xaxis_label: "X - Axis Label",
  y1axis_label: "Y1 – Achsenbeschriftung",
  y2axis_label: "Y2 – Achsenbeschriftung",
  y1axis: "axe y1",
  y2axis: "axe y2",
  edit_dual_axis_bar_trend_widget: "Doppelachsiges Balkentrend-Widget bearbeiten",
  add_dual_axis_bar_trend_widget: "Doppelachsiges Balkentrend-Widget hinzufügen",
  y1_graph_type: "Y1-Achsendiagrammtyp",
  y2_graph_type: "Y2-Achsendiagrammtyp",
  idealValue: "ideal Wert",
  idealLabel: "ideal Bezeichnung",
  add_parameters: "Parameter hinzufügen",
  add_equation: "Equity hinzufügen",
  add_params: "Params hinzufügen",
  enter_of_machines: "Keine Maschinen eingeben",
  edit_bar_trend_td_widget: "Bar Trend TD Widget bearbeiten",
  add_bar_trend_td_widget: "Bar Trend TD Widget hinzufügen",
  graph_type: "Abbildungsart",
  aggregation: "Aggregat",
  default_graph_type: "Standarddiagrammtyp",
  bar_mode: "Bar-Modus",
  show_ideal_line: "Ideale Linie",
  equations: "Gleichungen",
  edit_bitwise_word_widget: "Bitwise Word Widget bearbeiten",
  add_bitwise_word_widget: "Bitwise Word Widget hinzufügen",
  show_bit_status: "Bit Status anzeigen",
  edit_bullet_gauge_widget: "Bearbeiten Sie das Bild des Bullet",
  add_bullet_gauge_widget: "Bullet Gauge Widget hinzufügen",
  reference_value_for: "Referenzwert für",
  gauge_type: "Muster",
  indicator_color: "Anzeige Farbe",
  busbar_temperature_graph_widget: "Bus Bar Temperatur Graph Widget",
  busbar_module: "Bus-Bar Modul",
  edit_consumption_gauge_widget: "Konsum bearbeiten Widget von Gaumen",
  add_consumption_gauge_widget: "Add Consumption Gauge Widget",
  linearGauge: "Lineare Abmessung",
  show_target: "zeigen Ziel",
  healthy_on_below_target: "Gesund auf dem Ziel",
  edit_consumption_pie_widget: "Konsum bearbeiten Pie Widget",
  add_consumption_pie_widget: "Verbrauch hinzufügen Pie Widget",
  add_color_break_point: "Fügen Sie Color Break Point",
  custom_color: "Benutzerdefinierte Farbe",
  color: "Farbe",
  edit_heat_map_widget: "Heat Map Widget bearbeiten",
  add_heat_map__widget: "Heat Map Widget hinzufügen",
  choose_equation: "Wählen Sie Gleichung",
  label_star: "Label*",
  color_star: "Farbe*",
  operator1_star: "Betreiber 1*",
  value1_star: "Wert 1*",
  operator2_star: "Betreiber 2*",
  actions: "Maßnahmen",
  condition: "Bedingungen",
  status_color: "Statusfarbe",
  text_view: "Text anzeigen",
  edit_process_Status_widget: "Prozessstatus Widget bearbeiten",
  add_process_Status_widget: "Prozessstatus Widget hinzufügen",
  edit_machine_Status_widget: "Maschinenstatus Widget bearbeiten",
  add_machine_Status_widget: "Maschinenstatus Widget hinzufügen",
  choose_process: "Wählen Sie Prozesse",
  choose_machine: "Wählen Sie Maschinen",
  table_header_label: "Tabelle Header Label",
  edit_table_TD_widget: "Tabelle TD Widget bearbeiten",
  add_table_TD_widget: "Tabelle TD Widget hinzufügen",
  configure_table: "Tabelle konfigurieren",
  edit_target_based_trend_widget: "Zielbasierte Trend Widget bearbeiten",
  add_target_based_trend_widget: "Zielbasierte Trend Widget hinzufügen",
  target: "Ziel",
  target_line_label: "Ziellinie Etikett",
  edit_usage_score_card: "Verwenden Sie Scorecard Widget",
  add_usage_score_card: "Hinzufügen von Scorecard Widget",
  compare_to: "Vergleichen Sie",
  assign_color: "Farbe zuweisen",
  enter: "Geben Sie",
  gauge_to_dashboard: "Überprüfe das Armaturenbrett",
  widget_types: "Widget-Typen",
  from_value: "Von Wert",
  to_value: "Zu Wert",
  machine_status: "Maschinenstatus",
  deviceA_type: "Gerätetyp",
  templates: "Vorlagen",
  add_triggers_to_parameter: "Trigger zum Parameter hinzufügen",
  triggers: "Auslöser",
  show_filter: "Filter anzeigen",
  view_id: "Ausweis anzeigen",
  move_up: "Bewegung nach oben",
  move_down: "Los",
  multiplier_types: "Multiplier_Typen",
  energy_report: "Energiebericht",
  add_machine_param: "Produkt hinzufügen Param",
  aliasing: "Aliasing",
  no_zero_read: "Keine Null gelesen",
  remove_negative_entropy: "Negative Entropy entfernen",
  calculate_consumption: "Berechnung des Verbrauchs",
  disabled: "Behinderte",
  limiter_value: "Wert des Limmits",
  machine_scroll_view_page: "Maschinen und Anlagen Seite anzeigen",
  create_mannual_parameter: "Mannual Parameter erstellen",
  restrict_user_access: "Benutzerbeschränkung",
  add_device_parameter: "Geräteparameter hinzufügen",
  entropy: "Entropie",
  idle_latency: "Idle Latency",
  add_OPC_parameter: "OPC Parameter hinzufügen",
  node_id: "Node ID",
  byte_format: "Byte Format",
  param_scroll_view_page: "Die Welt der Welt Seite anzeigen",
  timeframed_sec_report: "Zeitrahmen SEC Bericht",
  prod_unit: "Proportional",
  SEC_unit: "SEK Einheit",
  energy_parameters: "Energieparameter",
  prod_param_type: "Produziert Param Typ",
  production_parameters: "Produktionsparameter",
  no_of_intervals: "Nein von Intervallen",
  granular_data: "Granular Data",
  csv: "CSV",
  delete_rule: "Regel",
  enable_disable_all_parameters: "Alle Parameter aktivieren/ deaktivieren",
  disable: "Nicht verfügbar",
  enable: "Ermöglichen",
  edit_computed_parameter: "Computed Parameter bearbeiten",
  quantization: "Mengen",
  command: "Befehl",
  register_size: "Registrieren Größe",
  consumer_ID: "Verbraucherausweis",
  site_id: "Site ID",
  get_data: "Daten sammeln",
  update_param_type: "Update Param Typ",
  write_in_data_logbook: "Schreiben Sie in Data Logbook",
  daily_data: "Tagesdaten",
  log_entry: "Anmelden",
  enable_Scroll_mode: "Aktivieren Sie den Scroll-Modus",
  multiple_areas: "Mehrere Bereiche",
  time_of_day: "Zeit des Tages",
  capacity_logger: "Kapazität Logger",
  provider: "Anbieter",
  total_sites_received_bills_MTD: "Gesamt-Sites erhalten Bills MTD",
  sites_yet_to_receive_bills: "Seiten Dennoch, Bills zu erhalten",
  total_sites: "Gesamte Seiten",
  sites_received_bills_MTD: "%Sites erhielt Rechnungen MTD",
  total: "Insgesamt",
  total_bills_received_mtd: "Gesamtrechnungen erhalten MTD",
  arrear_1: "Arrear 1",
  arrear_2: "Arrear 2",
  no_reading: "Kein Lesen",
  bills_received_in_5_days: "Rechnungen in den letzten 5 Tagen erhalten",
  site_wise_status: "Site weiser Status von Andere als OK Status",
  site_wise_final_summary: "Site Wise Schlussübersicht",
  source_link: "Sourcce Link",
  data_type: "Datentyp",
  select_an_image_to_upload: "Wählen Sie ein Bild hochladen",
  user_group_Id: "Benutzergruppen-ID",
  user_group_name: "Name der Benutzergruppe",
  delay_in_mins: "Verzögerung in (Minutes)",
  delete_task: "Aufgaben",
  watchers: "Uhren",
  comments: "Bemerkungen",
  asset_under_maintenance: "Vermögensgegenstände",
  start_time: "Startzeit",
  end_time: "Endzeit",
  download_latest_usage_table: "Aktuelle Nutzungstabelle herunterladen",
  tag: "Tag",
  add_target_data: "Zieldaten hinzufügen",
  edit_target: "Ziel bearbeiten",
  delete_target: "Löschen Ziel",
  prop: "Propan",
  excel_template_based_report: "Excel Vorlage Bericht",
  configured_hub: "Konfigurierter Hub",
  configured_relay: "Konfiguriertes Relais",
  desired_state: "Geliebter Staat",
  configured_notification_group: "Konfigurierte Benachrichtigungsgruppe",
  email_ids: "Email-IDs",
  logs: "logs",
  unit_id: "Einheitskennung",
  download_report: "Bericht herunterladen",
  frequency: "Häufigkeit",
  ip: "IP",
  edit_wifi: "Wifi bearbeiten",
  details: "Details",
  connected_to: "Vernetzt an",
  software_version: "Softwareversion",
  mac_address: "MAC-Adresse",
  opc_client_enabled: "OPC Client aktivieren",
  MQTT_bridge_enabled: "MQTT Brücke aktiviert",
  hub_specification: "Hub-Spezifikation",
  hub_details: "Und was ist das",
  restart_schedule: "Neustart",
  restart_device: "Neue Geräte",
  set_up_restart_time: "Wiederherstellen der Zeit",
  connectivity_logs: "Verbindungsprotokolle",
  internal_cache_size: "Interne Cache Größe",
  default: "Fehler",
  configs: "Konfessionen",
  processed: "Verarbeitet",
  space_available: "Raum verfügbar",
  kb: "KB",
  mb: "MB",
  last_ping: "Letzter Beitrag",
  toggle: "Toggle",
  configured_creds: "Konfigurierte Krisen",
  psk: "PSK",
  set_value: "Wert bestimmen",
  time_current_time: "Zeit (Kurzzeit wird eingenommen, wenn nicht erwähnt)",
  Set: "Set",
  health: "Gesundheit",
  port: "Hafen",
  mqtt_params: "Mqtt-Parames",
  delete_device: "Löschen von Gerät",
  view_details: "Details anzeigen",
  parameter_id: "Parameter-ID",
  email_address: "Email Adresse",
  attached_triggers: "Angebaute Auslöser",
  parameter_details: "Parameter Details",
  enable_write: "Schreiben aktivieren",
  disable_write: "Deaktivieren Schreiben",
  offset: "Offset",
  no_of_parameters: "Anzahl der Parameter",
  creation_time: "Erstellungszeit",
  not_configured: "Nicht konfiguriert",
  add_rule: "Artikel",
  sl_no: "Sl-Nr",
  targeted_by_value: "Gezielt nach Wert",
  configured_action: "Konfigurierte Aktion",
  add_action: "Aktion hinzufügen",
  action_id: "Aktionsausweis",
  delete_user: "Löschen",
  bit: "Bit",
  low: "Niedrig",
  high: "Hoch",
  esg_scope_conversion: "ESG Umrechnung von Umfang",
  batch_production_logs: "Batch-Produktion Logs",
  esg_data_logs: "ESG Datenprotokolle",
  remove_from_dashboard: "Entfernen von Dashboard",
  data_logs: "Datenprotokolle",
  select_device: "Wählen Sie Gerät",
  fetch_connectivity_logs: "Fetch Connectivity Logs",
  hub_internet_connectivity_logs: "Hub Internet Connectivity Logs",
  modbus_gateways: "Modbus Gateways",
  device_switched_on: "Gerät eingeschaltet",
  device_switched_off: "Gerät ausgeschaltet",
  parameter_report: "Parameterbericht",
  timeframed_specific_energy_consumption_report:
    "Zeitrahmenspezifischer Energieverbrauchsbericht",
  ebill_distribution_report: "E-Bill Verteilungsbericht",
  root_user_management: "Root User Management",
  user_acces_management: "User Access Management",
  inference: "In der",
  turn_off: "Ausschalten",
  turn_on: "Drehen",
  diagram_pallette: "Diagramm Pallette",
  add_component: "In den Warenkorb",
  components: "Komponenten",
  add_text: "Text hinzufügen",
  add_realtime_data: "Echtzeitdaten hinzufügen",
  shapes: "Formen",
  lines: "Zeilen",
  text_realtime_component: "Text Realtime Component",
  shapes_pallette: "Formen Pallette",
  lines_pallette: "Linien Pallette",
  click_choose:
    "Klicken Sie auf und wählen Sie die Formen, um der SLD hinzuzufügen",
  choose_line: "Wählen Sie Zeile, um die SLD hinzuzufügen",
  choose_image: "Wählen Sie das Bild",
  upload_image_to_add: "Bild hochladen, um die SLD hinzuzufügen",
  add_realtime_textdata: "Echtzeit- oder Textdaten zur SLD hinzufügen",
  update_user: "Benutzer",
  add_watcher: "Watcher hinzufügen",
  premise_area: "Vorraum",
  create_computed_param: "erstellen berechnet Parasiten",
  create_derived_param: "erstellen Derive Param",
  proceed_to_ship: "Schifffahrt",
  esg_data_collection: "ESG Datenerhebung",
  esg_scope_details: "ESG Umfang Details",
  select_custom_date_range: "Wählen Sie aus Datumsbereich",
  days_365: "365 tage",
  days_180: "180 Tage",
  days_30: "30 Tage",
  create_user_for_SSO: "Benutzer für SSO erstellen",
  add_widget: "Widget hinzufügen",
  add_machine_status_rules: "Maschinenstatusregeln hinzufügen",
  add_trigger: "Hinzufügen von Trigger",
  add_logged_parameter: "Logged Parameter hinzufügen",
  create_mannual_param: "Mannual Param",
  add_opc_param: "OPC Param",
  create_write_block: "Schreibblock erstellen",
  all_parameters: "Alle Parameter",
  edit_device: "Gerät bearbeiten",
  edit_modbus_device: "Modbus-Gerät bearbeiten",
  edit_mqtt_device: "Mqtt Gerät bearbeiten",
  limit_entropy: "Begrenzung der Entropie",
  emails: "E-Mails",
  power_factor: "PowerFactor(PF)",
  tarrif_rate: "Tarnwert",
  apparent_power: "Offene Leistung",
  reactive_power: "Reactive Power",
  active_power: "Aktive Leistung",
  energy_usage_view_page: "Seite der Energienutzung anzeigen",
  switch_organisation: "Schalterorganisation",
  wireless_creds: "Kabellose Creds",
  latest: "Letzter Beitrag",
  value_is: "Wert ist",
  please_take_necessary_action: "Bitte nehmen Sie die nötige Aktion",
  edit_role: "Bearbeiten der Rolle",
  delete_role: "Löschen der Rolle",
  today: "Heute",
  days_4: "4 tage",
  due: "Aufgrund",
  move_to_done: "Gehen Sie zu Ende",
  power_system_fault: "Power System Fehlerzahl (Letzte Woche)",
  alarms: "Alarme",
  electricity_consumption: "Stromverbrauch (Monatsdatum)",
  average_demand: "Durchschnittlicher Verbrauch",
  water_use_intensity: "Wasserverbrauch Intensität (WUI)",
  average_water_cost: "Durchschnittliche Wasserkosten (Mondlich)",
  water_use_efficiency: "Wasserverbrauch Effizienz (WUE)",
  batch_logs: "Batch Logs",
  edit_log: "Anmelden bearbeiten",
  delete_log: "Löschen von Log",
  dates: "Datum",
  log_details: "Details",
  view: "Blick",
  scope1_emission: "Geltungsbereich 1 Emission",
  scope2_emission: "Geltungsbereich 2 Emission",
  lmtd: "LMT",
  company_data: "Unternehmen Daten",
  historian: "Historisch",
  from_optional: "Von (optional)",
  to_optional: "Zu (optional)",
  trends: "Entwicklung",
  generate_trend: "Trend generieren",
  by: "Von",
  check_logs: "Logs überprüfen",
  workspaces: "Arbeitsräume",
  types_of_nodes_connected: "Arten von Knoten verbunden",
  types_of_modbus_device_connected: "Arten von Modbus-Geräten verbunden",
  no_of_registers: "Anzahl der Register",
  write_register: "Registrieren",
  setting_view: "Ansicht des Benutzers",
  sign_up: "Anmelden",
  reset: "Zurück zur Übersicht",
  mobile_number: "Mobilnummer",
  nebeskie_labs: "Nebeskie Labs",
  please_register_and_send_otp:
    "Bitte geben Sie Ihre registrierte E-Mail ein Id und klicken Sie auf OTP senden.",
  reset_password: "Passwort vergessen",
  back_to_login: "Zurück zur Anmeldung",
  otp: "OTP",
  send_otp: "OTP senden",
  org_alias: "Org Alias",
  please_stand_by: "Bitte warten",
  total_usage_this_month: "Gesamtnutzung Dieser Monat",
  total_usage_last_month: "Gesamtnutzung Letzter Monat",
  generate_report_manually: "Bericht manuell erstellen",
  energy_distribution_detailed_report:
    "Energieverteilung Detaillierter Bericht",
  the_report_is_processing: "Der Bericht verarbeitet",
  enter_active_energy_manually: "Aktive Energie manuell eingeben",
  include_others: "Inklusive Andere",
  bill_amount: "Betrag",
  yes_delete_it: "Ja, Löschen",
  create_write_param: "Schreiben Sie Param",
  report_list: "Bericht",
  plant_id: "Warenbezeichnung",
  plant_name: "Bezeichnung",
  plant_address: "Anschrift",
  add_selected: "Gewählt hinzufügen",
  clear_selection: "Auswahl löschen",
  real_time_value: "Real tIme Wert",
  default_status: "Fehlerstatus",
  default_label: "Default Label",
  default_color_optional: "Standardfarbe optional",
  rule_based_status: "Regelbasierter Status",
  show_label_only: "Nur mit Label",
  show_unit: "Show Unit",
  status_rules: "Statusregeln",
  configure: "Konfigurieren",
  monthly_usage: "Monatliche Nutzung",
  meter_consumption_kwh: "Verbrauch (kwh)",
  total_unit: "Gesamteinheit",
  region: "Region",
  average_energy_consumed: "Durchschnittsenergieverbrauch",
  average_production: "Durchschnittliche Produktion",
  average_sec: "Durchschnittliche SEC",
  total_production: "Gesamtproduktion",
  total_energy_consumed: "Gesamtenergieverbrauch",
  max_sec: "Max SEC",
  device_1: "Gerät 1",
  device_parameter_1: "Geräteparameter 1",
  device_2: "Gerät 2",
  device_parameter_2: "Geräteparameter 2",
  create_param: "Param",
  update_tags: "Update Tags",
  remove_from_process: "Entfernen von Prozess",
  machine_parameters: "Maschinenparameter",
  energy: "Energie",
  power: "Leistung",
  remove_from_featured: "Von Featured entfernen",
  other_parameters: "Sonstige Parameter",
  add_to_featured: "In den Warenkorb",
  logged_parameters: "Logged Parameter",
  tags: "Schlagwörter",
  machine_status_rules: "Maschinen und Anlagen",
  connected_communication_devices: "Vernetzte Kommunikationsgeräte",
  mqtt_devices: "Mqtt Geräte",
  modbus_devices: "Modbus Geräte",
  total_no_of_params: "Anzahl der Params",
  edge_devices: "Edge-Geräte",
  machines_configured: "Maschinen konfiguriert",
  reports_configured: "Berichte Konfiguriert",
  views_configured: "Anzeigen Konfiguriert",
  send_email_notification: "E-Mail-Benachrichtigung senden",
  enture_send_email_notification:
    "Senden wir E-Mail-Benachrichtigung an die ausgewählte Benutzergruppe",
  agree_and_send: "Abschluss und Senden",
  ebill: "Ebill",
  monthly_trend: "Monatlicher Trend",
  param_view: "Blick auf die Stadt",
  calories: "Kalorien",
  param_count_view: "Die Welt der Welt Blick",
  created: "Erstellt",
  api_data_count_view: "API Datenzähler Blick",
  diesel_emissions: "Dieselemissionen",
  eb_emissions: "EB Emissionen",
  emissions_kg_co2: "Emissionen kg-Co2",
  kpis: "Kennzahlen",
  jan_23: "Jan-23",
  feb_23: "Februar-23",
  mar_23: "Mar-23",
  apr_23: "Apr. 23",
  may_23: "Mai-23",
  jun_23: "Jun-23",
  lpg_emissions: "LPG Emissionen",
  petrol_emissions: "Erdölemissionen",
  bullet_gauge: "Stierkampf",
  equipment: "Ausrüstung",
  enter_no_of_machines: "Keine Maschinen eingeben",
  shift: "Verschiebung",
  upload: "Hochladen",
  image_upload: "Bild hochladen",
  derived_parameter: "Abgeleiteter Parameter",
  value_type: "Werte",
  enable_entropy: "Aktive Entropie",
  remove_decimal: "Deimal entfernen",
  limiter_entropy: "Begrenzte Entropie",
  show_sku_id: "SKU-ID anzeigen",
  production_start_date: "Produktionsstartdatum(Optional)",
  production_start_time: "Produktionsstart Zeit(Optional)",
  time: "Zeit",
  temperature_c: "Temperatur c)",
  set_temperature_c: "Temperatur einstellen (C)",
  no_of_people: "Keine oF Personen",
  co2_level: "Ebene",
  aqi_optional: "AQI Optional",
  delete_sheets: "Löschen von Blättern",
  root_login: "Root Login",
  uam_login: "UAM Anmelden",
  login: "Anmelden",
  add_oem_logo: "Hinzufügen von OEM Logo",
  devices: "Geräte",
  derived_data_units: "Abgeleitete/Dateneinheiten",
  roles: "Roggen",
  engineering: "Ingenieurwesen",
  control_panel: "Systemsteuerung",
  views: "Ansichten",
  reports_analysis: "Berichte und Analysen",
  actions_triggers: "Aktionen & Trigger",
  modules: "Module",
  processes: "Verfahren",
  air_quality: "Luftqualität",
  manufacturing: "Herstellung",
  esg: "ESG",
  task_managment: "Aufgabenstellung",
  sustainability: "Nachhaltigkeit",
  groups: "Gruppen",
  log_book: "Logbuch",
  monitor: "Monitor",
  enture_settings: "Eingabeeinstellungen",
  configuration: "Konfiguration",
  bar: "Bar",
  line: "Linie",
  s7_device: "S7 Gerät",
  rack: "Rack",
  slot: "Slot",
  db_number: "DB-Nummer",
  byte_offset: "Byte Offset",
  bit_offset: "Bit Offset",
  data_length: "Datenlänge",
  val_type: "Wertart",
  create_s7_device: "S7 Gerät erstellen",
  scope_label: "Anwendungsbereich",
  client_safety: "Kundensicherheit",
  edit: "Bearbeiten",
  delete: "Löschen",
  choose_param_to_monitor: "Wählen Sie Param zum Monitor",
  rules: "Regeln",
  add_to_row: "In der Nähe",
  add_usage: "Verwenden",
  notifications: "Mitteilungen",
  organisation_details: "Organisation Details",
  profile: "Profil",
  user_access: "Benutzerzugriff",
  admin: "Admin",
  "shifts": "Verschiebungen",
  skus: "Lagerhaltungseinheiten",
  locations: "Standorte",
  locations_event_types: "Veranstaltungstypen für Orte",
  lists: "Listen",
  param_equations: "Parametergleichungen",
  combo_params: "kombinierte Parameter",
  calculated_params: "berechnete Parameter",
  "add_pie_TD_widget": "Pie TD-Widget hinzufügen",
  "edit_pie_TD_widget": "Bearbeiten Sie das Torten-TD-Widget",
  "legend": "Legend",
  "pie": "Pie",
  "edit_bar_trend_widget": "Balkentrend-Widget bearbeiten",
  "add_bar_trend_widget": "Balkentrend-Widget hinzufügen",
  "edit_horizantal_bar_trend_widget": "Widget „Horizontaler Balkentrend“ bearbeiten",
  "add_horizantal_bar_trend_widget": "Widget „Horizontaler Balkentrend“ hinzufügen",
  "too_long": "Zu lang",
  "trigger_logs": "Trigger Logs",
  "create_trigger": "Erstellen Trigger",
  "right_click_to_configure": "Klicken Sie zum Konfigurieren mit der rechten Maustaste",
  "width": "Breite",
  "height": "Höhe",
  "hide_value": "Wert ausblenden",
  "edit_machine": "Maschine bearbeiten",
  "wait_time": "Wartezeit",
  "production_date": "Produktionsdatum",
  "connectivity_status": "Verbindungsstatus",
  "production_plans": "Produktionspläne",
  "production_pending": "Produktion steht noch aus",
  "pending_shipment": "Geplante Sendung",
  "trace_logs": "Trace-Protokolle",
  "movement_events": "Batch-Bewegungsereignisse",
  "entropy_threshold": "Entropieschwelle",
  "threshold_value": "Schwellwert",
  "pending_dispatch": "Ausstehender Versand",
  "dispatched": "Versendet",
  "dispatch_details": "Versanddetails",
  "dispatch": "Versenden Sie dies",
  "raw_materials": "Rohes Material",
  "pour_qty": "Gegossene Menge",
  "summary": "Zusammenfassung",
  "optional": "Optional",
  "param_mqtt_map": "Param MQTT-Karte",
  "machine_status_mqtt_map": "Maschinenstatus-MQTT-Karte",
  "edit_trigger_count_table_widget": "Widget Triggeranzahltabelle bearbeiten",
  "add_trigger_count_table_widget": "Fügen Sie das Widget Trigger-Zählungstabelle hinzu",
  "count": "Die Zählung",
  "real_time_consumption": "Echtzeitverbrauch",
  "search_view": "Suchansicht",
  "multiple_entries": "Mehrfache Einträge",
  "combined_parameter": "Kombinierter Parameter",
  "background_color": "Hintergrundfarbe",
  "resize": "Größe ändern",
  "remove": "Entfernen",
  "select_views": "Wählen Sie Ansichten aus",
  "difference": "Unterschied",
  "sum": "Summe",
  "set_param_type_for_machine": "Parametertyp für Maschine festlegen",
  "temperature_parameters": "Temperaturparameter",
  "area_name": "Gebietsname",
  "maintenance_due_on": "Wartung fällig am",
  "days_left": "Tage übrig",
  "enture_insights": "Venture-Einblicke",
  "edit_logged_parameter": "Protokollierte Parameter bearbeiten",
  "reports": "Berichte",
  "till_date_pie_chart": "Kreisdiagramm bis heute",
  "till_date_bar_chart": "Balkendiagramm bis heute",
  "tod_billing_widget": "TOD-Abrechnungs-Widget",
  "bar_trend_widget": "Balkentrend-Widget",
  "target_based_bar_trend_widget": "Zielbasiertes Balkentrend-Widget",
  "gauge_widget": "Jauge-Widget",
  "consumption_pie_widget": "Verbrauchs-Kreisdiagramm-Widget",
  "consumption_gauge_widget": "Verbrauchs-Jauge-Widget",
  "angular_gauge_widget": "Angular Jauge-Widget",
  "table_widget": "Tabellen-Widget",
  "banner_widget": "Banner-Widget",
  "param_equation_banner_widget": "Param-Gleichungs-Banner-Widget",
  "shift_based_usage_table": "Schichtbasiertes Nutzungstabelle",
  "bitwise_word_widget": "Bitweise Wort-Widget",
  "dynamic_log_widget": "Dynamisches Protokoll-Widget",
  "machine_status_timeline_widget": "Maschinenstatus-Zeitstrahl-Widget",
  "process_or_machine_status_widget": "Prozess-/Maschinenstatus-Widget",
  "parameter_insights_widget": "Parameter Einblicke-Widget",
  "heat_map": "Wärmekarte",
  "usage_score_card_widget": "Nutzungspunktestand-Widget",
  "busbar_live_monitoring": "Busbar-Live-Überwachung",
  "bullet_or_angular_guage_widget": "Bullet/Angular Jauge-Widget",
  "dual_axis_bar_trend_widget": "Dual-Achsen-Balkentrend-Widget",
  "trigger_count_table_widget": "Auslöser-Zählertabelle-Widget",
  "bar_trend_td_widget": "Balkentrend TD-Widget",
  "horizantal_bar_trend_widget": "Horizontales Balkentrend-Widget",
  "parameter_status_based_usage_status": "Parameterstatusbasiertes Widget",
  "choose_from_templates": "Aus Vorlagen wählen",
  "shift_timeframed_trends": "Schichtbasierte zeitliche Trends",
  "candlestick_trends": "CandleStick Trends",
  "free_ram": "Free RAM",
  "item": "Item",
  "item_code": "Item Code",
  "department": "Department",
  "departments": "Departments",
  "issue_vouchers": "Issue Vouchers",
  "issue_voucher": "Issue Voucher",
  "to_be_approved": "To Be Approved",
  "to_be_issued": "To Be Issued",
  "intended": "Intended",
  "approved": "Approved",
  "received": "Received",
  "receiver": "Receiver",
  "material": "material",
  "material_group": "material group",
  "material_desc": "Material Description",
  "uom": "Unit Of Measurement",
  "create_item_code": "Create Item Code",
  "create_department": "Create Department",
  "users": "Users",
  "items": "Items",
  "users_in_department": "Users in Department",
  "authorisers_in_department": "Authorisers in Department",
  "issuers_in_department": "Issuers in Department",
  "item_issuer_list": "Item Issuer List",
  "add_item_issuer": "Add Item Issuer",
  "store": "Store",
  "create_issue_voucher": "Create Issue Voucher",
  "voucher_requests": "Voucher Requests",
  "intended_qty": "Intended Qty",
  "issued_qty": "Issued Qty",
  "issue_items": "Issue Items",
  "voucher_receipt": "Voucher Receipt",
  "add_stock": "Add Stock",
  "attached_targets": "Attached Targets",
  "attach_target": "Attach Target",
  "add_target_to_parameter": "Add Target to Parameter",
  "empty": "Empty",
  "add_day_wise_target": "Add Day wise Target",
  "day_wise_targets": "Day Wise Targets",
  "fetch_targets": "Fetch Targets",

  "day_wise_machine_status_widget": "Tägliches Maschinenstatus-Widget",
  "add_day_wise_machine_status_widget": "Tägliches Maschinenstatus-Widget hinzufügen",
  "edit_day_wise_machine_status_widget": "Tägliches Maschinenstatus-Widget bearbeiten",
  "add_machines": "Maschinen hinzufügen",
  "added_machines": "Hinzugefügte Maschinen",
  "consumption_start_time":"Consumption Start time",







};
